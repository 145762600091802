import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from 'antd';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { LogContainer, VerticalDivider } from './styles/logStyle';

const Log = ({ log, setAuditLogModal }) => {
  const { Text } = Typography;

  const formatDate = date => {
    const dateFormat = 'dd/MM/yyyy HH:mm:ss';

    return format(new Date(date), dateFormat, { locale: ptBR });
  };

  return (
    <LogContainer>
      <Text style={{ width: '15%', textAlign: 'center' }}>
        <strong>Origem -</strong> {log.app_location}
      </Text>
      <VerticalDivider />
      <Text style={{ width: '15%', textAlign: 'center' }}>
        <strong>Data -</strong> {formatDate(log.time_stamp)}
      </Text>
      <VerticalDivider />
      <Text style={{ width: '15%', textAlign: 'center' }}>
        <strong>Usuário -</strong> {log.user_email}
      </Text>
      <VerticalDivider />
      <Text style={{ width: '15%', textAlign: 'center' }}>
        <strong>Recurso -</strong> {log.resource?.toUpperCase()}
      </Text>
      <VerticalDivider />
      <Text style={{ width: '15%', textAlign: 'center' }}>
        <strong>Ação -</strong> {log.action_type}
      </Text>
      <VerticalDivider />
      <Button
        onClick={() => setAuditLogModal({ visible: true, log })}
        type="primary"
      >
        Ver detalhes
      </Button>
    </LogContainer>
  );
};

Log.propTypes = {
  log: PropTypes.shape({
    app_location: PropTypes.string,
    user_email: PropTypes.string,
    time_stamp: PropTypes.string,
    resource: PropTypes.string,
    action_type: PropTypes.string,
    setAuditLogModal: PropTypes.func,
  }),
  setAuditLogModal: PropTypes.func,
};

export default Log;
