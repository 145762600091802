import styled from 'styled-components';
import { Switch, Button, Form, Tag } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 11px 10px;

  min-height: 50px;
  max-width: 80%;
  border: 2px solid var(--border-color);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin-bottom: 0;
    font-weight: bold;
    color: #707070;
    font-size: 18px;
  }

  span {
    margin-right: 8px;
  }
`;

export const SwitchSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  .switch {
    margin: 0 8px;
  }

  .active {
    font-size: 10px;
  }
`;

export const ConfigDetails = styled.div`
  padding: 0 20px;
`;

export const CustomForm = styled(Form)`
  background-color: transparent;
  .ant-row {
    margin-bottom: 0;
  }
`;

export const CustomSwitch = styled(Switch)`
  min-width: 38px;
  border: 1px solid #707070;
  background-color: transparent;
  &.ant-switch-checked {
    background-color: transparent;
    .ant-switch-handle {
      left: calc(100% - 16px - 2px);
      &:before {
        background-color: #58f5a1;
        box-shadow: none;
        border: none;
      }
    }
  }
  .ant-switch-handle {
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: transparent;
    &:before {
      border: 1px solid #707070;
      box-shadow: none;
    }
  }
`;

export const CustomTag = styled(Tag)`
  text-transform: capitalize;
  text-align: center;
  min-width: 54px;
  margin-left: 7px;
`;

export const RowTrigger = styled.div`
  display: grid;
  grid-template-columns: 0.7fr repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 5px;
  margin-left: 15px;
  max-width: 80%;
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
`;

export const CustomButton = styled(Button)`
  text-transform: capitalize;
  text-align: center;
  min-width: 97px;
  margin-right: 7px;
  border-color: #707070;
  background-color: transparent;
  padding: 0 12px;
  border-radius: 4px;
  height: 30px;
  font-weight: 700;
  color: #707070;
  position: absolute;
  bottom: -40px;
  right: 0;
  &:hover,
  &:focus {
    color: #707070;
    border-color: #58f5a1;
    background-color: #58f5a1;
  }
`;
