import api from '../../infrascruture/httpRequest';
import getEndpoint from '../../utils/getEndpoint';

// import { responseTag } from './response';

export const TOKEN_KEY_OPERATION_NAME = `@Beon-adm-panel:operation-name`;
export const TOKEN_KEY_OPERATION_ID = `@Beon-adm-panel:operation-id`;

export const getDataFromEndpoints = async () => {
  const endpoint = getEndpoint('apiTenant');
  const tenants = await api.get(`${endpoint}/tenants`);
  const orderedTenants = tenants.data.sort((a, b) =>
    a.profile.name > b.profile.name ? 1 : -1,
  );

  return orderedTenants;
};

export const selectTenant = tenant => {
  const { _id, profile } = tenant;
  const tenantName = profile.name.toUpperCase();

  localStorage.setItem(TOKEN_KEY_OPERATION_NAME, JSON.stringify(tenantName));
  localStorage.setItem(TOKEN_KEY_OPERATION_ID, JSON.stringify(_id));
};

const getTenantProducts = ({ beon_studio, beon_search }) => {
  const products = [];

  if (beon_studio) products.push('beon_studio');
  if (beon_search) products.push('beon_search');

  return products;
};

export const registerTenant = async values => {
  const endpoint = getEndpoint('apiTenant');
  const originsCluster = [values.base_url];

  const name = values.name.trim();
  const products = getTenantProducts(values);

  const flags = {};

  Object.keys(values).forEach(key => {
    if (key.startsWith('flag_')) {
      const flagName = key.substring(5);
      flags[flagName] = values[key];
      delete values[key];
    }
  });

  delete values.beon_search;
  delete values.beon_studio;

  const tenantData = {
    profile: { ...values, name, products, flags, active: true, version: '' },
    origins: originsCluster,
  };

  console.log('valores enviados para Tenant API: ', tenantData);
  const tenant = await api.post(`${endpoint}/tenants`, tenantData);

  console.log('dados recebidos da criacao: ', tenant);
  if (tenant.status === 201) {
    console.log('resultado criacao: ', tenant);
    const id = tenant.data._id;
    const operationName = values.name;
    localStorage.setItem(
      TOKEN_KEY_OPERATION_NAME,
      JSON.stringify(operationName),
    );
    localStorage.setItem(TOKEN_KEY_OPERATION_ID, JSON.stringify(id));

    return id;
  }

  return '';
};

export const updateTenant = async (values, id) => {
  const endpoint = getEndpoint('apiTenant');
  const { origins } = values;
  const products = getTenantProducts(values);
  const flags = {};

  Object.keys(values).forEach(key => {
    if (key.startsWith('flag_')) {
      const flagName = key.substring(5);
      flags[flagName] = values[key];
      delete values[key];
    }
  });

  delete values.beon_search;
  delete values.beon_studio;
  delete values.origins;

  const tenantData = {
    profile: {
      ...values,
      products,
      flags,
    },
    origins,
  };

  const tenant = await api.post(`${endpoint}/${id}/update`, tenantData);

  if (tenant.status === 200) {
    return true;
  }
  return false;
};

export const getTenantData = async id => {
  try {
    const endpoint = getEndpoint('apiTenant');
    const tenant = await api.get(`${endpoint}/${id}`);
    return tenant;
  } catch (err) {
    return {};
  }
};
