import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Form, Input, Select, Space, Tooltip } from 'antd';
import { useCatalog } from '../../../../context/catalog';

const { Panel } = Collapse;

const ClustersTypeMap = ({ formKey }) => {
  const [keyName, keyProps] = formKey;
  const { integrationData } = useCatalog();
  const savedClustersTM = integrationData[keyName];

  const { helper, label } = keyProps;

  const showCurrentAttrs = () => {
    const normalizedAttrs = savedClustersTM
      ? savedClustersTM.map(attr => {
          const { cluster_ids, name } = attr;

          return {
            cluster_ids,
            name,
          };
        })
      : [];

    return normalizedAttrs;
  };

  return (
    <Collapse
      defaultActiveKey={`${savedClustersTM?.length ? ['1'] : []}`}
      style={{ marginBottom: '2rem' }}
    >
      <Panel
        header={
          <>
            {label}{' '}
            <Tooltip title={helper}>
              <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </>
        }
        key="1"
      >
        <Form.Item>
          <Form.List name={keyName} initialValue={showCurrentAttrs()}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'cluster_ids']}
                      rules={[
                        {
                          required: true,
                          message: 'Inclua pelo menos um ID Cluster Vtex',
                        },
                      ]}
                      style={{ width: '300px' }}
                    >
                      <Select mode="tags" placeholder="IDs Cluster Vtex" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Inclua a classificação',
                        },
                      ]}
                    >
                      <Input placeholder="Tipo Classificação" />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar atributo
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

ClustersTypeMap.propTypes = {};

export default ClustersTypeMap;
