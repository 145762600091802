import { Card } from 'antd';
import styled from 'styled-components';

export const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 3rem 0;
  width: 100%;

  img {
    height: 200px;
    width: 250px;
    object-fit: contain;
  }

  button {
    margin-top: 1rem;
  }
`;
