import { Row, Col, Divider, Tabs } from 'antd';
import { useParams } from 'react-router-dom';

import IntegrationFormModal from './IntegrationModal';
import FeedPreviewModal from './IntegrationModal/FeedPreviewModal';
import HookModal from './IntegrationModal/HookModal';
import TenantConfig from './TenantConfig';
import VtexLogs from './VtexLogs';
import FeedLogs from './FeedLogs';
import { useCatalog } from '../../context/catalog';

const { TabPane } = Tabs;

const CatalogIntegration = () => {
  const { integration } = useParams();
  const { hookModal, setHookModal } = useCatalog();

  if (integration === 'vtex') {
    return (
      <Row gutter={16}>
        <Col span={18} className="gutter-row">
          <h2>Integrações de catálogo VTEX</h2>

          <Divider />

          <Tabs defaultActiveKey="config" size="large">
            <TabPane tab="Configuração" key="config">
              <TenantConfig />
            </TabPane>

            <TabPane tab="Logs" key="vtex_logs">
              <VtexLogs />
            </TabPane>
          </Tabs>
        </Col>

        <IntegrationFormModal />
      </Row>
    );
  }

  if (integration === 'nuvemshop') {
    return (
      <Row gutter={16}>
        <Col span={18} className="gutter-row">
          <h2>Integrações de catálogo NuvemShop</h2>

          <Divider />

          <Tabs defaultActiveKey="config" size="large">
            <TabPane tab="Configuração" key="config">
              <TenantConfig />
            </TabPane>
          </Tabs>
        </Col>

        <IntegrationFormModal />
      </Row>
    );
  }

  return (
    <Row gutter={16}>
      <Col span={18} className="gutter-row">
        <h2>Integrações de catálogo FEED</h2>

        <Divider />

        <Tabs defaultActiveKey="config" size="large">
          <TabPane tab="Configuração" key="config">
            <TenantConfig />
          </TabPane>

          <TabPane tab="Logs" key="feed_logs">
            <FeedLogs />
          </TabPane>
        </Tabs>
      </Col>

      <IntegrationFormModal />
      <FeedPreviewModal />
      {hookModal.visible && (
        <HookModal hookModal={hookModal} setHookModal={setHookModal} />
      )}
    </Row>
  );
};

export default CatalogIntegration;
