const platforms = [
  {
    name: 'VTEX',
    value: 'vtex',
  },
  {
    name: 'FEED',
    value: 'feed',
  },
  {
    name: 'VTEXIO',
    value: 'vtexio',
  },
  {
    name: 'Tray',
    value: 'tray',
  },

  {
    name: 'Magento 1.0',
    value: 'mag1x',
  },
  {
    name: 'Magento 2.0',
    value: 'mag2x',
  },
  {
    name: 'Shopify',
    value: 'shopf',
  },
  {
    name: 'Linx Impulse',
    value: 'linx',
  },
  {
    name: 'NuvemShop',
    value: 'nuvemshop',
  },
  {
    name: 'Loja integrada',
    value: 'ljint',
  },
  {
    name: 'Própria',
    value: 'own',
  },
  {
    name: 'Outra',
    value: 'other',
  },
];

export default Object.freeze(platforms);
