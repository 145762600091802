import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import js_beautify from 'js-beautify';
import { ModalContent, ProductImage, JsonContainer } from './productModalStyle';

const ProductModal = ({ visible, setShowProductModal, product }) => {
  const { images } = product;

  return (
    <Modal
      visible={visible}
      onCancel={() => setShowProductModal(false)}
      width="80%"
      footer={[
        <Button type="primary" onClick={() => setShowProductModal(false)}>
          Ok
        </Button>,
      ]}
    >
      <ModalContent>
        <ProductImage src={images[0]} />
        <JsonContainer>
          <SyntaxHighlighter
            wrapLines
            wrapLongLines
            language="html"
            style={a11yLight}
          >
            {js_beautify(JSON.stringify(product))}
          </SyntaxHighlighter>
        </JsonContainer>
      </ModalContent>
    </Modal>
  );
};

ProductModal.propTypes = {
  visible: PropTypes.bool,
  setShowProductModal: PropTypes.func,
  product: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    product_id: PropTypes.string,
    sku: PropTypes.string,
    is_active: PropTypes.bool,
    is_available: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    url: PropTypes.string,
    additional_prices: PropTypes.shape({
      avista: PropTypes.number,
    }),
    price_from: PropTypes.number,
    price_to: PropTypes.number,
    installment_months: PropTypes.number,
    installment_amount: PropTypes.number,
    trees: PropTypes.shape({}),
    is_promo: PropTypes.bool,
    is_dirty: PropTypes.bool,
    deleted_at: PropTypes.string,
    pathname: PropTypes.string,
    price_special_days_left: PropTypes.number,
    price_special_until: PropTypes.string,
    price_unit_multiplier: PropTypes.number,
    source: PropTypes.string,
    release_date: PropTypes.string,
    price_discount_off: PropTypes.number,
    price_discount_value: PropTypes.number,
    keywords: PropTypes.arrayOf(PropTypes.string),
    media_specification: PropTypes.arrayOf(PropTypes.string),
    must_update: PropTypes.arrayOf(PropTypes.string),
    references: PropTypes.arrayOf(PropTypes.string),
    details: PropTypes.shape({}),
  }),
};

export default ProductModal;
