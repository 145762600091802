import api from '../../infrascruture/httpRequest';
import getEndpoint from '../../utils/getEndpoint';

const endpoint = getEndpoint('apiRankings');

export const getDataFromEndpoints = async id => {
  const tenantConfig = await api.get(`${endpoint}/${id}/config`);
  const rankingsDescribe = await api.get(`${endpoint}/${id}/describe`);

  return { tenantConfig, rankingsDescribe };
};
