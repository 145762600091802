import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Tooltip,
  Divider,
  Button,
  Tag,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import StatusDetails from '../../components/StatusDetails';
import sendNotification from '../../components/Notification/index.tsx';
import {
  updateGaConfig,
  getGAInfo,
  getGAAuth,
  getGAViews,
} from '../../domain/GoogleAnalytics';

const { Option } = Select;

const GoogleAnalytics = props => {
  const history = useHistory();
  const { id } = useParams();
  const { status, alerts } = props.location.state;
  const [gaConfig, setGAConfig] = useState(false);
  const [gaViews, setGAViews] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isGAViewsLoading, setIsGAViewsLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
      xl: {
        span: 24,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
      xl: {
        span: 16,
      },
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 14,
      span: 0,
    },
  };

  const handleGAAuth = async () => {
    setIsLoading(true);
    const authorized = await getGAAuth(id);
    console.log(authorized);
    setGAConfig({ ...gaConfig, authStatus: authorized });
    setIsLoading(false);
    return authorized;
  };

  const onFinish = async values => {
    try {
      setIsLoading(true);
      await updateGaConfig(values, id);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const onFinishFailed = () => {
    sendNotification(
      'Informações preenchidas incorretamente. Por favor cheque novamente e faça as devidas correções.',
      'error',
    );
  };

  const checkGAViews = async () => {
    const result = await getGAViews({
      id,
      ua_property: gaConfig.configData.ua_property,
    });

    setIsGAViewsLoading(false);

    if (Array.isArray(result)) {
      setGAViews(result);
      return;
    }
    sendNotification(result, 'error');
  };

  const handleGAViews = async () => {
    setIsGAViewsLoading(true);
    if (!gaConfig.authStatus) {
      const authorized = await handleGAAuth(id);

      if (authorized) {
        await checkGAViews();
      } else {
        setIsGAViewsLoading(false);
        sendNotification(
          'Propriedade inválida ou não autorizada para a conta acima. Favor checar e consultar novamente.',
          'error',
        );
      }
    } else {
      await checkGAViews();
    }
  };

  useEffect(() => {
    const getGAData = async () => {
      setIsLoading(true);
      const result = await getGAInfo(id);
      setGAConfig(result);

      setIsLoading(false);
    };

    getGAData();
  }, [id]);

  return (
    <>
      <Row gutter={16}>
        <Col span={18} className="gutter-row">
          <h2>Integração Google Analytics</h2>
          <Form
            {...formItemLayout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <h3>01 - PERMISSÕES DA CONTA</h3>
            <Divider />
            <p>
              É necessário conceder permissão de leitura na propriedade
              principal da loja para a conta abaixo:
            </p>
            <h4 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              google@usebeon.com
            </h4>
            <br />
            <p>
              Situação:
              {gaConfig.authStatus ? (
                <span style={{ marginLeft: '4px' }}>
                  <Tag color="green">Autorizado</Tag>
                </span>
              ) : (
                <span style={{ marginLeft: '4px' }}>
                  <Tag color="red">Sem permissão</Tag>
                </span>
              )}
            </p>
            <Button
              style={{ marginBottom: '16px' }}
              onClick={() => handleGAAuth(id)}
              loading={isLoading}
            >
              Checar acesso
            </Button>

            <h3>02 - CONFIGURAÇÃO DA PROPRIEDADE</h3>
            <Divider />
            {gaConfig && (
              <Form.Item
                required
                label="ID da propriedade"
                name="ua_property"
                initialValue={gaConfig.configData.ua_property || ''}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'string',
                    min: 10,
                  },
                ]}
                hasFeedback
              >
                <Input
                  placeholder="Entre com o ID da propriedade"
                  style={{ width: '100%' }}
                  onChange={event =>
                    setGAConfig({
                      ...gaConfig,
                      configData: {
                        ...gaConfig.configData,
                        [event.target.id]: event.target.value,
                      },
                    })
                  }
                  onBlur={handleGAViews}
                  suffix={
                    <Tooltip title="Uma propriedade do Google Analytics tem o formato UA-xxxxx-xx">
                      <QuestionCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            )}

            {gaViews ? (
              <Form.Item
                required
                label="Visão da propriedade"
                name="view"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'string',
                    min: 3,
                  },
                ]}
              >
                <Select
                  placeholder="Selecione uma visão"
                  // defaultValue={gaConfig.configData.view_id}
                >
                  {gaViews.map(view => (
                    <Option key={view.view_id} value={view.view_label}>
                      {view.view_id} - {view.view_label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <p>Não foi possível encontrar nenhuma View</p>
            )}

            <Button
              onClick={() => handleGAViews()}
              loading={isGAViewsLoading}
              disabled={!gaConfig.authStatus}
            >
              Atualizar visões
            </Button>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item {...tailLayout}>
                <Button
                  size="large"
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => history.push(`/status/${id}`)}
                >
                  Cancelar
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Col>
        <Col span={6} className="gutter-row">
          <StatusDetails status={status} alertMessages={alerts} />
        </Col>
      </Row>
    </>
  );
};

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default GoogleAnalytics;
