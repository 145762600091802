import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button, Card, Collapse, Space, Typography } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import sendNotification from '../../../../components/Notification/index.tsx';
import api from '../../../../infrascruture/httpRequest';

const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

const IntegrationLogs = ({ baseUrl, formatDate }) => {
  const [logsData, setLogsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const getIntegrationLogs = async () => {
    try {
      setIsLoading(true);
      setLogsData({});

      const url = `${baseUrl}/${id}/logs`;
      const response = await api.get(url);

      switch (response?.status) {
        case 200:
          if (
            response?.data === null ||
            Object.keys(response?.data)?.length === 0
          ) {
            sendNotification(
              'Nenhuma integração completa realizada até o momento',
              'error',
            );
            return;
          }

          setLogsData(response?.data);
          break;

        default:
          sendNotification('Algo deu errado, tente novamente', 'error');
          break;
      }
    } catch (error) {
      setLogsData({});
      sendNotification('Algo deu errado, tente novamente', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const generateLogsText = () => {
    try {
      const {
        created_at: createdAt,
        dispatched,
        errored,
        integrated,
        last_page_processed: lastProcessedPage,
        page_size: pageSize,
        status,
        updated_at: updatedAt,
      } = logsData;

      return (
        <Card style={{ marginTop: '20px' }}>
          <Space direction="vertical">
            <Text>
              Status: {status === 'pending' ? 'Iniciado' : 'Finalizado'}
            </Text>
            <Text>Início: {formatDate(createdAt)}</Text>
            <Text>Última atualização: {formatDate(updatedAt)}</Text>
            <Text>Última página processada: {lastProcessedPage}</Text>
            <Text>Quantidade máxima de itens por página: {pageSize}</Text>
            <Text>Itens enviados para a fila: {dispatched}</Text>
            <Text>Itens já processados: {integrated}</Text>
            <Text>Itens não mais disponíveis API VTEX: {errored}</Text>
          </Space>
        </Card>
      );
    } catch (e) {
      sendNotification('Algo deu errado, tente novamente', 'error');
      console.error(e);
      return '';
    }
  };

  return (
    <Collapse>
      <Panel header="Registro de integração completa" key="detailed_logs">
        <Paragraph>
          <InfoCircleTwoTone /> Logs com detalhes de Integração Completa
        </Paragraph>

        <Button
          type="primary"
          htmlType="button"
          onClick={getIntegrationLogs}
          loading={isLoading}
          style={{ display: 'block', marginBottom: '20px' }}
        >
          Buscar
        </Button>

        {Object.keys(logsData)?.length > 0 && generateLogsText()}
      </Panel>
    </Collapse>
  );
};

IntegrationLogs.propTypes = {
  baseUrl: PropTypes.string,
  formatDate: PropTypes.func,
};

export default IntegrationLogs;
