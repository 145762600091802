import styled from 'styled-components';
import { Button } from 'antd';

export const CustomButton = styled(Button)`
  text-transform: capitalize;
  text-align: center;
  min-width: 97px;
  margin-right: 7px;
  border-color: ${props => (props.isActive ? '#58f5a1' : '#707070')};
  background-color: ${props => (props.isActive ? '#58f5a1' : 'transparent')};
  padding: 0 12px;
  border-radius: 4px;
  height: 30px;
  font-weight: 700;
  color: #707070;

  &:hover,
  &:focus {
    color: #707070;
    border-color: #58f5a1;
    background-color: #58f5a1;
  }
`;
