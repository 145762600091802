import api from '../../infrascruture/httpRequest';
import endpoints from '../../constants/endpoints';

export const getDataFromEndpoints = async id => {
  const devEnvironment = process.env.REACT_APP_MODE === 'development';
  let healthcheckPath = `healthcheck/${id}`;

  const waitFor = ms => new Promise(r => setTimeout(r, ms));
  const apiCalls = [];

  const asyncForEach = async (array, callback) => {
    const activeEndpoints = array.filter(
      endpoint => endpoint.active && endpoint.healthcheck,
    );
    console.log('active endpoints', activeEndpoints);
    for (let index = 0; index < activeEndpoints.length; index += 1) {
      await callback(activeEndpoints[index]);
      // await callback(activeEndpoints[index], index, activeEndpoints);
    }
  };

  console.log('Iniciou request');

  await asyncForEach(endpoints, async endpoint => {
    try {
      await waitFor(50);
      if (devEnvironment) {
        healthcheckPath = `healthcheck/${endpoint.resource}/${id}`;
      }
      console.log('paths', healthcheckPath);
      await api
        .get(`${endpoint.resource}/${healthcheckPath}`)
        .then(response => {
          if (response.status !== 200) {
            apiCalls.push({});
            return;
          }
          console.log(response.data);
          apiCalls.push({
            ...response.data,
            title: endpoint.title,
            type: endpoint.type,
          });
        });
    } catch (e) {
      console.error(e);
    }
  });
  console.log('Finalizou request');
  console.log('ApiCalls', apiCalls);

  return apiCalls;
};

export const createSummary = operationStatuses => {
  const stepsCompleted = operationStatuses.filter(
    service => service.status === 'success',
  ).length;
  const errors = operationStatuses.filter(
    service => service.status === 'error',
  ).length;
  const warnings = operationStatuses.filter(
    service => service.status === 'warning',
  ).length;

  const summary = {
    stepsCompleted,
    errors,
    warnings,
  };

  return summary;
};
