import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  createConfig,
  updateConfig,
  getTenantIntegrationsGeneralInfo,
  generateLog,
} from '../domain/catalog';
import sendNotification from '../components/Notification/index.tsx';
import getUpdateDifference from '../utils/getUpdateDifference';

const CatalogContext = createContext(null);

export const CatalogProvider = ({ children }) => {
  const [formKeys, setFormKeys] = useState([]);
  const [requiredItems, setRequiredItems] = useState([]);
  const [integrations, setIntegrations] = useState({});
  const [integrationData, setIntegrationData] = useState({});
  const [provider, setProvider] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadFormOnAction, setLoadFormOnAction] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedTreeAttributes, setFeedTreeAttributes] = useState([]);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [feedPreview, setFeedPreview] = useState({});
  const [feedTenantId, setFeedTenantId] = useState('');
  const [hookModal, setHookModal] = useState({
    visible: false,
    integrationId: '',
  });
  const [feedSaveButtonDisable, setFeedSaveButtonDisable] = useState(false);

  const getIntegrations = async (id, currProvider) => {
    setIntegrations({});
    setIntegrationData({});

    const result = await getTenantIntegrationsGeneralInfo(id, currProvider);

    if (result?.status === 200) {
      if (currProvider === 'integra-feed') {
        setIntegrations(result.data);
        return true;
      }

      setIntegrations(result.data);
      const intData = {};
      const {
        data: { payload },
      } = result;

      intData.active = payload.active;
      intData._id = payload._id;
      intData.tenant_id = payload.tenant_id;

      Object.entries(payload.credentials).map(([key, value]) => {
        const newKey = `payload_credentials_${key}`;
        intData[newKey] = value;
      });

      Object.entries(payload.parse).map(([key, value]) => {
        const newKey = `payload_parse_${key}`;
        intData[newKey] = value;
      });

      setIntegrationData(intData);
      return true;
    }

    return false;
  };

  const getFeedIntegrationData = integrationId => {
    const integration = integrations.find(
      currIntegration => currIntegration._id === integrationId,
    );

    const {
      config: { hook, product_tags, tree_tags, feed_item_tag },
      ...rest
    } = integration;

    const primordialKeys = {};

    if (hook) {
      Object.entries(hook).forEach(entry => {
        const [keyName, keyValue] = entry;
        const fullname = `config_hook_${keyName}`;

        primordialKeys[fullname] = keyValue;
      });
    }

    if (product_tags) {
      Object.entries(product_tags).forEach(entry => {
        const [keyName, keyValue] = entry;

        if (keyName !== 'contexts') {
          const fullName = `config_product_tags_${keyName}`;

          primordialKeys[fullName] = keyValue;
        }
      });

      Object.entries(product_tags.contexts).forEach(entry => {
        const [keyName, keyValue] = entry;
        const fullName = `config_product_tags_contexts_${keyName}`;
        primordialKeys[fullName] = keyValue;
      });
    }

    if (tree_tags) {
      Object.entries(tree_tags).forEach(entry => {
        const [keyName, keyValue] = entry;
        const fullName = `config_tree_tags_${keyName}`;

        if (keyName === 'tree_attributes') {
          setFeedTreeAttributes(keyValue);
        }

        primordialKeys[fullName] = keyValue;
      });
    }

    const { createdAt, updatedAt, _id, tenant_id, ...finalRest } = rest;

    const finalPrimordialKeys = {
      ...primordialKeys,
      tenantId: tenant_id,
      ...finalRest,
      config_feed_item_tag: feed_item_tag,
      integrationId: _id,
    };

    setIntegrationData(finalPrimordialKeys);

    return integration;
  };

  const hasOtherFeedIntegrationActive = integrationId => {
    if (integrations.length > 0) {
      const integration = integrations.find(
        currIntegration =>
          currIntegration.active === true &&
          currIntegration._id !== integrationId,
      );

      if (integration) return true;
    }

    return false;
  };

  const handleCancel = () => {
    setProvider('');
    setIntegrationData({});
    setLoadFormOnAction('');
    setIsModalVisible(false);
  };

  const formatVtexExceptionItems = values => {
    const parse = {};
    const credentials = {};

    if (provider === 'vtex') {
      // ADDITIONAL PRICES RULES
      const pricesRulesArray = [];
      const { payload_parse_additional_prices_rules: pricesRules } = values;
      pricesRules?.forEach(({ key, name, value }) =>
        pricesRulesArray.push({ key, name, value: value / 100 }),
      );
      delete values.payload_parse_additional_prices_rules;
      parse.additional_prices_rules = pricesRulesArray;

      // IMAGES SIzE CONSTRAINT
      const { payload_parse_images_size_constraint: imgSize } = values;
      if (imgSize) {
        const imgsSizeConstraints = imgSize.split(',');
        values.payload_parse_images_size_constraint = imgsSizeConstraints;
      } else {
        values.payload_parse_images_size_constraint = [''];
      }

      // SPECS PARSE RULES
      const specsParseRules = [];
      const { payload_parse_specs_parse_rules: specsPR } = values;
      specsPR?.map(({ spec_name, spec_rule }) =>
        specsParseRules.push({ [spec_name]: spec_rule }),
      );

      delete values.payload_parse_specs_parse_rules;
      parse.specs_parse_rules = specsParseRules;
    }

    Object.entries(values).map(([key, value]) => {
      if (key.split('_')[1] === 'parse') {
        const nKey = key.split('payload_parse_')[1];
        parse[nKey] = value;

        delete values[key];
      }

      if (key.split('_')[1] === 'credentials') {
        const nKey = key.split('payload_credentials_')[1];
        credentials[nKey] = value;

        delete values[key];
      }
    });

    if (loadFormOnAction === 'add') {
      delete values.integrationId;
    }

    values.parse = parse;
    values.credentials = credentials;

    const userId = JSON.parse(localStorage.getItem('@Beon-adm-panel:userid'));

    if (loadFormOnAction === 'add') {
      values = {
        payload: { ...values },
        tenant_id: values.tenant_id,
        modified_by: userId,
        active: values.active,
      };
    } else {
      values = {
        _id: integrations._id,
        tenant_id: values.tenant_id,
        payload: { ...values },
        active: values.active,
        modified_by: userId,
      };
    }

    return values;
  };

  const formatFeedExceptionItems = (
    values,
    tenantId,
    integrationId,
    status = 'add',
  ) => {
    const finalBody = {
      config: {
        hook: {},
        product_tags: {
          contexts: {},
        },
        tree_tags: {},
      },
    };

    Object.entries(values).forEach(entry => {
      const [keyName, keyValue] = entry;

      if (keyName.includes('hook')) {
        const separatedKeyName = keyName.split('hook_');
        const finalKeyName = separatedKeyName.slice(-1);
        finalBody.config.hook[finalKeyName] = keyValue;
      }

      if (keyName.includes('product_tags')) {
        if (keyName.includes('context')) {
          const separatedKeyName = keyName.split('contexts_');
          const finalKeyName = separatedKeyName.slice(-1);
          finalBody.config.product_tags.contexts[finalKeyName] = keyValue;
        } else {
          const separatedKeyName = keyName.split('product_tags_');
          const finalKeyName = separatedKeyName.slice(-1);
          let valueToAdd;

          if (
            finalKeyName[0] === 'pathname_depth' ||
            finalKeyName[0] === 'installment_amount' ||
            finalKeyName[0] === 'installment_months' ||
            finalKeyName[0] === 'price_additional_discount'
          ) {
            if (keyValue === '') {
              valueToAdd = 0;
            } else {
              valueToAdd = +keyValue;
            }
          } else {
            valueToAdd = keyValue;
          }

          finalBody.config.product_tags[finalKeyName] = valueToAdd;
        }
      }

      if (keyName.includes('tree_tags')) {
        const separatedKeyName = keyName.split('tree_tags_');
        const finalKeyName = separatedKeyName.slice(-1);
        finalBody.config.tree_tags[finalKeyName] = keyValue;
      }
    });

    finalBody.active = false;
    finalBody.last_integration = null;
    finalBody.tenant_id = tenantId;
    finalBody.tested = false;
    finalBody.feed_url = values.feed_url;
    finalBody.config.feed_item_tag = values.config_feed_item_tag;

    if (integrations.length > 0 && status === 'update') {
      const integration = getFeedIntegrationData(integrationId);
      finalBody.config.interval = integration.config.interval;
      finalBody.config.hook = integration.config.hook;
    }

    return finalBody;
  };

  const getLog = (logBody, tenantId, resource, action) => {
    const user_email = localStorage.getItem('@Beon-adm-panel:user');
    const user_id = JSON.parse(localStorage.getItem('@Beon-adm-panel:userid'));

    let log;

    if (logBody !== null) {
      log = {
        tenant_id: tenantId,
        time_stamp: new Date().toJSON(),
        user_email,
        user_id,
        app_location: 'Painel ADM',
        resource,
        action_type: action,
        body: logBody,
      };
    } else {
      log = {
        tenant_id: tenantId,
        time_stamp: new Date().toJSON(),
        user_email,
        user_id,
        app_location: 'Painel ADM',
        resource,
        action_type: action,
      };
    }

    return log;
  };

  const addIntegration = async (values, tenantId) => {
    setIsLoading(true);

    let formattedValues;
    if (provider === 'vtex') {
      formattedValues = formatVtexExceptionItems(values);
    } else if (provider === 'feed') {
      formattedValues = formatFeedExceptionItems(values, tenantId);
    }

    const result = await createConfig(formattedValues, tenantId, provider);

    if (!result) {
      sendNotification(
        'Ocorreu um erro nesta operação. Por favor revise os dados e tente novamente.',
        'error',
      );
      return;
    }

    if (result instanceof Error) {
      sendNotification(
        'Ocorreu um erro nesta operação. Tente novamente em mais alguns minutos.',
        'error',
      );
      setIsModalVisible(false);
      return;
    }

    handleCancel();
    sendNotification('Integração adicionada com sucesso', 'success');
    await getIntegrations(tenantId, provider);
    setIsLoading(false);

    const logBody = getUpdateDifference(
      formattedValues,
      provider,
      integrationData,
      integrations,
    );

    const action = loadFormOnAction === 'add' ? 'Criação' : 'Edição';
    const log = getLog(logBody, tenantId, provider, action);

    await generateLog(log);
  };

  const updateIntegration = async ({ values, tenantId, integrationId }) => {
    const { provider: currProvider, ...restValues } = values;

    try {
      setIsLoading(true);
      let formattedValues;

      if (currProvider === 'vtex') {
        formattedValues = formatVtexExceptionItems(values);
      } else if (currProvider === 'feed' || currProvider === 'integra-feed') {
        formattedValues = formatFeedExceptionItems(
          restValues,
          tenantId,
          integrationId,
          'update',
        );
      }

      const result = await updateConfig({
        formattedValues,
        tenantId,
        integrationId,
        provider,
      });

      if (!result) {
        sendNotification(
          'Ocorreu um erro nesta operação. Por favor revise os dados e tente novamente.',
          'error',
        );

        return;
      }

      handleCancel();
      sendNotification('Integração atualizada com sucesso', 'success');
      await getIntegrations(tenantId, currProvider);
      setIsLoading(false);

      const logBody = getUpdateDifference(
        formattedValues,
        currProvider,
        integrationData,
        integrations,
      );

      const log = getLog(logBody, tenantId, currProvider, 'Edição');
      await generateLog(log);
    } catch (e) {
      sendNotification(
        'Ocorreu um erro nesta operação. Tente novamente em mais alguns minutos.',
        'error',
      );
    }
  };

  const updateFeedHook = async (values, tenantId) => {
    const { currProvider, ...restValues } = values;

    restValues.tenant_id = tenantId;
    restValues.last_integration = null;

    try {
      const result = await updateConfig({
        formattedValues: restValues,
        tenantId,
        integrationId: hookModal.integrationId,
        provider: currProvider,
      });

      if (!result) {
        sendNotification(
          'Ocorreu um erro nesta operação. Por favor revise os dados e tente novamente.',
          'error',
        );
        return;
      }

      handleCancel();
      sendNotification('Hook atualizado com sucesso', 'success');
      setHookModal({ ...hookModal, visible: false });
      await getIntegrations(tenantId, currProvider);

      const logBody = getUpdateDifference(
        restValues,
        currProvider,
        integrationData,
        integrations,
      );

      const log = getLog(logBody, tenantId, 'Hook personalizado', 'Edição');
      await generateLog(log);
    } catch (e) {
      sendNotification(
        'Ocorreu um erro nesta operação. Tente novamente em mais alguns minutos.',
        'error',
      );
    }
  };

  const toggleIntegrationStatus = async (
    tenantId,
    currProvider,
    integrationId,
  ) => {
    setIsLoading(true);

    const configs = integrations;
    configs.active = !configs.active;
    configs._id = integrations._id;

    let result;

    if (currProvider === 'integra-feed') {
      const feedConfig = getFeedIntegrationData(integrationId);

      if (!hasOtherFeedIntegrationActive(integrationId)) {
        feedConfig.active = !feedConfig.active;

        result = await updateConfig({
          formattedValues: feedConfig,
          integrationId: feedConfig._id,
          provider: currProvider,
          tenantId,
        });
      } else {
        sendNotification(
          'Este cliente já possui um integração feed ativa, por favor, desabilite-a primeiro para poder ativar esta',
          'error',
        );
        return;
      }
    } else {
      result = await createConfig(configs, tenantId, currProvider);
    }

    if (result) {
      await getIntegrations(tenantId, currProvider);
      sendNotification('Status atualizado com sucesso', 'success');
    } else {
      sendNotification(
        'Ocorreu um erro. Tente novamente em mais alguns minutos.',
        'error',
      );
    }
    setIsLoading(false);
  };

  return (
    <CatalogContext.Provider
      value={{
        addIntegration,
        formKeys,
        getIntegrations,
        handleCancel,
        integrationData,
        integrations,
        isLoading,
        isModalVisible,
        loadFormOnAction,
        provider,
        requiredItems,
        setFormKeys,
        setIntegrationData,
        setIntegrations,
        setIsLoading,
        setIsModalVisible,
        setLoadFormOnAction,
        setProvider,
        setRequiredItems,
        toggleIntegrationStatus,
        updateIntegration,
        feedTreeAttributes,
        setFeedTreeAttributes,
        isPreviewModalVisible,
        setIsPreviewModalVisible,
        feedPreview,
        setFeedPreview,
        setFeedTenantId,
        feedTenantId,
        hookModal,
        setHookModal,
        updateFeedHook,
        getFeedIntegrationData,
        hasOtherFeedIntegrationActive,
        feedSaveButtonDisable,
        setFeedSaveButtonDisable,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

CatalogProvider.propTypes = {
  children: PropTypes.node,
};

export const useCatalog = () => useContext(CatalogContext);
