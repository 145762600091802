import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Divider, Row, Col } from 'antd';

import { useAuth } from '../../context/user.tsx';

// import StatusDetails from '../../components/StatusDetails';

// import { Container, ContainerContent } from './styles';

import Loading from '../../components/Loading';
import sendNotification from '../../components/Notification/index.tsx';
import HandleUserModal from './HandleUserModal';
import UsersTable from './UsersTable';
import { generateLog } from '../../domain/catalog';

const UserMgmt = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState({
    visible: false,
    action: '',
    data: {},
  });

  const showModal = (action, userData) => {
    console.log('showModal', userData);
    setIsModalVisible({
      visible: true,
      action,
      data: userData,
    });
  };

  const resetModal = () => {
    setIsModalVisible({
      visible: false,
      action: '',
      data: {},
    });
  };

  const { listUsers, createUser, updateUser } = useAuth();
  const { id } = useParams();
  // const { status, alerts } = props.location.state;

  const loadUsers = async () => {
    setIsLoading(true);
    const response = await listUsers(id);
    setIsLoading(false);
    setUsers(response.data);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const getFieldsWithDifference = (data, newData) => {
    const fieldsWithDifference = {};

    Object.entries(newData).forEach(entry => {
      const [entryName, entryValue] = entry;

      if (
        entryValue !== data[entryName] &&
        entryName !== 'active' &&
        entryName !== 'role' &&
        entryName !== 'id'
      ) {
        fieldsWithDifference[entryName] = entryValue;
      }

      if (entryName === 'active') {
        const keyName = `org:${id}`;
        if (entryValue !== data.acl[keyName].active) {
          fieldsWithDifference[entryName] = entryValue;
        }
      }

      if (entryName === 'role') {
        const keyName = `org:${id}`;
        if (entryValue !== data.acl[keyName].type) {
          fieldsWithDifference[entryName] = entryValue;
        }
      }
    });

    if (
      Object.entries(fieldsWithDifference).length > 5 ||
      Object.entries(fieldsWithDifference).length === 0
    ) {
      return null;
    }

    return fieldsWithDifference;
  };

  const setLog = async (data, action) => {
    let userFound = {};

    users.forEach(user => {
      const aclEntries = Object.entries(user.acl);

      aclEntries.forEach(entry => {
        const [entryName] = entry;
        const tenantId = entryName.split(':');

        if (tenantId[1] === data.id) {
          userFound = user;
        }
      });
    });

    const user_email = localStorage.getItem('@Beon-adm-panel:user');
    const user_id = JSON.parse(localStorage.getItem('@Beon-adm-panel:userid'));

    let log;

    if (action === 'edit') {
      const logBody = getFieldsWithDifference(userFound, data);

      if (logBody) {
        log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: `Gestão de usuários - ${data.fullname}`,
          action_type: 'edição',
          body: logBody,
        };
      } else {
        log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: `Gestão de usuários - ${data.fullname}`,
          action_type: 'edição',
        };
      }
    }

    const result = await generateLog(log);

    if (result.status !== 201) {
      sendNotification('Falha ao gerar log', 'error');
    }
  };

  const handleUser = async (action, data) => {
    const userRequest = action === 'add' ? createUser(data) : updateUser(data);

    const response = await userRequest;

    if (response instanceof Error) {
      sendNotification(
        'Ocorreu uma falha inesperada. Aguarde mais alguns instantes e tente novamente.',
        'error',
      );
    } else if (!response) {
      sendNotification(
        'Uma falha foi identificada. Verifique os dados e tente novamente.',
        'error',
      );
    } else {
      setLog(data, action);

      sendNotification('Operação realizada com sucesso!', 'success');
      loadUsers();
    }
  };

  return (
    <Row gutter={16}>
      <Col span={18} className="gutter-row">
        <div>
          <h3>Gestão de usuários da operação</h3>

          <Divider />

          <div data-testid="user-mgmt">
            <HandleUserModal
              id={id}
              isModalVisible={isModalVisible}
              resetModal={resetModal}
              handleUser={handleUser}
            />
            {isLoading ? (
              <Loading />
            ) : (
              <UsersTable
                loading={isLoading}
                data={users}
                showModal={showModal}
                id={id}
              />
            )}
          </div>
        </div>
      </Col>
      {/* <Col span={6} className="gutter-row">
        <StatusDetails status={status} alertMessages={alerts} />
      </Col> */}
    </Row>
  );
};

UserMgmt.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default UserMgmt;
