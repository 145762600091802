import styled from 'styled-components';

export const Title = styled.h4`
  font-size: 14px;
  color: #707070;
  line-height: 21px;
  margin-left: 65px;
  margin-bottom: 13px;
  margin-top: 35px;
`;

export const UploadBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-width: 80%;
  border: 1px dashed var(--border-color);
  margin: 16px 0;
  padding: 10px;
  position: relative;
  .uploadArea {
    width: 100%;
    height: 100px;
    background-color: #fff;
    border: none;
    p {
      margin-bottom: 32px !important;
    }
  }
  .uploadedText {
    position: absolute;
    bottom: 25px;
    button {
      border: 1px solid #27acff;
      color: #27acff;
      border-radius: 4px;
      height: 36px;
      font-weight: 600;
    }
  }
`;
