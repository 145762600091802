import React, { useState } from 'react';
import { Modal, Spin, Table, Typography, Divider, Button } from 'antd';

import { useCatalog } from '../../../context/catalog';
import FeedParsedProduct from './FeedParsedProduct';
import { ProductsContainer } from './styles/feedPreviewStyles';
import PreviewProductDetails from './PreviewProductDetails';

const FeedPreviewModal = () => {
  const {
    isPreviewModalVisible,
    setIsPreviewModalVisible,
    isLoading,
    feedPreview,
  } = useCatalog();

  const [detailsModal, setDetailsModal] = useState({
    visible: false,
    product: {},
  });

  const { Title, Text } = Typography;

  const handleCancel = () => {
    setIsPreviewModalVisible(false);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const getTableBasicData = () => {
    const data = [];

    Object.entries(feedPreview).forEach((entry, index) => {
      const [entryName, entryValue] = entry;

      switch (entryName) {
        case 'XML_size':
          data.push({
            key: index,
            name: 'Tamanho do arquivo XML',
            value: entryValue,
          });
          break;
        case 'products_parsed':
          data.push({
            key: index,
            name: 'Quantidade de produtos encontrados',
            value: entryValue,
          });
          break;
        case 'timestamp':
          data.push({
            key: index,
            name: 'Tempo de processamento',
            value: entryValue,
          });
          break;
        case 'date_time':
          data.push({
            key: index,
            name: 'Data do processamento',
            value: entryValue,
          });
          break;
        default:
      }
    });

    return data;
  };

  const getProductsErrors = () => {
    if (Object.entries(feedPreview).length > 0) {
      const { count, log } = feedPreview.errors_format_product;
      if (count > 0) {
        const errorsTableData = [];
        const errorsTablecolumns = [
          {
            title: 'ID do produto',
            dataIndex: 'product_id',
            key: 'product_id',
          },
          {
            title: 'Erro',
            dataIndex: 'error',
            key: 'error',
          },
        ];

        log.forEach(({ product_id, error }, index) => {
          errorsTableData.push({
            key: index,
            product_id,
            error,
          });
        });

        return (
          <Table dataSource={errorsTableData} columns={errorsTablecolumns} />
        );
      }
    }

    return <Text>Todos os produtos foram parseados corretamente</Text>;
  };

  const hasProducts = () => {
    if (Object.entries(feedPreview).length > 0) return true;

    return false;
  };

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      visible={isPreviewModalVisible}
      onCancel={handleCancel}
      width="100%"
      footer={[<Button onClick={handleCancel}>Fechar</Button>]}
    >
      {isLoading ? (
        <Spin tip="Baixando preview..." />
      ) : (
        <>
          <Title level={3}>Preview da integração</Title>
          <Table dataSource={getTableBasicData()} columns={columns} />
          <Divider />
          <Title level={3}>Produtos com erro</Title>
          {getProductsErrors()}
          <Divider />
          <Title level={3}>Exemplo de produtos a serem integrados</Title>
          <ProductsContainer>
            {hasProducts()
              ? feedPreview.preview_product.log.map(product => (
                  <FeedParsedProduct
                    product={product}
                    setDetailsModal={setDetailsModal}
                  />
                ))
              : null}
          </ProductsContainer>
          <PreviewProductDetails
            setDetailsModal={setDetailsModal}
            detailsModal={detailsModal}
          />
        </>
      )}
    </Modal>
  );
};

export default FeedPreviewModal;
