export const hookDefaultCode = `// Exemplo de um hook
const { data, node } = item;
// const { price_from } = data;

// ignora price_to, feed informa preço promo com desconto do boleto.
// data.price_to = price_from;
// data.price_from = undefined;
// data.price_discount_off = undefined;
// data.price_discount_value = undefined;

// const desconto = 0.15;
// const avista = data.price_to * (1 - desconto);
// data.additional_prices = data.additional_prices || {};
// data.additional_prices.avista = parseFloat(avista).toFixed(2);

return item;`;

const URLHelper =
  'Uma biblioteca interna do Node.js que permite trabalhar com URLs.';
const axiosHelper =
  'Uma biblioteca padrão do axios que permite enviar requisições HTTP.';

const beginnersHelper =
  'Um array de strings que contém os identificadores dos iniciantes do cliente "03ef72a5-fcae-4313-a2c4-770dd425cfe7".';

const treeIdHelper =
  'Uma função que ajuda a gerar um ID de árvore para uma determinada árvore. A entrada dessa função é um número e ela retorna uma string.';

const mapVehicleOrModelsHelper =
  'A função mapVehicleOrModels filtra uma string de entrada para encontrar modelos ou veículos, usando regex específicos fornecidos como uma string de entrada. A função recebe como entrada uma string contendo o nome do modelo ou veículo a ser filtrado e uma string especificando o modelo de regex a ser usado, que pode ser manufacturersMapRegexp, modelsMapRegexp ou modelFIATMapRegexp. O resultado é um array de strings correspondentes aos modelos ou veículos encontrados na string de entrada, sem itens duplicados.';

const utilsHelper = 'Um objeto que contém duas funções listadas abaixo.';

const utilsParseInstallmentsHelper =
  'Uma função que calcula as parcelas com base no preço, no número máximo e mínimo de parcelas permitidas. A entrada dessa função é o preço, o número máximo e mínimo de parcelas permitidas e ela retorna um array de números.';

const utilsParsePriceHelper =
  'Uma função que analisa o preço e o converte em um formato apropriado. A entrada dessa função é uma string contendo o preço e ela retorna um number.';

export const hookFunctionsHelpers = {
  URLHelper,
  axiosHelper,
  beginnersHelper,
  treeIdHelper,
  mapVehicleOrModelsHelper,
  utilsHelper,
  utilsParseInstallmentsHelper,
  utilsParsePriceHelper,
};

export const hookExempleCode =
  // eslint-disable-next-line no-template-curly-in-string
  "const { data, node } = item;\nconst { treeIdHelper } = hookHelpers;\n\nconst trees = [];\n\ntrees.push({\n    kind: meta.key,\n    tree_id: treeIdHelper(id),\n    name: id,\n    tree_key: `${meta.key}:${treeIdHelper(id)}`,\n    source: 'integration',\n    level: 0,\n    priority: 0,\n})";
