import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SwapOutlined, UserOutlined } from '@ant-design/icons';
import { FaBuilding, FaSignOutAlt } from 'react-icons/fa';
import { FiSliders, FiMonitor } from 'react-icons/fi';
import { Col, Layout, Menu, Row, Tag } from 'antd';
import { useAuth } from '../../context/user.tsx';

const { SubMenu } = Menu;
const { Header, Sider, Footer } = Layout;

const AppLayout = props => {
  const operationName = JSON.parse(
    localStorage.getItem('@Beon-adm-panel:operation-name'),
  );
  const [currentOperation, setCurrentOperation] = useState(operationName || '');
  const { id } = useParams();
  const history = useHistory();
  const { logout, authenticatedUser } = useAuth();

  const handleUserMenu = evt => {
    switch (evt.key) {
      case 'logout':
        handleLogout();
        break;
      case 'swap':
        history.push('/operation-select');
        break;
      // future menu options
      default:
        break;
    }
  };

  const handleLogout = () => {
    logout();
    history.replace('/');
  };

  return (
    <Layout className="site-layout-background">
      <Header
        className="header"
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          backgroundColor: 'var(--beon-color)',
          height: '65px',
        }}
      >
        <Row gutter={0}>
          <Col span={4}>
            <Link to={`/status/${id}`}>
              <div className="logo" />
            </Link>
          </Col>
          <Col span={14}>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={['1']}
              style={{
                height: '90%',
                borderRight: 0,
                backgroundColor: 'var(--beon-color)',
                borderBottom: 0,
              }}
            >
              {/* <Link to={`/status/${id}`}> */}
              <Menu.Item onClick={() => history.push(`/status/${id}`)} key="1">
                Configuração da operação
              </Menu.Item>
              {/* </Link> */}
              {/* <Menu.Item key="1">Ferramentas de suporte</Menu.Item> */}
            </Menu>
          </Col>
          <Col span={6}>
            <Menu
              mode="horizontal"
              onClick={handleUserMenu}
              style={{ backgroundColor: 'var(--beon-color)', color: '#fff' }}
            >
              <SubMenu
                key="SubMenu"
                title={authenticatedUser()}
                icon={<UserOutlined />}
              >
                <Menu.Item key="swap">
                  <SwapOutlined style={{ marginRight: 4 }} />
                  Trocar operação
                </Menu.Item>
                <Menu.Item key="logout">
                  <FaSignOutAlt style={{ marginRight: 4 }} />
                  Logout
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
        </Row>
      </Header>
      <Layout
        className="site-layout-background"
        style={{
          marginTop: 65,
          minHeight: 'calc(100vh - 135px)',
        }}
      >
        <Sider width={250} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['operation', 'integration', 'configuration']}
            style={{ minHeight: '100%', borderRight: 0 }}
          >
            <SubMenu
              key="operation"
              icon={<FaBuilding />}
              title={currentOperation}
              style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'blue' }}
            >
              {/* <Menu.Item key="1" disabled>
                <Tag color="warning">Em ativação</Tag>
              </Menu.Item> */}
              <Menu.Item key="2">
                <Link to={`/status/${id}/support-tools`} />
                Suporte
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="integration"
              icon={<FiMonitor />}
              title="INTEGRAÇÕES FRONTEND"
            >
              <SubMenu key="product-catalog" title="Catálogos de produtos">
                <Menu.Item key="3">
                  <Link to={`/status/${id}/catalogo-de-produtos/vtex`} />
                  VTEX
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to={`/status/${id}/catalogo-de-produtos/feed`} />
                  Feed XML
                </Menu.Item>
                <Menu.Item key="catalog-nvmshop">
                  <Link to={`/status/${id}/catalogo-de-produtos/nuvemshop`} />
                  Nuvemshop
                </Menu.Item>
              </SubMenu>
              {/* <Menu.Item key="4">Google Analytics</Menu.Item> */}
              <Menu.Item key="5">
                <Link to={`/status/${id}/tag-gtm`} />
                Tag GTM
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="configuration"
              icon={<FiSliders />}
              title="CONFIGURAÇÕES"
            >
              <Menu.Item key="6">
                <Link to={`/status/${id}/user-management`} />
                Gestão de usuários
              </Menu.Item>
              <Menu.Item key="7">
                <Link to={`/status/${id}/cadastro-da-operacao`} />
                Cadastro
              </Menu.Item>

              {/* <Menu.Item key="8">Customização</Menu.Item>
              <Menu.Item key="9">Gatilhos</Menu.Item>
              <Menu.Item key="10">Rankings</Menu.Item> */}
              <Menu.Item key="11">
                <Link to={`/status/${id}/templates`} />
                Templates
              </Menu.Item>
              <Menu.Item key="12">
                <Link to={`/status/${id}/logs`} />
                Auditoria
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout
          style={{
            margin: '0 auto',
            padding: '1rem',
            paddingLeft: '2rem',
          }}
        >
          {props.children}
        </Layout>
      </Layout>
      <Footer style={{ textAlign: 'center', marginTop: 'auto' }}>
        Beon ©2018
      </Footer>
    </Layout>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
};

export default AppLayout;
