import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import slugify from './slugify';

const ActionButton = ({ id, title, status, alerts }) => {
  const buttonName = 'Visualizar'; // Por default é Warning status

  // Future implementation with Healthchecks
  // buttonName =
  //   status === 'error'
  //     ? 'Corrigir'
  //     : status === 'success'
  //     ? 'Visualizar'
  //     : buttonName;

  const buttonClassName = 'ant-btn ant-btn-primary';

  // Future implementation with Healthchecks
  // buttonClassName =
  //   status === 'error'
  //     ? 'ant-btn ant-btn-dangerous'
  //     : status === 'success'
  //     ? 'ant-btn'
  //     : buttonClassName;

  const routeBasePath = '/status';

  const slug = slugify(title);
  const routePath = `${routeBasePath}/${id}/${slug}`;

  return (
    <Link
      data-testid="action-button"
      className={`${buttonClassName} ant-btn-block`}
      to={{ pathname: `${routePath}` }}
      // Future implementation with Healthchecks
      // to={{ pathname: `${routePath}`, state: { status, alerts } }}
    >
      {buttonName}
    </Link>
  );
};

ActionButton.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  alerts: PropTypes.arrayOf(PropTypes.object),
};

export default ActionButton;
