import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Button, Typography } from 'antd';

import { UnitaryIntegrationModalContainer } from './styles';
import {
  runMultiplatformUnitaryIntegration,
  generateLog,
} from '../../../domain/catalog';
import sendNotification from '../../../components/Notification/index.tsx';

const UnitaryIntegrationModal = ({
  showModal,
  setModalVisible,
  tenantId,
  namespace,
  setIsLoading,
}) => {
  const { Title } = Typography;
  const [sku, setSku] = useState('');

  const handleInputChange = ({ target: { value } }) => {
    setSku(value);
  };

  const onIntegrateClick = async () => {
    if (sku === '') {
      sendNotification('Por favor insira um sku válido', 'error');
      return;
    }

    setIsLoading(true);

    const { data } = await runMultiplatformUnitaryIntegration({
      tenantId,
      sku,
      platform: namespace,
    });

    if (data?.code === 200) {
      sendNotification('Integração realizada com sucesso!', 'success');

      const user_email = localStorage.getItem('@Beon-adm-panel:user');
      const user_id = JSON.parse(
        localStorage.getItem('@Beon-adm-panel:userid'),
      );

      const log = {
        app_location: 'Painel ADM',
        tenant_id: tenantId,
        time_stamp: new Date().toJSON(),
        user_email,
        user_id,
        resource: namespace,
        action_type: 'Integração unitária SKU',
      };

      await generateLog(log);

      setModalVisible(false);
    } else {
      sendNotification(
        'Algo deu errado na integração! Por favor verifique o SKU e tente novamente',
        'error',
      );
    }

    setIsLoading(false);
  };

  return (
    <Modal
      visible={showModal}
      onCancel={() => setModalVisible(false)}
      footer={[
        <Button onClick={() => setModalVisible(false)} type="primary">
          OK
        </Button>,
      ]}
    >
      <Title style={{ textAlign: 'center' }} level={3}>
        Integração unitária por SKU
      </Title>
      <UnitaryIntegrationModalContainer>
        <Input
          onChange={handleInputChange}
          placeholder="Digite o SKU"
          style={{ width: '70%' }}
        />
        <Button
          onClick={onIntegrateClick}
          type="primary"
          style={{ width: '25%' }}
        >
          Integrar
        </Button>
      </UnitaryIntegrationModalContainer>
    </Modal>
  );
};

UnitaryIntegrationModal.propTypes = {
  showModal: PropTypes.bool,
  setModalVisible: PropTypes.func,
  tenantId: PropTypes.string,
  namespace: PropTypes.string,
  setIsLoading: PropTypes.func,
};

export default UnitaryIntegrationModal;
