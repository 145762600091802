import React, { useEffect, useState } from 'react';
import { Collapse, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { getFeedTenantLogs } from '../../../domain/catalog';
import LogCollapse from './LogCollapse';
import LogModal from './LogModal';

const FeedLogs = () => {
  const { id } = useParams();
  const { Text } = Typography;
  const { Panel } = Collapse;
  const [logs, setLogs] = useState([]);
  const [logModal, setLogModal] = useState({
    visible: false,
    logId: '',
    detailsBody: '',
  });

  useEffect(() => {
    const getLogs = async () => {
      const { data } = await getFeedTenantLogs(id);

      if (data) {
        const sortedData = data.sort((a, b) => {
          return new Date(b.log.date_time) - new Date(a.log.date_time);
        });

        setLogs(sortedData);
      }
    };

    getLogs();
  }, [id]);

  return (
    <>
      {logs.length === 0 && <Text>Nenhum log encontrado!</Text>}
      {logs.map(log => (
        <LogCollapse key={log.fieldId} log={log} setLogModal={setLogModal} />
      ))}
      {logModal.visible && (
        <LogModal logModal={logModal} setLogModal={setLogModal} />
      )}
    </>
  );
};

export default FeedLogs;
