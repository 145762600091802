import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Table, Button } from 'antd';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { CustomCard } from './styles';
import ProductModal from './ProductModal';

const { Panel } = Collapse;
const dateFormat = 'dd/MMM/yyyy HH:mm:ss';

const formatToBRL = n => {
  return n.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

const ProductCard = ({ product }) => {
  const [showProductModal, setShowProductModal] = useState(false);

  const {
    images,
    is_active,
    is_available,
    created_at,
    updated_at,
    sku,
    name,
    product_id,
    additional_prices,
    price_from,
    price_to,
    url,
    installment_months,
    installment_amount,
    trees,
  } = product;

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Kind',
      dataIndex: 'kind',
      key: 'kind',
    },
    {
      title: 'Tree Key',
      dataIndex: 'tree_key',
      key: 'tree_key',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
    },
  ];

  return (
    <CustomCard cover={<img alt={name} src={images[0] || ''} />}>
      <p>Nome: {name}</p>
      <p>Id: {product_id}</p>
      <p>SKU: {sku}</p>
      <p>Ativo: {is_active ? 'Sim' : 'Não'}</p>
      <p>Disponível: {is_available ? 'Sim' : 'Não'}</p>
      <p>
        Criado em: {format(new Date(created_at), dateFormat, { locale: ptBR })}
      </p>
      <p>
        Atualizado em:{' '}
        {format(new Date(updated_at), dateFormat, { locale: ptBR })}
      </p>
      <p>
        URL:{' '}
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      </p>
      <p>
        Valor à vista:{' '}
        {additional_prices && additional_prices.avista
          ? formatToBRL(additional_prices.avista)
          : 'Não calculado internamente'}
      </p>
      <p>Preço DE: {price_from ? formatToBRL(price_from) : 'Não informado'}</p>
      <p>
        Preço POR:{' '}
        {price_to
          ? formatToBRL(price_to)
          : 'Não informado/calculado internamente'}
      </p>
      <p>
        Quantidade de parcelas:{' '}
        {installment_months || 'Não informado/calculado internamente'}
      </p>
      <p>
        Valor da parcela:{' '}
        {installment_amount
          ? formatToBRL(installment_amount)
          : 'Não informado/calculado internamente'}
      </p>

      {trees.length > 0 && (
        <Collapse>
          <Panel header="Árvore de categorias" key="trees">
            <Table dataSource={trees} columns={columns} pagination={false} />
          </Panel>
        </Collapse>
      )}
      <Button type="primary" onClick={() => setShowProductModal(true)}>
        visualizar todas as propriedades
      </Button>
      <ProductModal
        visible={showProductModal}
        setShowProductModal={setShowProductModal}
        product={product}
      />
    </CustomCard>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    additional_prices: PropTypes.shape({ avista: PropTypes.number }),
    created_at: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    is_active: PropTypes.bool,
    is_available: PropTypes.bool,
    installment_amount: PropTypes.number,
    installment_months: PropTypes.number,
    name: PropTypes.string,
    price_from: PropTypes.number,
    price_to: PropTypes.number,
    product_id: PropTypes.string,
    sku: PropTypes.string,
    trees: PropTypes.arrayOf(
      PropTypes.shape({
        kind: PropTypes.string,
        name: PropTypes.string,
        source: PropTypes.string,
        tree_key: PropTypes.string,
      }),
    ),
    updated_at: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default ProductCard;
