const getFieldLabel = fieldName => {
  switch (fieldName) {
    case 'base_url':
      return 'Endereço principal';
    case 'cnpj':
      return 'CNPJ';
    case 'email':
      return 'Contato principal';
    case 'name':
      return 'Nome da operação';
    case 'platform':
      return 'Plataforma';
    case 'segment':
      return 'Segmento';
    case 'ua_property':
      return 'Propriedade GA';
    case 'origins':
      return 'Origens';
    case 'beon_studio':
      return 'Beon Studio';
    case 'beon_search':
      return 'Beon Search';
    default:
      return '';
  }
};

const getFieldsWithDifference = (newValues, tenant) => {
  const fieldsWithDifference = {};
  const { profile } = tenant;

  Object.entries(newValues).forEach(entry => {
    const [entryName, entryValue] = entry;

    if (
      entryValue !== profile[entryName] &&
      entryName !== 'beon_studio' &&
      entryName !== 'beon_search' &&
      entryName !== 'origins'
    ) {
      const label = getFieldLabel(entryName);

      fieldsWithDifference[label] = entryValue;
    }
  });

  for (let index = 0; index < newValues.origins.length; index += 1) {
    if (newValues.origins[index] !== tenant.origins[index]) {
      const label = getFieldLabel('origins');

      fieldsWithDifference[label] = newValues.origins;
      break;
    }
  }

  const beonStudio = profile.products.find(
    product => product === 'beon_studio',
  );
  const studioLabel = getFieldLabel('beon_studio');

  const beonSearch = profile.products.find(
    product => product === 'beon_search',
  );
  const searchLabel = getFieldLabel('beon_search');

  if (newValues.beon_studio && !beonStudio) {
    fieldsWithDifference[studioLabel] = newValues.beon_studio;
  } else if (!newValues.beon_studio && beonStudio) {
    fieldsWithDifference[studioLabel] = newValues.beon_studio;
  }

  if (newValues.beon_search && !beonSearch) {
    fieldsWithDifference[searchLabel] = newValues.beon_search;
  } else if (!newValues.beon_search && beonSearch) {
    fieldsWithDifference[searchLabel] = newValues.beon_search;
  }

  if (Object.entries(fieldsWithDifference).length === 0) {
    return null;
  }

  if (Object.entries(fieldsWithDifference).length > 5) {
    return false;
  }

  return fieldsWithDifference;
};

export default getFieldsWithDifference;
