import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Divider, Row, Col } from 'antd';

import getEndpoint from '../../utils/getEndpoint';

import RankingsBox from './RankingsBox';
import StatusDetails from '../../components/StatusDetails';

import { getDataFromEndpoints } from '../../domain/Rankings';

const Rankings = props => {
  const { status, alerts } = props.location.state;
  const { id } = useParams();

  const [rankings, setRankings] = useState([]);
  const [config, setConfig] = useState([]);

  const endpoint = getEndpoint('apiRankings');

  useEffect(() => {
    const getRankings = async () => {
      const result = await getDataFromEndpoints(id);

      setConfig(result.tenantConfig.data);
      setRankings(result.rankingsDescribe.data);
    };

    getRankings();
  }, [endpoint, id]);

  return (
    <Row gutter={16}>
      <Col span={18} className="gutter-row">
        <h2>Rankings</h2>
        <Divider />
        {rankings.map(ranking => (
          <RankingsBox
            key={ranking._id}
            ranking={ranking}
            config={config}
            endpoint={endpoint}
            tenant_id={id}
          />
        ))}
      </Col>
      <Col span={6} className="gutter-row">
        <StatusDetails status={status} alertMessages={alerts} />
      </Col>
    </Row>
  );
};

Rankings.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default Rankings;
