import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Switch,
  Divider,
  Space,
} from 'antd';

import FormFieldDocument from '../../components/FormFieldDocument';
import platforms from '../../constants/platforms';

const { Option } = Select;

const NewOperationForm = ({ visible, handleRegister, handleCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const registerNewOperation = () => {
    form
      .validateFields()
      .then(validatedValues => {
        setIsLoading(true);
        console.log('valores do Form', validatedValues);
        return handleRegister(validatedValues);
      })
      .catch(info => {
        console.log(info);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      title="Cadastrar uma nova operação"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={registerNewOperation}
        >
          Cadastrar
        </Button>,
      ]}
    >
      <Form form={form} name="createOperation">
        <Form.Item
          required
          label="Nome: "
          name="name"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
              type: 'string',
              min: 3,
            },
          ]}
          hasFeedback
        >
          <Input placeholder="Insira o nome da operação" />
        </Form.Item>
        <FormFieldDocument
          required
          label="CNPJ"
          name="cnpj"
          hasFeedback
          type="CNPJ"
        />
        <Form.Item
          required
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
              type: 'email',
            },
          ]}
        >
          <Input placeholder="Favor inserir o e-mail do responsável principal da operação" />
        </Form.Item>
        <Form.Item
          required
          label="Propriedade GA: "
          name="ua_property"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
              type: 'string',
              min: 8,
            },
          ]}
          hasFeedback
        >
          <Input placeholder="Insira o ID (UA) da propriedade do Google Analytics" />
        </Form.Item>
        <Form.Item
          required
          label="URL Base"
          name="base_url"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },

            () => ({
              validator(_, value) {
                if (!value || !/\/$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Favor remover o caractere / no final da URL'),
                );
              },
            }),
          ]}
        >
          <Input placeholder="Insira a URL de base" />
        </Form.Item>
        <Form.Item
          required
          label="Plataforma E-commerce"
          name="platform"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Select placeholder="Informar a plataforma do cliente">
            {platforms.map(({ name, value }) => (
              <Option key={`platform-${value}`} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />

        <h3>Produtos:</h3>
        <Space>
          <Form.Item label="Beon Studio" name="beon_studio" initialValue>
            <Switch defaultChecked />
          </Form.Item>

          <Form.Item
            label="Beon Search"
            name="beon_search"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
        </Space>

        <h3>Funcionalidades:</h3>
        <Space>
          <Form.Item
            label="Mostrar variações de produto"
            name="flag_product_variations"
            initialValue={false}
          >
            <Switch />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

NewOperationForm.propTypes = {
  visible: PropTypes.bool,
  handleRegister: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default NewOperationForm;
