import Routes from './routes';
import { AuthProvider } from './context/user.tsx';
import { CatalogProvider } from './context/catalog';
import GlobalStyle from './styles/GlobalStyle';

import './App.css';

function App() {
  return (
    <AuthProvider>
      <CatalogProvider>
        <Routes />
        <GlobalStyle />
      </CatalogProvider>
    </AuthProvider>
  );
}

export default App;
