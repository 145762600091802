import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import FormFieldDocument from '../../components/FormFieldDocument';
import sendNotification from '../../components/Notification/index.tsx';
import Loading from '../../components/Loading';
import { getTenantData, updateTenant } from '../../domain/Tenant';
import { generateLog } from '../../domain/catalog';
import platforms from '../../constants/platforms';
import getFieldsWithDifference from './getFieldsWithDifference';
import ButtonsContainer from './registrationStyles';

const { Option } = Select;

const Registration = () => {
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [tenant, setTenant] = useState({});
  const [products, setProducts] = useState([]);
  const [flags, setFlags] = useState({});

  useEffect(() => {
    const getTenantInfo = async () => {
      const result = await getTenantData(id);

      if (result) {
        setTenant(result.data);

        setProducts(result.data.profile.products || []);
        setFlags(result.data.profile.flags || {});

        setIsLoading(false);
      } else {
        sendNotification(
          'Ocorreu um problema no carregamento dos dados.',
          'error',
        );
      }
    };

    getTenantInfo();
  }, [id]);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
      xl: {
        span: 24,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
      xl: {
        span: 16,
      },
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 14,
      span: 24,
    },
  };

  const onFinish = async values => {
    try {
      const logBody = getFieldsWithDifference(values, tenant);

      const result = await updateTenant(values, id);
      if (result) {
        sendNotification('Informações atualizadas com sucesso.', 'success');

        const user_email = localStorage.getItem('@Beon-adm-panel:user');
        const user_id = JSON.parse(
          localStorage.getItem('@Beon-adm-panel:userid'),
        );

        const log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: 'Cadastro de operação',
          action_type: 'edição',
        };

        if (logBody !== null && logBody !== false) {
          log.body = logBody;
        }

        const logResult = await generateLog(log);

        if (logResult.status !== 201) {
          sendNotification('Falha ao gerar log', 'error');
        }

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        sendNotification(
          'Ocorreu um problema no envio das informações.',
          'error',
        );
      }
    } catch (err) {
      sendNotification(
        'Ocorreu um problema no envio das informações.',
        'error',
      );
      console.log(err);
    }
  };

  const onFinishFailed = () => {
    sendNotification(
      'Informações preenchidas incorretamente. Por favor cheque novamente e faça as devidas correções.',
      'error',
    );
  };

  const returnToDashboad = () => {
    history.push(`/status/${id}`);
  };

  return (
    <Row gutter={16}>
      <Col span={18} className="gutter-row">
        <h2>Cadastro da operação</h2>
        {isLoading ? (
          <Loading />
        ) : (
          tenant && (
            <Form
              {...formItemLayout}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <h3>
                01 <strong>PRODUTOS</strong>
              </h3>

              <Divider />

              <Space>
                <Form.Item
                  label="Beon Studio"
                  name="beon_studio"
                  initialValue={products.includes('beon_studio')}
                >
                  <Switch defaultChecked={products.includes('beon_studio')} />
                </Form.Item>

                <Form.Item
                  label="Beon Search"
                  name="beon_search"
                  initialValue={products.includes('beon_search')}
                >
                  <Switch defaultChecked={products.includes('beon_search')} />
                </Form.Item>
              </Space>

              <h3>
                02 <strong>FUNCIONALIDADES</strong>
              </h3>
              <Divider />

              <Form.Item
                label="Mostrar variações de produto"
                name="flag_product_variations"
                initialValue={flags.product_variations}
              >
                <Switch defaultChecked={flags.product_variations} />
              </Form.Item>

              <h3 className="field__label">03 PERFIL</h3>
              <Divider />
              <Form.Item
                require
                label="Nome da operação"
                name="name"
                initialValue={tenant.profile.name}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'string',
                    min: 3,
                  },
                ]}
                hasFeedback
              >
                <Input
                  placeholder="Favor inserir o nome da operação"
                  suffix={
                    <Tooltip title="Extra help here!">
                      <QuestionCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <FormFieldDocument
                required
                label="CNPJ"
                name="cnpj"
                initialValue={tenant.profile.cnpj}
                hasFeedback
              />
              <Form.Item
                label="Contato principal"
                name="email"
                initialValue={tenant.profile.email}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'email',
                  },
                ]}
              >
                <Input
                  placeholder="Favor inserir o e-mail do contato principal da operação"
                  suffix={
                    <Tooltip title="Extra help here!">
                      <QuestionCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <Form.Item
                label="Segmento"
                name="segment"
                initialValue={tenant.profile.segment}
              >
                <Input
                  placeholder="Favor inserir o segmento comercial do cliente"
                  suffix={
                    <Tooltip title="Extra help here!">
                      <QuestionCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <Form.Item
                required
                label="Propriedade GA: "
                name="ua_property"
                initialValue={tenant.profile.ua_property}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'string',
                    min: 10,
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Insira o ID da propriedade do Google Analytics" />
              </Form.Item>

              <h3>
                04 <strong>PLATAFORMA</strong>
              </h3>
              <Divider />
              <Form.Item
                required
                label="Plataforma"
                name="platform"
                initialValue={tenant.profile.platform}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'string',
                    min: 3,
                  },
                ]}
              >
                <Select placeholder="Favor inserir o nome da plataforma">
                  {platforms.map(({ name, value }) => (
                    <Option value={value}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <h3>
                05 <strong>DOMÍNIO</strong>
              </h3>
              <Divider />
              <Form.Item
                required
                label="Endereço principal"
                name="base_url"
                initialValue={tenant.profile.base_url}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'url',
                  },

                  () => ({
                    validator(_, value) {
                      if (!value || !/\/$/.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'Favor remover o caractere / ao final da URL',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="www.meusite.com.br"
                  style={{ width: '100%' }}
                  suffix={
                    <Tooltip title="Extra help here!">
                      <QuestionCircleOutlined
                        style={{ color: 'rgba(0,0,0,.45)' }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>

              <Form.Item
                label="Subdomínios autorizados"
                name="origins"
                initialValue={tenant.origins}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value || !/\/$/.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'Favor remover o caractere / no final da URL',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Insira um subdomínio autorizado"
                />
              </Form.Item>
              <ButtonsContainer>
                <Form.Item {...tailLayout}>
                  <Button
                    size="large"
                    htmlType="default"
                    onClick={returnToDashboad}
                  >
                    Cancelar
                  </Button>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button size="large" htmlType="submit">
                    Salvar
                  </Button>
                </Form.Item>
              </ButtonsContainer>
            </Form>
          )
        )}
      </Col>
    </Row>
  );
};

Registration.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default Registration;
