import { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Switch } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { transform } from 'lodash';

const { Option } = Select;

const HandleUserModal = ({ isModalVisible, resetModal, id, handleUser }) => {
  const [userRole, setUserRole] = useState('');
  const [userActive, setUserActive] = useState(false);
  const { visible, action, data } = isModalVisible;

  const [form] = Form.useForm();
  console.log('Form', action, data);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const { active, type } = data.acl[`org:${id}`];
      console.log('From ACL', active, type);
      setUserRole(type);
      setUserActive(active);
    }
  }, [data, id]);

  return (
    <Modal
      title={action === 'add' ? 'Adicionar usuário' : 'Editar usuário'}
      okText="Confirmar"
      cancelText="Cancelar"
      visible={visible}
      onCancel={resetModal}
      onOk={() => {
        form
          .validateFields()
          .then(validatedValues => {
            const userData = {
              ...validatedValues,
              id,
            };
            if (action === 'add') {
              handleUser('add', userData);
              resetModal();
            } else {
              handleUser('edit', userData);
              console.log('usuario atualizado', userData);
              resetModal();
            }
          })
          .catch(info => {
            console.log(info);
          });
      }}
    >
      {action && action === 'add' ? (
        <>
          {form.resetFields()}
          <Form form={form} name="addUser">
            <Form.Item
              required
              tooltip="No mínimo três caracteres"
              label="Nome: "
              name="fullname"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                  type: 'string',
                  min: 3,
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Insira o nome do usuário" />
            </Form.Item>
            <Form.Item
              required
              normalize={value => value.toLowerCase()}
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Insira o e-mail do usuário" />
            </Form.Item>

            <Form.Item
              label="Função"
              name="role"
              required
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            >
              <Select placeholder="Permissão do usuário na operação">
                <Option value="user">Apenas leitura</Option>
                <Option value="editor">Editor</Option>
                <Option value="owner">Administrador</Option>
              </Select>
            </Form.Item>
            <Form.Item
              required
              tooltip="No mínimo 8 caracteres (letras e números)"
              label="Senha: "
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                  min: 3,
                },
              ]}
            >
              {/* <Input.Group compact size="small"> */}
              <Input.Password
                iconRender={visibleInput =>
                  visibleInput ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="Favor inserir uma senha"
              />

              {/* </Input.Group> */}
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirmar senha: "
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Favor confirmar a senha!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('As senhas informadas não são idênticas!'),
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                iconRender={visibleInput =>
                  visibleInput ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="Confirmar a senha"
              />
            </Form.Item>
            <Form.Item
              label="Status"
              name="active"
              valuePropName="checked"
              initialValue
              // rules={[
              //   {
              //     required: true,
              //     message: 'Campo obrigatório',
              //   },
              // ]}
            >
              <Switch checkedChildren="ativo" className="ant-modified" />
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          {form.resetFields()}
          {form.setFieldsValue({
            fullname: data.fullname,
            email: data.email,
            role: userRole,
            active: userActive,
          })}
          {data && (
            <Form form={form} name="editUser">
              <Form.Item
                required
                tooltip="No mínimo três caracteres"
                label="Nome: "
                name="fullname"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                    type: 'string',
                    min: 3,
                  },
                ]}
              >
                <Input placeholder="Insira o nome do usuário" />
              </Form.Item>
              <Form.Item label="E-mail: " name="email">
                <Input
                  disabled
                  placeholder="Favor inserir o e-mail do usuário"
                />
              </Form.Item>

              <Form.Item
                label="Função: "
                name="role"
                required
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Select placeholder="Permissão do usuário na operação">
                  <Option value="user">Apenas leitura</Option>
                  <Option value="editor">Editor</Option>
                  <Option value="owner">Administrador</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Nova senha: "
                name="password"
                hasFeedback
                tooltip="No mínimo 8 caracteres (letras e números)"
                rules={[
                  {
                    required: false,
                    message: 'Necessário informar uma senha.',
                    min: 8,
                  },
                ]}
              >
                {/* <Input.Group compact size="small"> */}
                <Input.Password
                  iconRender={visibleInput =>
                    visibleInput ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Favor inserir uma senha"
                />

                {/* </Input.Group> */}
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirmar senha: "
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: false,
                    message: 'Favor confirmar a senha!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('As senhas informadas não são idênticas!'),
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  iconRender={visibleInput =>
                    visibleInput ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  placeholder="Confirmar a senha"
                />
              </Form.Item>

              <Form.Item label="Status" valuePropName="checked" name="active">
                <Switch checked={userActive} checkedChildren="ativo" />
              </Form.Item>
            </Form>
          )}
        </>
      )}
    </Modal>
  );
};

export default HandleUserModal;
