import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Pagination } from 'antd';

import { getAllLogs, getPageLogs } from '../../domain/catalog';
import AuditLogModal from './AuditLogModal';
import Log from './Log';
import Loading from '../../components/Loading';

import { LogsContainer } from './styles/auditLogsStyles';

const { Title } = Typography;
const pageSize = 5;

const AuditLogs = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [pagination, setPagination] = useState({
    from: 0,
    to: pageSize,
    total: 0,
  });
  const [auditLogModal, setAuditLogModal] = useState({
    visible: false,
    log: {},
  });

  const getOrdenadLog = logsList => {
    let ordenadLogs = [];

    ordenadLogs = logsList.sort((a, b) => {
      const aDate = new Date(a.time_stamp);
      const bDate = new Date(b.time_stamp);

      return bDate - aDate;
    });

    return ordenadLogs;
  };

  useEffect(() => {
    const getLogs = async () => {
      setIsLoading(true);
      const { data } = await getPageLogs(id, pagination.from, pagination.to);
      const { data: allLogs } = await getAllLogs(id);

      setPagination({ ...pagination, total: allLogs.length });

      let ordenadLogs = [];

      if (data) {
        ordenadLogs = getOrdenadLog(data);
      }

      setLogs(ordenadLogs);
      setIsLoading(false);
    };

    getLogs();
  }, []);

  useEffect(() => {
    const getLogs = async () => {
      const { data } = await getPageLogs(id, pagination.from, pagination.to);
      let ordenadLogs = [];
      if (data) {
        ordenadLogs = getOrdenadLog(data);
      }

      setLogs(ordenadLogs);
    };

    getLogs();
  }, [pagination]);

  const onPageChange = value => {
    let to;
    let from;

    if (value === 1) {
      to = pageSize;
      from = 0;
    } else {
      to = pageSize * value;
      from = to - pageSize;
    }

    setPagination({ ...pagination, from, to });
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <Title level={2} style={{ textAlign: 'center', margin: '1rem auto' }}>
        Logs de auditoria
      </Title>
      {logs.length === 0 ? (
        <span style={{ textAlign: 'center' }}>Nenhum log registrado!</span>
      ) : (
        <LogsContainer>
          {logs.map(log => (
            <Log
              key={log.log_id}
              log={log}
              setAuditLogModal={setAuditLogModal}
            />
          ))}
          <Pagination
            style={{ marginTop: '1rem' }}
            total={pagination.total}
            pageSize={pageSize}
            onChange={onPageChange}
          />
        </LogsContainer>
      )}
      <AuditLogModal
        visible={auditLogModal.visible}
        log={auditLogModal.log}
        setAuditModal={setAuditLogModal}
      />
    </>
  );
};

export default AuditLogs;
