import styled from 'styled-components';

export const WarnningContainer = styled.div`
  align-items: center;
  background-color: #ffe605;
  border: 2px solid orange;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  min-height: 50px;
  padding: 10px;
  width: 100%;
`;
