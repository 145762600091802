import blacklist from './hookBlacklist';

export const hookValidation = value => {
  const valueFormatted = value.replaceAll(/(\r\n|\n|\r)/gm, '');

  for (let index = 0; index < blacklist.length; index += 1) {
    if (valueFormatted.includes(blacklist[index])) {
      return {
        error: true,
        message: `O uso do termo "${blacklist[index]}" não é permitido`,
      };
    }
  }

  return { error: false, code: valueFormatted };
};

export const feedXMLUrlValidation = async (
  feedXmlUrl,
  tenantInfo,
  setNotSameOrigin,
  setFeedSaveButtonDisable,
  hasDifferent,
) => {
  if (feedXmlUrl === '') {
    return Promise.reject('Este campo precisa ser preenchido!');
  }

  let hasOrigin = false;

  const { origins, profile } = tenantInfo;
  const { base_url } = profile;

  if (origins[0]) {
    if (origins[0].includes('www')) {
      const origin = origins[0].split('.');
      const xmlUrl = feedXmlUrl.split('.');

      if (xmlUrl[1] === origin[1]) hasOrigin = true;
    } else {
      const origin = origins[0].split('/');
      const xmlUrl = feedXmlUrl.split('/');

      if (xmlUrl[2] === origin[2]) hasOrigin = true;
    }
  }

  if (hasOrigin === false) {
    const baseUrl = base_url.split('.');
    const xmlUrl = feedXmlUrl.split('.');

    if (xmlUrl[1] === baseUrl[1]) hasOrigin = true;
  }

  if (!hasOrigin) {
    setNotSameOrigin(true);
    setFeedSaveButtonDisable(true);
  } else {
    setNotSameOrigin(false);
  }

  return Promise.resolve('Test');
};

const validations = {
  hookValidation,
  feedXMLUrlValidation,
};

export default validations;
