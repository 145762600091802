import styled from 'styled-components';

import { Form } from 'antd';

export const Title = styled.h4`
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 6px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  border: 2px solid var(--border-color);
  padding: 12px 10px;
  margin-bottom: 0.5rem;
`;

export const ContainerRightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .icon {
    margin-right: 8px;
  }
`;

export const PackageVersionContent = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: bold;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    margin-bottom: 6px;
  }
`;

export const ContainerLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 20px;

  .switch {
    margin-right: 8px;
  }
`;

export const CustomForm = styled(Form)`
  background-color: transparent;
  .ant-row {
    margin-bottom: 0;
  }
`;
