import React from 'react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';
import StatusIcon from '../../../components/StatusIcon';
import ActionButton from '../ActionButton';
import {
  Container,
  MainContent,
  StatusArea,
  ContentArea,
  ButtonArea,
} from './styles';

const ServiceStatus = ({ id, title, description, status, alerts }) => (
  <Container>
    <MainContent>
      {/* <StatusArea>
        <StatusIcon status={status} size="16px" withIcon={false} />
      </StatusArea> */}
      <ContentArea>
        <h4 data-testid="title">
          <span>{title}</span>
        </h4>
        {/* <p>
          <RightOutlined /> {description}
        </p> */}
      </ContentArea>
    </MainContent>
    <ButtonArea>
      <ActionButton id={id} status={status} title={title} alerts={alerts} />
    </ButtonArea>
  </Container>
);

ServiceStatus.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  alerts: PropTypes.arrayOf(PropTypes.object),
};

export default ServiceStatus;
