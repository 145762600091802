import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Collapse,
  Form,
  InputNumber,
  message,
  Typography,
} from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

import api from '../../../../infrascruture/httpRequest';

const { Panel } = Collapse;
const { Paragraph, Title } = Typography;

const NotificationLogs = ({ baseUrl, formatDate }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [logsData, setLogsData] = useState({});

  const getNotificationLogs = async ({ hours }) => {
    try {
      setIsLoading(true);

      const url = `${baseUrl}/${id}/logs/notifications/${hours}`;
      const response = await api.get(url);

      setLogsData(response?.data || {});
    } catch (e) {
      message.error('Houve um erro na requisição. Por favor, tente novamente.');
      console.warn('Get notification logs failed with', e);
    } finally {
      setIsLoading(false);
    }
  };

  const LogsTable = () => {
    const {
      in_progress: inProgress,
      discarded,
      done,
      failed,
      outdated,
      total,
    } = logsData;

    return (
      <>
        <Card>
          <Title level={5}>Informações gerais</Title>
          <Paragraph>
            Notificações no sistema a partir da hora informada: {total}
          </Paragraph>
          <Paragraph>Notificações descartadas: {discarded}</Paragraph>
          <Paragraph>
            Notificações consideradas desatualizadas: {outdated}
          </Paragraph>
          <Paragraph>
            Notificações com falha ou produto não encontrado: {failed}
          </Paragraph>

          <Collapse bordered={false}>
            <Panel header="Concluídas">
              <Paragraph>
                Notificações processadas e concluídas:{' '}
                {done?.count ?? 'Não informado'}
              </Paragraph>

              <Title level={5}>Notificação concluída mais antiga</Title>
              <Paragraph>
                ID: {done?.oldest_notification?.id ?? 'Não informado'}
              </Paragraph>
              <Paragraph>
                Data:{' '}
                {formatDate(done?.oldest_notification?.timestamp) ??
                  'Não informado'}
              </Paragraph>

              <Title level={5}>Notificação concluída mais recente</Title>
              <Paragraph>
                ID: {done?.latest_notification?.id ?? 'Não informado'}
              </Paragraph>
              <Paragraph>
                Data:{' '}
                {formatDate(done?.latest_notification?.timestamp) ??
                  'Não informado'}
              </Paragraph>
            </Panel>

            <Panel header="Em processamento">
              <Paragraph>
                Notificações em processamento:{' '}
                {inProgress?.count ?? 'Não informado'}
              </Paragraph>
              <Paragraph>
                Notificações travadas em processamento há mais de 24 horas:{' '}
                {inProgress?.stuck_in_progress ?? 'Não informado'}
              </Paragraph>

              {inProgress?.count > 0 && (
                <>
                  <Title level={5}>
                    Informações sobre a notificação em processamento mais antiga
                  </Title>
                  <Paragraph>
                    ID: {inProgress?.oldest_notification?.id ?? 'Não informado'}
                  </Paragraph>
                  <Paragraph>
                    Data:{' '}
                    {formatDate(inProgress?.oldest_notification?.timestamp) ??
                      'Não informado'}
                  </Paragraph>

                  <Title level={5}>
                    Informações sobre a notificação em processamento mais
                    recente
                  </Title>
                  <Paragraph>
                    ID: {inProgress?.latest_notification?.id ?? 'Não informado'}
                  </Paragraph>
                  <Paragraph>
                    Data:{' '}
                    {formatDate(inProgress?.latest_notification?.timestamp) ??
                      'Não informado'}
                  </Paragraph>
                </>
              )}
            </Panel>
          </Collapse>
        </Card>
      </>
    );
  };

  return (
    <Collapse>
      <Panel header="Atualizações recentes" key="processing_logs">
        <Paragraph>
          <InfoCircleTwoTone /> Logs das atualizações recentes recebidas pela
          VTEX. É possível buscar pelas atualizações entre as últimas 1 e 48
          horas.
        </Paragraph>

        <Form name="notification_logs" onFinish={getNotificationLogs}>
          <Form.Item
            label="Insira o número de horas a serem buscadas"
            name="hours"
            rules={[
              {
                required: true,
                message: 'Insira um número para fazer a busca!',
              },
              {
                type: 'number',
                min: 1,
                max: 48,
                message: 'O número de horas precisa estar entre 1 e 48.',
              },
            ]}
          >
            <InputNumber min={1} max={48} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Buscar
            </Button>
          </Form.Item>
        </Form>

        {Object.keys(logsData).length > 0 && <LogsTable />}
      </Panel>
    </Collapse>
  );
};

NotificationLogs.propTypes = {
  baseUrl: PropTypes.string,
  formatDate: PropTypes.func,
};

export default NotificationLogs;
