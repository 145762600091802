import React from 'react';
import PropTypes from 'prop-types';
import { Form, Space, Input, Button, Divider } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useCatalog } from '../../../../context/catalog';

const FeedUrlParams = ({ item }) => {
  const { integrationData } = useCatalog();
  const { label, description, helper } = item.keyValues;
  const currentValues = integrationData[item.keyName];

  const renderCurrentValues = () => {
    const formattedValues = currentValues?.map(v => ({
      param: v,
    }));
    return formattedValues;
  };

  return (
    <>
      <Divider />

      <Form.Item
        label={label || description}
        tooltip={helper}
        name={item.keyName}
      >
        <Form.List name={item.keyName} initialValue={renderCurrentValues()}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restFields }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Form.Item
                      {...restFields}
                      label="Parâmetro"
                      name={[name, 'param']}
                      style={{ width: '90%' }}
                      rules={[
                        {
                          required: true,
                          message:
                            'Se não for adicionar o parâmetro, remova essa campo',
                        },
                      ]}
                    >
                      <Input name={`params-${name}-param`} />
                    </Form.Item>

                    <MinusCircleOutlined
                      style={{ fontSize: '20px', color: 'red' }}
                      onClick={() => remove(name)}
                    />
                  </div>
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  style={{ borderRadius: '10px' }}
                >
                  Adicionar regra
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Divider />
    </>
  );
};

FeedUrlParams.propTypes = {
  item: PropTypes.shape({
    keyName: PropTypes.string,
    keyValues: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      helper: PropTypes.string,
    }),
  }),
  onFormValuesChanged: PropTypes.func,
  onTreeAddOrRemove: PropTypes.func,
};

export default FeedUrlParams;
