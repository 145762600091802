import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Switch, Collapse, Typography, Tag } from 'antd';
import { EditorView, basicSetup } from 'codemirror';
import { keymap } from '@codemirror/view';
import { indentWithTab } from '@codemirror/commands';
import { javascript } from '@codemirror/lang-javascript';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import js_beautify from 'js-beautify';
import { useCatalog } from '../../../context/catalog';
import {
  MainEditorContainer,
  ExempleEditorContainer,
} from './styles/hookModalStyles';
import { hookValidation } from '../../../constants/validations';
import sendNotification from '../../../components/Notification/index.tsx';
import {
  hookDefaultCode,
  hookFunctionsHelpers,
  hookExempleCode,
} from '../../../constants/hookHelpers';

const HookModal = ({ hookModal, setHookModal }) => {
  const { id: tenantId } = useParams();
  const hookFuncKey = 'config_hook_func';
  const hookActiveKey = 'config_hook_active';
  const { integrationData, formKeys, updateFeedHook, getFeedIntegrationData } =
    useCatalog();
  const mainViewEditorRef = useRef();
  const [hookCode, setHookCode] = useState('');
  const [hookActive, setHookActive] = useState(
    integrationData[hookActiveKey] || false,
  );

  const { config_hook_active } = formKeys;
  const { label, description, helper } = config_hook_active;

  const { Panel } = Collapse;
  const { Text } = Typography;

  const onEditorChange = EditorView.updateListener.of(updatedView => {
    let code = '';

    if (updatedView.state.doc.text) {
      const textArray = updatedView.state.doc.text;

      if (textArray) {
        for (let index = 0; index < textArray.length; index += 1) {
          code += textArray[index];
        }
      }
    } else if (updatedView.state.doc.children) {
      const codeParts = updatedView.state.doc.children;

      for (let index = 0; index < codeParts.length; index += 1) {
        if (codeParts[index].text) {
          for (
            let textIndex = 0;
            textIndex < codeParts[index].text.length;
            textIndex += 1
          ) {
            code += codeParts[index].text[textIndex];
          }
        }
      }
    }

    setHookCode(code);
  });

  useEffect(() => {
    let defaultCode = hookDefaultCode;
    const beautifyOptions = {
      indent_size: 2,
      space_in_empty_paren: true,
    };

    if (hookFuncKey in integrationData && integrationData[hookFuncKey] !== '') {
      defaultCode = integrationData.config_hook_func;
      const splitedCode = defaultCode.split(';');
      let formattedCode = '';
      let hasComments = false;

      for (let index = 0; index < splitedCode.length; index += 1) {
        if (splitedCode[index].startsWith('//')) {
          splitedCode[index] = `\n${splitedCode[index]};`;
          hasComments = true;
        } else if (
          !splitedCode[index].endsWith(';') &&
          splitedCode[index] !== ''
        ) {
          splitedCode[index] = `${splitedCode[index]};`;
        }

        if (hasComments) {
          splitedCode[index] = `${splitedCode[index]}\n`;
        }

        formattedCode += splitedCode[index];
      }

      defaultCode = js_beautify(formattedCode, beautifyOptions);
    }

    const mainEditorView = new EditorView({
      extensions: [
        basicSetup,
        javascript(),
        onEditorChange,
        keymap.of([indentWithTab]),
      ],
      parent: mainViewEditorRef.current,
      doc: defaultCode,
    });
  }, []);

  const handleOk = () => {
    const validatedCode = hookValidation(hookCode);

    if (validatedCode.error) {
      sendNotification(validatedCode.message, 'error');
    } else if (hookActive && validatedCode.code === '') {
      sendNotification(
        'O hook está ativo, porém não foi escrito, desabilite o hook ou digite um código válido',
        'error',
      );
    } else {
      const integration = getFeedIntegrationData(hookModal.integrationId);
      const values = {
        currProvider: 'integra-feed',
        ...integration,
        config: {
          ...integration.config,
          hook: {
            ...integration.config.hook,
            active: hookActive,
            func: validatedCode.code,
          },
        },
      };

      updateFeedHook(values, tenantId);
      setHookModal({ ...hookModal, visible: false });
    }
  };

  const onSwitchChange = value => {
    setHookActive(value);
  };

  return (
    <Modal
      visible={hookModal.visible}
      title="Escreva abaixo o código para o hook e o ative"
      width="80%"
      onOk={handleOk}
      onCancel={() => setHookModal({ ...hookModal, visible: false })}
      footer={[
        <Button
          key="back"
          onClick={() => setHookModal({ ...hookModal, visible: false })}
        >
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Salvar
        </Button>,
      ]}
    >
      <Form.Item label={label || description} tooltip={helper}>
        <Switch
          onChange={onSwitchChange}
          defaultChecked={integrationData[hookActiveKey] || false}
        />
      </Form.Item>
      <Collapse style={{ marginBottom: '1rem' }}>
        <Panel header="Ajuda?">
          <Text>
            Você poderá usar um objeto chamado <Text code>hookHelpers</Text> que
            contém várias funções úteis que podem ser usadas em scripts
            personalizados. Essas funções incluem:
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              URL
            </Tag>
            {hookFunctionsHelpers.URLHelper}
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              axios
            </Tag>
            {hookFunctionsHelpers.axiosHelper}
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              beginners
            </Tag>
            {hookFunctionsHelpers.beginnersHelper}
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              treeIdHelper
            </Tag>
            {hookFunctionsHelpers.treeIdHelper}
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              mapVehicleOrModels
            </Tag>
            {hookFunctionsHelpers.mapVehicleOrModelsHelper}
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              utils
            </Tag>
            {hookFunctionsHelpers.utilsHelper}
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              utils.parseInstallments
            </Tag>
            {hookFunctionsHelpers.utilsParseInstallmentsHelper}
          </Text>
          <Text style={{ display: 'block', margin: '1rem 0' }}>
            <Tag style={{ fontSize: '16px' }} color="cyan">
              utils.parsePrice
            </Tag>
            {hookFunctionsHelpers.utilsParsePriceHelper}
          </Text>
          <Text>
            Ao usar essas funções em um script personalizado, você pode
            facilmente desestruturar o objeto <Text code>hookHelpers</Text> e
            acessá-las diretamente. Por exemplo:
          </Text>
          <ExempleEditorContainer>
            <SyntaxHighlighter style={a11yDark} language="javascript">
              {hookExempleCode}
            </SyntaxHighlighter>
          </ExempleEditorContainer>
          <Text>
            Nesse exemplo, o ID da árvore é gerado usando a função{' '}
            <Text code>treeIdHelper</Text>, que é desestruturada do objeto{' '}
            <Text code>hookHelpers</Text>. A biblioteca interna URL e a
            biblioteca padrão <Text code>axios</Text> também estão disponíveis
            para serem usadas neste script personalizado.
          </Text>
        </Panel>
      </Collapse>
      <Tag color="orange" style={{ marginBottom: '0.8rem' }}>
        Por favor, sempre finalize a linha com ; (ponto e vírgula) até mesmo
        comentários!{' '}
      </Tag>
      <MainEditorContainer ref={mainViewEditorRef} />
    </Modal>
  );
};

HookModal.propTypes = {
  hookModal: PropTypes.shape({
    visible: PropTypes.bool,
    integrationId: PropTypes.string,
  }),
  setHookModal: PropTypes.func,
};

export default HookModal;
