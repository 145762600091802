import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn/index.tsx';
import OperationSet from '../pages/OperationSet';
import Registration from '../pages/Registration';
import CatalogIntegration from '../pages/CatalogIntegration';
import GoogleAnalytics from '../pages/GoogleAnalytics';
import Tracking from '../pages/Tracking';
import Customize from '../pages/Customize';
import Rankings from '../pages/Rankings';
import Triggers from '../pages/Triggers';
import Templates from '../pages/Templates/index.tsx';
import CatalogSupport from '../pages/CatalogIntegration/CatalogSupport';
import AuditLogs from '../pages/AuditLogs';

import PrivateRoute from './route-wrapper';
import UserMgmt from '../pages/UserMgmt';

const Routes = () => (
  <Router>
    <Switch>
      <PrivateRoute
        path="/operation-select"
        onboarding
        exact
        component={OperationSet}
      />
      <PrivateRoute path="/status/:id" exact component={Dashboard} />
      <PrivateRoute
        path="/status/:id/support-tools"
        exact
        component={CatalogSupport}
      />
      <PrivateRoute
        path="/status/:id/user-management"
        exact
        component={UserMgmt}
      />
      <PrivateRoute
        path="/status/:id/cadastro-da-operacao"
        exact
        component={Registration}
      />
      <PrivateRoute
        path="/status/:id/catalogo-de-produtos/:integration"
        exact
        component={CatalogIntegration}
      />
      <PrivateRoute
        path="/status/:id/google-analytics"
        exact
        component={GoogleAnalytics}
      />
      <PrivateRoute path="/status/:id/tag-gtm" exact component={Tracking} />
      <PrivateRoute
        path="/status/:id/customizacao"
        exact
        component={Customize}
      />
      <PrivateRoute path="/status/:id/rankings" exact component={Rankings} />
      <PrivateRoute path="/status/:id/gatilhos" exact component={Triggers} />
      <PrivateRoute path="/status/:id/templates" exact component={Templates} />
      <PrivateRoute path="/status/:id/logs" exact component={AuditLogs} />
      <Route path="/" exact component={SignIn} />
    </Switch>
  </Router>
);

export default Routes;
