import styled from 'styled-components';
import { Switch, Tag } from 'antd';

export const CustomTag = styled(Tag)`
  text-transform: capitalize;
  text-align: center;
  min-width: 54px;
  margin-left: 7px;
`;

export const CustomSwitch = styled(Switch)`
  min-width: 38px;
  border: 1px solid #707070;
  background-color: transparent;
  &.ant-switch-checked {
    background-color: transparent;
    .ant-switch-handle {
      left: calc(100% - 16px - 2px);
      &:before {
        background-color: #58f5a1;
        box-shadow: none;
        border: none;
      }
    }
  }
  .ant-switch-handle {
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: transparent;
    &:before {
      border: 1px solid #707070;
      box-shadow: none;
    }
  }
`;
