import { Divider } from 'antd';
import { useCatalog } from '../../../context/catalog';
import platforms from '../../../constants/platforms';

const IntegrationMode = () => {
  const { provider, loadFormOnAction } = useCatalog();

  const getPlatformName = () => {
    if (provider !== '') {
      const platform = platforms.find(({ value }) => value === provider);
      return platform.name;
    }

    return '';
  };

  return (
    <>
      {loadFormOnAction === 'add' ? (
        <>
          <h2>Adicionar uma nova integração {getPlatformName()}</h2>
          <Divider />
        </>
      ) : (
        <h2>Configurar integração</h2>
      )}
    </>
  );
};

export default IntegrationMode;
