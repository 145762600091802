import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography, Tag, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

const LogCollapse = ({ log, setLogModal }) => {
  const { Panel } = Collapse;
  const { log: currLog, fieldId } = log;
  const { Text } = Typography;

  const formatDate = date => {
    const dateFormat = 'dd/MM/yyyy HH:mm:ss';

    return format(new Date(date), dateFormat, { locale: ptBR });
  };

  const getLogStatus = () => {
    if (currLog.status === 'failure') {
      return <Tag color="red">Falha</Tag>;
    }

    if (currLog.status === 'success') {
      return <Tag color="green">Sucesso</Tag>;
    }

    return null;
  };

  const getFailureMessage = () => {
    if (currLog.status === 'failure') {
      return (
        <Text style={{ display: 'block', margin: '1rem 0' }}>
          <RightOutlined /> Mensagem: {currLog.message}
        </Text>
      );
    }

    return null;
  };

  const hasSuccessResponse = () => currLog.status === 'success';

  const onSeeDetailledLogs = () => {
    if (currLog.success_response) {
      setLogModal({
        visible: true,
        logId: fieldId,
        detailsBody: 'success_response',
      });
    } else if (currLog.error_response) {
      setLogModal({
        visible: true,
        logId: fieldId,
        detailsBody: 'error_response',
      });
    }
  };

  const getDetailsButton = () => {
    if (currLog.success_response || currLog.error_response) {
      return (
        <Button onClick={onSeeDetailledLogs} type="primary">
          Ver log detalhado
        </Button>
      );
    }

    return null;
  };

  return (
    <Collapse style={{ margin: '1rem 0' }}>
      <Panel header={formatDate(currLog.date_time)} key={fieldId}>
        <Text style={{ display: 'block', marginBottom: '1rem' }}>
          <RightOutlined /> Status: {getLogStatus()}
        </Text>
        {getFailureMessage()}
        {hasSuccessResponse() && (
          <>
            <Text style={{ display: 'block', marginBottom: '1rem' }}>
              <RightOutlined /> Tamanho do arquivo:{' '}
              {currLog.success_response.XML_size}
            </Text>
            <Text style={{ display: 'block', marginBottom: '1rem' }}>
              <RightOutlined /> Produtos encontrados:{' '}
              {currLog.success_response.products_parsed}
            </Text>
            <Text style={{ display: 'block', marginBottom: '1rem' }}>
              <RightOutlined /> Tempo de processamento:{' '}
              {currLog.success_response.timestamp}
            </Text>
          </>
        )}
        {getDetailsButton()}
      </Panel>
    </Collapse>
  );
};

LogCollapse.propTypes = {
  log: PropTypes.shape({
    fieldId: PropTypes.string,
    log: PropTypes.shape({
      date_time: PropTypes.string,
      status: PropTypes.string,
      message: PropTypes.string,
      success_response: PropTypes.shape({
        XML_size: PropTypes.string,
        products_parsed: PropTypes.number,
        timestamp: PropTypes.string,
        date_time: PropTypes.string,
      }),
      error_response: PropTypes.shape({}),
    }),
  }),
  setLogModal: PropTypes.func,
};

export default LogCollapse;
