import { Button, Modal } from 'antd';
import { useCatalog } from '../../../context/catalog';
import IntegrationMode from '../IntegrationMode';
import IntegrationForm from '../IntegrationForm';

const IntegrationModal = () => {
  const { isModalVisible, handleCancel, isLoading, feedSaveButtonDisable } =
    useCatalog();

  return (
    <Modal
      width="1250px"
      visible={isModalVisible}
      onCancel={handleCancel}
      okText="Salvar"
      cancelText="Cancelar"
      destroyOnClose
      footer={[
        <Button onClick={handleCancel} form="newIntegrationForm">
          Cancelar
        </Button>,
        <Button
          type="primary"
          form="newIntegrationForm"
          key="submit"
          htmlType="submit"
          loading={isLoading}
          disabled={feedSaveButtonDisable}
        >
          Salvar
        </Button>,
      ]}
    >
      <>
        <IntegrationMode />

        <IntegrationForm />
      </>
    </Modal>
  );
};

export default IntegrationModal;
