const blacklist = [
  'SELECT',
  'INSERT',
  'UPDATE',
  'DELETE',
  'DROP',
  'ALTER',
  'TRUNCATE',
  'UNION',
  'OR',
  'AND',
  'LIKE',
  'ESCAPE',
  '$where',
  '$eval',
  '$function',
  '$group',
  '$regex',
  '$mapReduce',
  '$orderBy',
  '$slice',
  '$push',
  '$pull',
  '$pop',
  '$unwind',
  '$geoNear',
  '$where',
  '$ne',
  '$in',
  '$nin',
  '$regex',
  '$and',
  '$or',
  '$not',
  '$nor',
  '$exists',
  '$size',
  'eval',
  'setTimeout',
  'setInterval',
  'clearTimeout',
  'clearInterval',
  'window',
  'document',
  'location',
  'alert',
  'confirm',
  'prompt',
  'XMLHttpRequest',
  'fetch',
  'open',
  'close',
  'throw',
  'new',
  'Error',
  'Function',
  'require',
  'child_process',
  'while',
  'for',
];

export default blacklist;
