import styled from 'styled-components';

export const CustomIcon = styled.div`
  display: inline-block;
  width: ${props => (props.size ? props.size : '12px')};
  height: ${props => (props.size ? props.size : '12px')};
  border-radius: 100%;
  background-color: ${props => (props.color ? props.color : '#f50')};
  margin-right: 17px;
  background-size: contain;
  background-repeat: no-repeat;
`;
