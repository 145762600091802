import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Divider, Button, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import validations from '../FormItems/FormItemsValidations';
import { useCatalog } from '../../../../context/catalog';

const TreeAttributes = ({ item }) => {
  const { integrationData } = useCatalog();
  const { label, description, helper, type } = item.keyValues;
  const currentValue = integrationData[item.keyName];

  return (
    <>
      <Divider />
      <Form.Item
        label={label || description}
        tooltip={helper}
        name={item.keyName}
      >
        <Form.List name={item.keyName} initialValue={currentValue}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restFields }) => (
                <>
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: 8,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px',
                      }}
                    >
                      <Form.Item
                        {...restFields}
                        label="Tag"
                        name={[name, 'tag']}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório',
                            type,
                          },
                          () => ({
                            validator(_, value) {
                              return validations(key, value);
                            },
                          }),
                        ]}
                      >
                        <Input name={`tree-${name}-tag`} />
                      </Form.Item>
                      <Form.Item
                        {...restFields}
                        label="Kind"
                        name={[name, 'kind']}
                        style={{ marginLeft: 5 }}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório',
                            type,
                          },
                          () => ({
                            validator(_, value) {
                              return validations(key, value);
                            },
                          }),
                        ]}
                      >
                        <Input name={`tree-${name}-kind`} />
                      </Form.Item>
                      <Form.Item
                        {...restFields}
                        label="Name"
                        name={[name, 'name']}
                        style={{ marginLeft: 5 }}
                        rules={[
                          {
                            required: true,
                            message: 'Campo obrigatório',
                            type,
                          },
                          () => ({
                            validator(_, value) {
                              return validations(name, value);
                            },
                          }),
                        ]}
                      >
                        <Input name={`tree-${name}-name`} />
                      </Form.Item>

                      <MinusCircleOutlined
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  </Space>
                  <Divider />
                </>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  style={{ borderRadius: '10px' }}
                >
                  Adicionar regra
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

TreeAttributes.propTypes = {
  item: PropTypes.shape({
    keyName: PropTypes.string,
    keyValues: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      helper: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  onFormValuesChanged: PropTypes.func,
  onTreeAddOrRemove: PropTypes.func,
};

export default TreeAttributes;
