import { useParams } from 'react-router-dom';
import {
  Divider,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  Slider,
  Switch,
} from 'antd';
import { useCatalog } from '../../../../context/catalog';

import validations from './FormItemsValidations';

import AdditionalPricesRules from '../ExceptionItems/AdditionalPricesRules';
import ClustersAsDetails from '../ExceptionItems/ClustersAsDetails';
import ClustersTypeMap from '../ExceptionItems/ClustersTypeMap';
import ImgsSizeConstraint from '../ExceptionItems/ImagesSizeConstraint';
import SpecsParseRules from '../ExceptionItems/SpecsParseRules';

const { Option } = Select;

const FormItems = ({ formKey }) => {
  const { id } = useParams();

  const [key, keyValue] = formKey;
  const { integrations, integrationData, requiredItems } = useCatalog();

  const {
    type,
    inputType,
    description,
    label,
    helper,
    is_system,
    default: defaultValue,
    options,
  } = keyValue;

  if (is_system) return null;

  if (key === 'integrationId') {
    return null;
  }

  if (key === 'payload_parse_cluster_as_details') {
    return <ClustersAsDetails formKey={formKey} />;
  }

  if (key === 'payload_parse_images_size_constraint') {
    return <ImgsSizeConstraint formKey={formKey} />;
  }

  if (key === 'payload_parse_additional_prices_rules') {
    return <AdditionalPricesRules formKey={formKey} />;
  }

  if (key === 'payload_parse_cluster_type_map') {
    return <ClustersTypeMap formKey={formKey} />;
  }

  if (key === 'payload_parse_specs_parse_rules') {
    return <SpecsParseRules formKey={formKey} />;
  }

  if (key === 'tenant_id') {
    return (
      <Form.Item
        label={label || description}
        name={key}
        initialValue={id}
        tooltip={helper}
      >
        <Mentions
          style={{ width: '100%' }}
          placeholder={id}
          disabled
          options={options}
        />
      </Form.Item>
    );
  }

  if (key === 'active') {
    return (
      <>
        <Form.Item
          label={label || description}
          name={key}
          initialValue={integrations.active}
          tooltip={helper}
        >
          <Switch defaultChecked={integrations.active} />
        </Form.Item>
        <Divider />
      </>
    );
  }

  if (inputType === 'text') {
    return (
      <Form.Item
        label={label || description}
        name={key}
        initialValue={integrationData[key] || defaultValue || ''}
        rules={[
          {
            required: requiredItems?.includes(key),
            message: 'Campo obrigatório',
            type,
          },
          () => ({
            validator(_, value) {
              return validations(key, value);
            },
          }),
        ]}
        hasFeedback
        tooltip={helper}
      >
        {type === 'number' ? (
          <InputNumber />
        ) : (
          <Input placeholder={description || label} />
        )}
      </Form.Item>
    );
  }

  if (inputType === 'switch') {
    return (
      <>
        <Form.Item
          label={label || description}
          name={key}
          initialValue={
            key in integrationData
              ? integrationData[key]
              : defaultValue || false
          }
          tooltip={helper}
        >
          <Switch
            defaultChecked={
              key in integrationData
                ? integrationData[key]
                : defaultValue || false
            }
          />
        </Form.Item>
      </>
    );
  }

  if (inputType === 'select') {
    if (options && options.length) {
      return (
        <Form.Item
          label={label || description}
          name={key}
          rules={[
            {
              required: requiredItems?.includes(key),
              message: 'Campo obrigatório',
            },
          ]}
          initialValue={integrationData[key]}
          tooltip={helper}
        >
          <Select
            allowClear={!requiredItems?.includes(key)}
            placeholder={description || label}
          >
            {options.map(option => (
              <Option value={option[0]}>{option[1]}</Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    return (
      <Form.Item
        label={label || description}
        name={key}
        required={requiredItems?.includes(key)}
        rules={[
          {
            required: requiredItems?.includes(key),
            message: 'Campo obrigatório',
          },
        ]}
        initialValue={integrationData[key]}
        tooltip={helper}
      >
        <Select
          mode="tags"
          allowClear={!requiredItems?.includes(key)}
          placeholder={label}
        />
      </Form.Item>
    );
  }

  if (inputType === 'textArea') {
    return (
      <Form.Item
        label={label || description}
        name={key}
        hasFeedback
        initialValue={integrationData[key] || defaultValue}
        rules={[
          {
            required: requiredItems?.includes(key),
            message: 'Este campo não pode ficar em branco',
          },
        ]}
        tooltip={helper}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
    );
  }

  if (inputType === 'slider') {
    const { min, max } = formKey[1];

    const marks = {
      [min]: min,
      [max]: max,
    };

    return (
      <>
        <Divider />
        <Form.Item
          label={label || description}
          name={key}
          required={requiredItems?.includes(key)}
          initialValue={integrationData[key] || defaultValue}
          tooltip={helper}
        >
          <Slider
            min={min || 1}
            max={max || 10}
            marks={marks}
            defaultValue={defaultValue}
          />
        </Form.Item>
      </>
    );
  }

  return '';
};

export default FormItems;
