import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Input, Divider, Row, Col, Tag, Tooltip, Form, Select } from 'antd';
import {
  FiExternalLink,
  FiCopy,
  FiChevronRight,
  FiDownload,
} from 'react-icons/fi';

// import StatusDetails from '../../components/StatusDetails';

import {
  Container,
  ContainerContent,
  CopyContainer,
  ExternalLink,
  DownloadButton,
} from './styles';

import Loading from '../../components/Loading';
import { getGTMTag, handleDownload } from '../../domain/Tracking';

const { TextArea } = Input;

const Tracking = () => {
  const { id } = useParams();
  // // const { status, alerts } = props.location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [tagGTM, setTagGTM] = useState('');
  const [tagGTMVersion, setTagGTMVersion] = useState(5);
  const [isDownloadable, setIsDownloadable] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  const getBeonTagLoader = allTags => {
    const beonTag = allTags.containerVersion.tag.filter(
      tag => tag.name === 'beon_tag_loader',
    );

    return beonTag[0].parameter[0].value;
  };

  const getTagGTM = async version => {
    setIsLoading(true);
    const response = await getGTMTag(id, version);

    if (response instanceof Error) {
      setTagGTM('Tag indisponível');
      setIsLoading(false);
    } else {
      const beonGTMTagValue = getBeonTagLoader(response.data);
      console.log(beonGTMTagValue);
      setTagGTM(beonGTMTagValue);
      setIsDownloadable(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTagGTM(5);
  }, []);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const onTagVersionSelectChange = value => {
    getTagGTM(value);
    setTagGTMVersion(value);
  };

  return (
    <Row gutter={16}>
      <Col span={18} className="gutter-row">
        <Container>
          <h2>TAG GTM</h2>
          <h4>01 TAG DE INTEGRAÇÃO</h4>
          <Divider />
          <Form>
            <Form.Item label="Selecione a versão do script" name="tag-version">
              <Select
                style={{ width: '7%' }}
                defaultValue="5.0"
                onChange={onTagVersionSelectChange}
                options={[
                  {
                    value: 4,
                    label: '4.0',
                  },
                  {
                    value: 5,
                    label: '5.0',
                  },
                ]}
              />
            </Form.Item>
          </Form>
          <ContainerContent data-testid="gtm tag">
            {isLoading ? (
              <Loading />
            ) : (
              tagGTM && (
                <>
                  <CopyContainer>
                    <CopyToClipboard text={tagGTM} onCopy={onCopyText}>
                      <span>
                        {isCopied ? (
                          <Tag color="success">Copied!</Tag>
                        ) : (
                          <Tooltip title="Copy to clipboard">
                            <FiCopy />
                          </Tooltip>
                        )}
                      </span>
                    </CopyToClipboard>
                  </CopyContainer>
                  <SyntaxHighlighter
                    wrapLines
                    wrapLongLines
                    language="html"
                    style={a11yLight}
                  >
                    {tagGTM}
                  </SyntaxHighlighter>
                </>
              )
            )}
          </ContainerContent>

          <br />

          <ExternalLink>
            <FiChevronRight />
            <a
              href="https://developers.google.com/tag-manager/quickstart"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instruções de integração via código
            </a>
            <FiExternalLink />
          </ExternalLink>
        </Container>

        <Container>
          <h4>02 DOWNLOAD</h4>
          <Divider />
          <ContainerContent>
            <p>Pacote para importação no Google Tag Manager</p>

            <DownloadButton
              disabled={isDownloadable}
              onClick={() => handleDownload(id, tagGTMVersion)}
            >
              <span>Baixar</span>
              <FiDownload />
            </DownloadButton>
          </ContainerContent>

          <br />

          <ExternalLink>
            <FiChevronRight />
            <a
              href="https://support.google.com/tagmanager/answer/6106997?hl=pt-BR&ref_topic=9002095"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instruções de importação
            </a>

            <FiExternalLink />
          </ExternalLink>
        </Container>
      </Col>
      {/* <Col span={6} className="gutter-row">
        <StatusDetails status={status} alertMessages={alerts} />
      </Col> */}
    </Row>
  );
};

Tracking.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default Tracking;
