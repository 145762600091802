import styled from 'styled-components';

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProductImage = styled.img`
  width: 50%;
`;

export const JsonContainer = styled.div`
  border: 1px solid #dadada;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 70%;
`;
