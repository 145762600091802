import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Input, Form, Radio, InputNumber } from 'antd';
import { useCatalog } from '../../../../context/catalog';

const additionalDiscountOnKey =
  'config_product_tags_price_additional_discount_on';

const additionalDiscountKey = 'config_product_tags_price_additional_discount';

const FeedAdditionalPriceRules = ({ item, onFormValuesChanged, feedForm }) => {
  const [itemRequired, setItemRequired] = useState(false);
  const [additionalDiscount, setAdditionalDiscount] = useState({});
  const [additionalDiscountOn, setAdditionalDiscountOn] = useState({});
  const [fieldsValue, setFieldsValue] = useState({
    priceLabel: '',
    discount: '',
    discountOn: '',
  });

  const { formKeys, integrationData } = useCatalog();

  const { label, description, helper } = item.keyValues;

  useEffect(() => {
    const verifyFields = () => {
      if (fieldsValue.discountOn === '' && fieldsValue.priceLabel === '') {
        if (fieldsValue.discount === '' || fieldsValue.discount === 0) {
          feedForm.validateFields([
            item.keyName,
            additionalDiscountKey,
            additionalDiscountOnKey,
          ]);

          setItemRequired(false);
        }
      }
    };

    verifyFields();

    Object.entries(formKeys).forEach(entry => {
      const [entryName, entryValue] = entry;

      if (entryName === additionalDiscountKey) {
        setAdditionalDiscount(entryValue);
      } else if (entryName === additionalDiscountOnKey) {
        setAdditionalDiscountOn(entryValue);
      }
    });
  }, [fieldsValue]);

  const setValue = (key, value) => {
    switch (key) {
      case additionalDiscountOnKey:
        setFieldsValue({ ...fieldsValue, discountOn: value });
        break;
      case additionalDiscountKey:
        setFieldsValue({ ...fieldsValue, discount: value });
        break;
      default:
        setFieldsValue({ ...fieldsValue, priceLabel: value });
    }
  };

  const onItemChange = (values, key, hasOnly = false) => {
    let currentValue;

    if (!hasOnly) {
      const {
        target: { value },
      } = values;

      currentValue = value;

      if (currentValue !== integrationData[key]) {
        setItemRequired(true);
      }
    } else if (values === integrationData[key]) {
      currentValue = values;
    } else {
      currentValue = values;
      setItemRequired(true);
    }

    setValue(key, currentValue);
    onFormValuesChanged(values, hasOnly, key);
  };

  const validateInputNumber = value => {
    if (
      (fieldsValue.priceLabel !== '' || fieldsValue.discountOn !== '') &&
      value === 0
    ) {
      return Promise.reject(
        'Como os campos "Nome do preço" e/ou "Campo de preço" foram preenchido, este campo não pode ser 0!',
      );
    }

    return Promise.resolve();
  };

  return (
    <>
      <Divider />
      <Form.Item
        label={label || description}
        name={item.keyName}
        tooltip={helper}
        rules={[
          {
            required: itemRequired,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <Input
          name={item.keyName}
          onChange={values => onItemChange(values, item.keyName)}
          placeholder={description}
        />
      </Form.Item>
      <Form.Item
        label={additionalDiscountOn?.label || additionalDiscountOn?.description}
        name={additionalDiscountOnKey}
        tooltip={additionalDiscountOn?.helper}
        rules={[
          {
            required: itemRequired,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <Radio.Group
          name={additionalDiscountOnKey}
          onChange={values => onItemChange(values, additionalDiscountOnKey)}
          defaultValue="price_from"
        >
          <Radio.Button checked value="price_from">
            Preço de
          </Radio.Button>
          <Radio.Button value="price_to">Preço por</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={additionalDiscount?.label || additionalDiscount?.description}
        name={additionalDiscountKey}
        tooltip={additionalDiscount?.helper}
        rules={[
          {
            required: itemRequired,
            validator: (_, value) => validateInputNumber(value),
          },
        ]}
      >
        <InputNumber
          name={additionalDiscountKey}
          onChange={value => onItemChange(value, additionalDiscountKey, true)}
          min={0}
        />
      </Form.Item>
    </>
  );
};

FeedAdditionalPriceRules.propTypes = {
  item: PropTypes.shape({
    keyName: PropTypes.string,
    keyValues: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      helper: PropTypes.string,
    }),
  }),
  onFormValuesChanged: PropTypes.func,
};

export default FeedAdditionalPriceRules;
