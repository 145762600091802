import endpoints from '../constants/endpoints';

const getEndpoint = endpoint => {
  const requestedEndpoint = endpoints.find(
    element => element.name === endpoint && element.active,
  );

  if (!requestedEndpoint) {
    return '';
  }
  return requestedEndpoint.resource;
};

export default getEndpoint;
