import { useCatalog } from '../../../context/catalog';
import FeedIntegrationForm from './feed';
import GeneralIntegrationForm from './general';

const IntegrationForm = () => {
  const { provider } = useCatalog();

  switch (provider) {
    case 'integra-feed':
      return <FeedIntegrationForm />;

    default:
      return <GeneralIntegrationForm />;
  }
};

export default IntegrationForm;
