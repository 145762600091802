import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Form, Input, Space, Tooltip } from 'antd';
import { useCatalog } from '../../../../context/catalog';

const { Panel } = Collapse;

const ClustersAsDetails = ({ formKey }) => {
  const { integrationData } = useCatalog();
  const savedClusterAD = integrationData[formKey[0]];
  const { helper, label: formItemLabel } = formKey[1];

  const showCurrentAttrs = () => {
    const normalizedAttrs = savedClusterAD
      ? savedClusterAD.map(attr => {
          const { cluster_id, label, key } = attr;

          return {
            cluster_id,
            label,
            key,
          };
        })
      : [];

    return normalizedAttrs;
  };

  return (
    <Collapse
      defaultActiveKey={`${savedClusterAD?.length ? ['1'] : []}`}
      style={{ marginBottom: '2rem' }}
    >
      <Panel
        header={
          <>
            {formItemLabel}{' '}
            <Tooltip title={helper}>
              <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </>
        }
        key="1"
      >
        <Form.Item>
          <Form.List name={formKey[0]} initialValue={showCurrentAttrs()}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'cluster_id']}
                      rules={[
                        {
                          required: true,
                          message: 'Inclua o ID Vtex',
                        },
                      ]}
                    >
                      <Input placeholder="Cluster ID" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'label']}
                      rules={[
                        {
                          required: true,
                          message: 'Inclua o label detail',
                        },
                      ]}
                    >
                      <Input placeholder="Label" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[
                        {
                          required: true,
                          message: 'Inclua a chave detail',
                        },
                      ]}
                    >
                      <Input placeholder="Chave (key)" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar atributo
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

export default ClustersAsDetails;
