const EXCEPTION_KEYS = ['specsParseRules'];
const KEYS_TO_IGNORE = ['_id', 'integrationId', 'namespace', 'tenantId'];

function flattenObject(obj, parentKey = '') {
  const result = {};

  Object.keys(obj).forEach(key => {
    const newKey = parentKey ? `${parentKey}_${key}` : key;

    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      if (EXCEPTION_KEYS.includes(key)) {
        result[newKey] = obj[key];
      } else {
        const flattened = flattenObject(obj[key], newKey);
        Object.assign(result, flattened);
      }
    } else {
      result[newKey] = obj[key];
    }
  });

  return result;
}

function getObjectDiffs(obj1, obj2) {
  const keys = Object.keys(obj1);
  const diffs = {};

  keys.forEach(key => {
    if (/__id/.test(key) || KEYS_TO_IGNORE.includes(key)) return;
    if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      diffs[key] = obj2[key];
    }
  });

  return diffs;
}

const getUpdateDifference = (
  values,
  currProvider,
  integrationData,
  integrations,
) => {
  try {
    if (currProvider === 'vtex') {
      const { payload: newValues } = values;
      const flattenedNewValues = flattenObject(newValues);

      if (!integrations || !Object.entries(integrations)?.length) {
        return flattenedNewValues;
      }

      const { payload: oldValues } = integrations;
      const flattenedOldValues = flattenObject(oldValues);

      const diffs = getObjectDiffs(flattenedOldValues, flattenedNewValues);
      return diffs;
    }

    if (currProvider === 'integra-feed') {
      const oldValues = integrationData;
      const flattenedNewValues = flattenObject(values);

      if (!oldValues || !Object.entries(oldValues)?.length) {
        return flattenedNewValues;
      }

      const diffs = getObjectDiffs(oldValues, flattenedNewValues);
      return diffs;
    }

    return {};
  } catch (e) {
    return console.warn('GetUpdateDifference failed with', e);
  }
};

export default getUpdateDifference;
