import { Table, Tag } from 'antd';

import { UserAddOutlined } from '@ant-design/icons';

const UsersTable = ({ loading, data, showModal, id }) => {
  const handleUser = (action, userData = {}) => {
    showModal(action, userData);
  };

  const getUserRole = role => {
    let userRole = '';
    switch (role) {
      case 'owner':
        userRole = 'Administrador';
        break;
      case 'editor':
        userRole = 'Editor';
        break;
      case 'user':
        userRole = 'Apenas leitura';
        break;
      default:
        break;
    }
    return userRole;
  };

  const getStatus = user => {
    return user[`org:${id}`]?.active || '';
  };

  // Dev purposes - REMOVE & REPLACE BY ID
  // const testId = 'ccf02d85-7903-44da-a478-6ebb00a07bc6';

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'fullname',
      key: 'fullname',
      render: (_, record) => <p>{record.fullname}</p>,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => <p>{record.email}</p>,
    },
    {
      title: 'Função',
      dataIndex: 'acl',
      key: 'role',
      render: (_, record) => <p>{getUserRole(record.acl[`org:${id}`].type)}</p>,
    },
    {
      title: 'Status',
      key: 'acl-2',
      dataIndex: 'acl',
      render: (_, { acl }) => (
        <>
          <Tag color={getStatus(acl) ? 'green' : 'red'}>
            {getStatus(acl) ? 'ATIVO' : 'INATIVO'}
          </Tag>
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <button type="button" onClick={() => handleUser('edit', record)}>
          Editar
        </button>
      ),
    },
  ];

  return (
    <>
      <UserAddOutlined
        style={{ fontSize: '1.5rem', color: 'var(--beon-color)' }}
        onClick={() => handleUser('add')}
      />
      <br />
      <br />
      <Table
        loading={loading}
        bordered
        pagination={false}
        size="small"
        columns={columns}
        dataSource={data}
      />
    </>
  );
};

export default UsersTable;
