import React from 'react';
import PropTypes from 'prop-types';
import { Container, AlertResume, IconPlace } from './styles';
import StatusIcon from '../StatusIcon';
import AlertDetails from '../AlertDetails';

// TODO:
// Seria importante ter alguma validação aqui, para garantir a renderização
// do componente mesmo quando as props não são informadas.
const StatusDetails = ({ status, alertMessages }) => {
  return (
    <Container>
      <AlertResume>
        <IconPlace>
          <StatusIcon status={status} size="4em" />
        </IconPlace>
        {status === 'success' ? <p>OPERACIONAL</p> : <p>COMPROMETIDO</p>}
      </AlertResume>

      <AlertDetails alertMessages={alertMessages} />
    </Container>
  );
};

StatusDetails.propTypes = {
  status: PropTypes.string,
  alertMessages: PropTypes.arrayOf(PropTypes.object),
};

export default StatusDetails;
