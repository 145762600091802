import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { Divider, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import api from '../../infrascruture/httpRequest';

import getEndpoint from '../../utils/getEndpoint';

import StatusDetails from '../../components/StatusDetails';

import TriggerBox from './TriggerBox';

const Triggers = props => {
  const { id } = useParams();
  const { status, alerts } = props.location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [triggers, setTriggers] = useState([]);

  const endpoint = getEndpoint('apiTriggers');

  useEffect(() => {
    const getTriggers = async () => {
      setIsLoading(true);
      const response = await api.get(`${endpoint}/${id}/list`);

      setTriggers(response.data);
      setIsLoading(false);
    };

    getTriggers();
  }, [endpoint, id]);

  return (
    <Row gutter={16}>
      <Col span={18} className="gutter-row">
        <h2>Gatilhos de rastreamento</h2>
        <Divider />
        {isLoading ? (
          <LoadingOutlined
            style={{ fontSize: '40px', color: 'var(--beon-color)' }}
          />
        ) : (
          triggers.map(trigger => (
            <TriggerBox
              key={trigger._id}
              trigger={trigger}
              snippet={trigger.snippet_type}
              endpoint={endpoint}
              tenant_id={id}
            />
          ))
        )}
      </Col>
      <Col span={6} className="gutter-row">
        <StatusDetails status={status} alertMessages={alerts} />
      </Col>
    </Row>
  );
};

Triggers.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default Triggers;
