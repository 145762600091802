import styled from 'styled-components';

const getProductInfoFontSize = props => {
  if (props) {
    const { fontSize } = props;
    if (fontSize) return fontSize;
  }

  return '16px';
};

const getProductInfoTextDecoration = props => {
  if (props) {
    const { decoration } = props;

    if (decoration) return decoration;
  }

  return 'none';
};

export const ProductsContainer = styled.div`
  border: 1px solid black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  width: 100%;
`;

export const ProductContainer = styled.div`
  align-items: center;
  border: 1px solid black;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 1rem;
  min-height: 80px;
  padding: 10px;
  width: 25%;
`;

export const ProductImage = styled.img`
  width: ${({ width }) => width};
`;

export const ProductName = styled.h1`
  font-size: 20px;
  text-align: center;
`;

export const ProductHorizontalInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0.5rem 0;
  width: 100%;
`;

export const ProductInfo = styled.p`
  font-size: ${getProductInfoFontSize};
  text-decoration: ${getProductInfoTextDecoration};
`;

export const DetailsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ProductVerticalInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
