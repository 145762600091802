import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row, Col, Upload, message } from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import api from '../../infrascruture/httpRequest';
import getEndpoint from '../../utils/getEndpoint';

import PackageBox from './PackageBox';

import { Title, UploadBox } from './styles';

const { Dragger } = Upload;

const Customize = () => {
  const [packages, setPackages] = useState([]);

  const { id } = useParams();
  const endpoint = getEndpoint('apiCustomize');

  const getCustomizations = useCallback(async () => {
    const result = await api.get(`${endpoint}/${id}/bundles`);

    console.log(result);
    setPackages(result.data);
  }, [endpoint, id]);

  const activePackage = useMemo(() => {
    const active = packages.find(item => item.is_live === true);
    return active;
  }, [packages]);

  const uploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} Arquivo enviado com sucesso.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Não foi possível enviar o arquivo.`);
      }
    },
  };

  useEffect(() => {
    getCustomizations();
  }, []);

  return (
    <Row gutter={16}>
      <Col span={18} className="gutter-row">
        <h2>Customização</h2>
        <Title>VERSÃO LIVE</Title>
        {activePackage ? (
          <PackageBox
            item={activePackage}
            endpoint={endpoint}
            id={id}
            api={api}
            getCustomizations={getCustomizations}
          />
        ) : (
          <p>Sem pacotes ativos no momento.</p>
        )}

        <Title>OUTRAS VERSÕES</Title>
        <UploadBox>
          <Dragger className="uploadArea" accept=".pdf" {...uploadProps}>
            <p className="ant-upload-text">Solte arquivos aqui para upload</p>
          </Dragger>
          <Upload className="uploadedText" accept=".pdf" {...uploadProps}>
            <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
          </Upload>
        </UploadBox>
        {packages
          .filter(customPackage => customPackage.is_live === false)
          .map(item => (
            <PackageBox
              key={item._id}
              item={item}
              endpoint={endpoint}
              api={api}
              id={id}
              getCustomizations={getCustomizations}
            />
          ))}
      </Col>
      <Col span={6} className="gutter-row">
        {/* <StatusDetails status={status} alertMessages={alerts} /> */}
      </Col>
    </Row>
  );
};

export default Customize;
