import { createGlobalStyle } from 'styled-components';
import logo from '../assets/images/beon-logo-roxo.png';

export default createGlobalStyle`
:root {
  --error: #e74c3c;
  --warning: #f1c40f;
  --success: #2ecc71;
  
  --beon-color: #3D1371;
  --border-color: #D8D8D8;
  --background-general: #FDFDFD;

  --beonly-dark-yellow-color: rgb(232, 183, 23);  
  --beonly-gray-color: rgb(56, 76, 94);
  --background-light-gray-color: rgb(234, 234, 234); 
  
  --color-white: #FFF;
  --color-light-gray: #EDEDED;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

body {
  background: var(--color-white);
  color: #707070;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body, input, button {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;

}

input, button {
  border-radius: 4px;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 400;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  background-color: var(--beon-color);
  color: #fff;
  border: none;
  padding: 6px 20px;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--beon-color);
  }
}

div.ant-row.ant-row-center {
  margin: 0 -64px !important;
}

button.ant-btn {
  color: rgba(0,0,0,0.85);
  border-color: #d9d9d9 !important;

  &:hover {
    border-color: var(--beon-color);
    color: var(--beon-color);
    background-color: #fff;
  }
}

button.ant-btn.ant-btn-primary {
  background-color: var(--beon-color) !important;
  color: var(--color-white) !important;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8)
  }
}

a.ant-btn.ant-btn-primary.ant-btn-block {
  border-color: var(--beon-color) !important;
  background-color: var(--beon-color) !important;
  color: #fff !important;
}

div.ant-page-header-heading-left {

  span {
    color: var(--beon-color) !important;
    text-shadow: 2px 2px 2px rgba(61, 19, 113, 0.6);
    letter-spacing: 0.5px;
    padding: 4px;
    display: flex;

    &::before {
      content: "";
      background-image: url(${logo});
      background-size: contain;
      background-repeat: no-repeat;
      
      height: 35px;
      width: 80px;
    }
  }

  svg {
    color: rgba(61, 19, 113, 0.8) !important;

    &:hover {
      color: var(--beon-color) !important;
    }
  }
}

div.ant-space-item {

  a{
    color: var(--beon-color) !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

#editUser_active.ant-switch  {
  background-color: #707070 !important;
}

#editUser_active.ant-switch.ant-switch-checked {
  background-color: var(--beon-color) !important;
}

button#addUser_active.ant-switch  {
  background-color: #707070 !important;
}

button#addUser_active.ant-switch.ant-modified.ant-switch-checked {
  background-color: var(--beon-color) !important;
}

.btn-warning {
  border-color: var(--warning);
  color: var(--warning);
}
.btn-warning:hover, .btn-warning:focus {
  border-color: var(--warning);
  color: var(--warning);
}

.ant-modal-close {
  background-color: transparent !important;
  color: #707070 !important;
}
.ant-layout {
  background-color: var(--background-general);
}


.ant-menu {
  background-color: var(--background-general);
}

.ant-layout-background {
  background-color: var(--background-general);
}

.ant-layout-sider {
  background-color: var(--background-general) !important;
}

.logo {
    float: left;
    width: 112px;
    height: 55px;
    filter: brightness(0) invert(1);
    margin: 6px 24px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: contain;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.ant-menu-submenu-title svg {
  margin-right: 7px;
  position: relative;
  top: 2px;
}
.ant-modal-close {
  border-radius: 0 !important;
}
h4 {
  margin-bottom: 0;
}
pre, code, kbd, samp {
  font-size: 14px;
}
`;
