import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 62%;

  button {
    margin: 0 1rem;
  }
`;

export default ButtonsContainer;
