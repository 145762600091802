import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.defaults.validateStatus = status => status <= 499;

export default api;

export const catalogAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API_CATALOG,
});

catalogAPI.defaults.validateStatus = status => status <= 499;
