/* eslint-disable import/extensions */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'antd';

import { useCatalog } from '../../../context/catalog.js';
import sendNotification from '../../../components/Notification/index.tsx';
import FormItems from './FormItems/FormItems.jsx';

const GeneralIntegrationForm = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { formKeys, integrations, addIntegration, setFeedSaveButtonDisable } =
    useCatalog();
  const { _id } = integrations;

  useEffect(() => {
    setFeedSaveButtonDisable(false);
  }, []);

  const onFinish = async values => {
    addIntegration(values, id);
  };

  const onFinishFailed = () => {
    sendNotification(
      'Informações preenchidas incorretamente. Por favor cheque novamente e faça as devidas correções.',
      'error',
    );
  };

  return (
    <Row gutter={16}>
      <Col span={24} className="gutter-row">
        <Form
          id="newIntegrationForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          style={{ marginLeft: '2rem' }}
        >
          {_id && (
            <Form.Item name="integrationId">
              <em style={{ color: 'gray', fontSize: '12px' }}>
                Id da integração: {_id}
              </em>
            </Form.Item>
          )}

          <div style={{ width: '90%' }}>
            {Object.entries(formKeys)?.map(formKey => {
              return (
                <FormItems key={formKey[0]} formKey={formKey} form={form} />
              );
            })}
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default GeneralIntegrationForm;
