import api from '../../infrascruture/httpRequest';
import getEndpoint from '../../utils/getEndpoint';
import sendNotification from '../../components/Notification/index.tsx';

const endpoint = getEndpoint('apiIntegraGA');

export const getGAAuth = async id => {
  try {
    const GAAuthStatus = await api.get(`${endpoint}/${id}/auth/check`);

    if (GAAuthStatus.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
  return false;
};

export const getGAViews = async ({ id, ua_property }) => {
  const gaViews = await api.get(`${endpoint}/${id}/views/${ua_property}`);

  switch (gaViews.status) {
    case 200:
      return gaViews.data;
    case 403:
      return 'Não autorizado';
    case 404:
      return 'Nenhuma visão encontrada';
    default:
      return 'Erro desconhecido';
  }
};

export const getGAInfo = async id => {
  const config = await api.get(`${endpoint}/${id}/config/init`);
  const authStatus = await getGAAuth(id);

  const configData = config.data;

  return { configData, authStatus };
};

export const updateGaConfig = async (values, id) => {
  try {
    const result = await api.post(`${endpoint}/${id}/config/`, values);

    if (result.status === 200) {
      sendNotification('Dados enviados com sucesso', 'success');
    } else {
      sendNotification('Ocorreu algum problema. Tente novamente.', 'error');
    }
  } catch (err) {
    console.log(err);
    sendNotification('Ocorreu algum problema. Tente novamente.', 'error');
  }
};
