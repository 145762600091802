import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Switch, Mentions, Tag } from 'antd';
import { useCatalog } from '../../../../context/catalog';
import TreeAttributes from '../ExceptionItems/TreeAttributes';
import ParseDirection from '../ExceptionItems/ParseDirection';
import FeedAdditionalPriceRules from '../ExceptionItems/FeedAdditionalPriceRules';
import FeedInstallmentSource from '../ExceptionItems/FeedInstallmentSource';
import FeedUrlParams from '../ExceptionItems/FeedUrlParams';
import { feedXMLUrlValidation } from '../../../../constants/validations';

const FeedFormItems = ({
  item,
  tenantInfo,
  setNotSameOrigin,
  setFeedSaveButtonDisable,
  onFormValuesChanged,
  hasDifferent,
  onTreeAddOrRemove,
  feedForm,
}) => {
  const { id } = useParams();
  const { keyName, keyValues } = item;
  const { integrationData, requiredItems } = useCatalog();

  const {
    label,
    description,
    helper,
    type,
    default: defaultValue,
    is_system,
    inputType,
  } = keyValues;

  const formattValue = value => {
    if (value.includes('min')) {
      return `${value.replace('min', ' ')} minutos`;
    }

    if (value.includes('h') && !value.includes('1')) {
      return `${value.replace('h', ' ')} horas`;
    }

    const newValue = `${value.replace('h', ' ')} hora`;

    return newValue;
  };

  if (is_system) return null;

  if (keyName === 'active') return null;

  if (keyName === 'config_product_tags_installment_months') return null;

  if (keyName === 'config_product_tags_installment_amount') return null;

  if (keyName === 'config_hook_func') return null;

  if (keyName === 'config_product_tags_price_additional_discount_on')
    return null;

  if (keyName === 'config_product_tags_price_additional_discount') return null;

  if (keyName === 'config_product_tags_installment_source') {
    return (
      <FeedInstallmentSource
        item={item}
        onFormValuesChanged={onFormValuesChanged}
      />
    );
  }

  if (keyName === 'config_tree_tags_tree_attributes') {
    return <TreeAttributes item={item} />;
  }

  if (keyName === 'config_hook_active') {
    return null;
  }

  if (keyName === 'config_tree_tags_type_direction') {
    return (
      <ParseDirection item={item} onFormValuesChanged={onFormValuesChanged} />
    );
  }

  if (keyName === 'config_product_tags_price_additional_label') {
    return (
      <FeedAdditionalPriceRules
        item={item}
        onFormValuesChanged={onFormValuesChanged}
        onTreeAddOrRemove={onTreeAddOrRemove}
        feedForm={feedForm}
      />
    );
  }

  if (keyName === 'config_product_tags_url_params') {
    return (
      <FeedUrlParams
        item={item}
        onFormValuesChanged={onFormValuesChanged}
        onTreeAddOrRemove={onTreeAddOrRemove}
      />
    );
  }

  if (keyName === 'tenantId') {
    return (
      <Form.Item label={label || description} name={keyName} tooltip={helper}>
        <Mentions style={{ width: '100%' }} placeholder={id} disabled />
      </Form.Item>
    );
  }

  if (keyName === 'config_interval') {
    return (
      <Form.Item label={label || description} name={keyName} tooltip={helper}>
        <Mentions
          style={{ width: '100%' }}
          placeholder={formattValue(defaultValue)}
          disabled
        />
      </Form.Item>
    );
  }

  if (keyName === 'feed_url') {
    return (
      <>
        <Tag color="red" style={{ marginBottom: '10px' }}>
          Por favor certifique-se de incluir a URL correta do Feed XML
        </Tag>
        <Form.Item
          label={label || description}
          name={keyName}
          tooltip={helper}
          rules={[
            {
              required: true,
              validator: (_, value) =>
                feedXMLUrlValidation(
                  value,
                  tenantInfo,
                  setNotSameOrigin,
                  setFeedSaveButtonDisable,
                  hasDifferent,
                ),
            },
          ]}
        >
          <Input
            name={keyName}
            onChange={onFormValuesChanged}
            placeholder={description}
          />
        </Form.Item>
      </>
    );
  }

  if (inputType === 'switch') {
    return (
      <Form.Item
        label={label || description}
        name={keyName}
        tooltip={helper}
        rules={[
          {
            required: requiredItems.includes(keyName),
            message: 'Campo obrigatório',
            type,
          },
        ]}
      >
        <Switch
          onChange={value => onFormValuesChanged(value, true, keyName)}
          defaultChecked={
            keyName in integrationData
              ? integrationData[keyName]
              : defaultValue || false
          }
        />
      </Form.Item>
    );
  }

  if (inputType === 'text' && type === 'number') {
    return (
      <Form.Item
        label={label || description}
        name={keyName}
        tooltip={helper}
        rules={[
          {
            required: requiredItems.includes(keyName),
            message: 'Campo obrigatório',
            type,
          },
        ]}
      >
        <InputNumber
          name={keyName}
          onChange={value => onFormValuesChanged(value, true, keyName)}
        />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      label={label || description}
      name={keyName}
      tooltip={helper}
      rules={[
        {
          required: requiredItems.includes(keyName),
          message: 'Campo obrigatório',
        },
      ]}
      style={{ marginTop: '2rem' }}
    >
      <Input
        name={keyName}
        onChange={onFormValuesChanged}
        placeholder={description}
      />
    </Form.Item>
  );
};

FeedFormItems.propTypes = {
  item: PropTypes.shape({
    keyName: PropTypes.string,
    keyValues: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      helper: PropTypes.string,
      type: PropTypes.string,
      default: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      is_system: PropTypes.bool,
      inputType: PropTypes.string,
    }),
  }),
  tenantInfo: PropTypes.shape({}),
  setNotSameOrigin: PropTypes.func,
  setFeedSaveButtonDisable: PropTypes.func,
  onFormValuesChanged: PropTypes.func,
  hasDifferent: PropTypes.bool,
  onTreeAddOrRemove: PropTypes.func,
};

export default FeedFormItems;
