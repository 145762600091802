import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, Form, Input, Image } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { useAuth } from '../../context/user.tsx';
import Loading from '../../components/Loading';
import sendNotification from '../../components/Notification/index.tsx';

import { Container, LoginButton, LoginContainer, Title } from './styles';
import LogoBeonly from '../../assets/images/beonly-logo-texto.png';

type LoginFormValues = {
  username: string;
  password: string;
  remember: boolean;
};

const SignInPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const { login } = useAuth() || { login: () => Promise.reject(false) };

  const onFinish = async (values: LoginFormValues) => {
    try {
      setIsLoading(true);
      const result = await login(values);

      if (!result) {
        setIsLoading(false);
        sendNotification(
          'Falha na autenticação ou você não tem permissão de acessar este Dashboard. Verifique os dados e tente novamente.',
          'error',
        );
      } else {
        sendNotification('Login efetuado com sucesso.', 'success');
        history.push('operation-select');
      }
    } catch (e) {
      setIsLoading(false);
      sendNotification(
        'Ocorreu uma falha inesperada. Aguarde mais alguns instantes e tente novamente.',
        'error',
      );
    }
  };

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    );

  return (
    <Container>
      <LoginContainer>
        <Image
          src={LogoBeonly}
          alt="Logo Beonly"
          sizes="small"
          preview={false}
        />

        <Title>ADM Dashboard</Title>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            required
            name="username"
            rules={[
              {
                required: true,
                message: 'Verifique o formato do e-mail',
                type: 'email',
              },
            ]}
            hasFeedback
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="E-mail"
              className="login__form-input"
            />
          </Form.Item>
          <Form.Item
            required
            name="password"
            rules={[
              {
                required: true,
                message: 'Campo não pode ficar em branco',
                type: 'string',
                min: 3,
              },
            ]}
            hasFeedback
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Senha"
              className="login__form-input"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Lembrar</Checkbox>
          </Form.Item>

          <Form.Item>
            <LoginButton htmlType="submit">Entrar</LoginButton>
          </Form.Item>
        </Form>
      </LoginContainer>
    </Container>
  );
};

export default SignInPage;
