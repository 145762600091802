import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: hidden;

  h4 {
    font-size: 14px;
  }

  a {
    display: block;
    text-decoration: none;
    color: #b1b1b1;
    margin-left: 2px;
    margin-right: 4px;

    &:hover {
      text-decoration: underline;
      color: blue !important;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ContainerContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  max-width: 70%;
  border: 2px solid var(--border-color);
  overflow-y: auto !important;
  ovetrflow-x: hidden !important;

  pre {
    overflow-x: hidden !important;
  }

  .copyIcon {
    position: absolute;
    right: 0;
    top: 5%;
    bottom: 0;
    font-size: 120%;
  }
`;

export const CopyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100% !important;
  margin-top: 8px;

  span {
    display: block;
    text-align: right;
    margin-right: 8px;
  }

  svg {
    color: var(--beon-color);
  }
`;

export const ExternalLink = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
`;

export const DownloadButton = styled.button`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  height: 30px;
  border-radius: 4px;

  .svg {
    height: 20px;
    width: 20px;
  }

  &:disabled {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    cursor: not-allowed !important;
  }
`;
