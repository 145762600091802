import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const AlertDetails = ({ alertMessages }) => {
  return (
    <Container>
      {!alertMessages.length ? (
        <p data-testid="alerts-message">Sem alertas</p>
      ) : (
        <>
          <p>
            <strong>{alertMessages.length}</strong>
            {alertMessages.length === 1 ? ' alerta' : ' alertas'}
          </p>
          <ul data-testid="alert-list">
            {alertMessages &&
              alertMessages.map(alert => (
                <li key={alert.lastCheck + alert.name}>
                  <em>{alert.label}</em>
                </li>
              ))}
          </ul>
        </>
      )}
    </Container>
  );
};

AlertDetails.propTypes = {
  alertMessages: PropTypes.arrayOf(PropTypes.object),
};

export default AlertDetails;
