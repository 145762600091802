import api from '../../infrascruture/httpRequest';
import getEndpoint from '../../utils/getEndpoint';

const endpoint = getEndpoint('apiTenant');

export const getGTMTag = async (id, version) => {
  try {
    const response = await api.get(
      `${endpoint}/tagmanager/version/${version}/${id}`,
    );

    return response;
  } catch (e) {
    return e;
  }
};

const download = (urlPath, filename) => {
  const anchor = document.createElement('a');
  anchor.href = urlPath;
  anchor.download = filename;

  document.body.appendChild(anchor);

  anchor.click();

  document.body.removeChild(anchor);
};

export const handleDownload = async (id, version) => {
  try {
    const response = await api.get(
      `${endpoint}/tagmanager/version/${version}/${id}`,
    );

    const jsonFile = JSON.stringify(response.data);

    const blob = new Blob([jsonFile], { type: 'application/json' });

    const url = URL.createObjectURL(blob);

    download(url, 'beon_tag_loader.json');

    URL.revokeObjectURL(url);
  } catch (e) {
    console.error(e);
  }
};
