import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 50px;
  padding-top: 15px;
  width: 200px;
  min-height: 200px;
  border: solid 1px #b7b7b7;
  border-radius: 4px;
`;

export const AlertResume = styled.div``;

export const IconPlace = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`;
