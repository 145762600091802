import { useState } from 'react';
import {
  Collapse,
  Input,
  Form,
  Button,
  Select,
  Upload,
  Typography,
  Popconfirm,
} from 'antd';
import { InfoCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import sendNotification from '../../../components/Notification/index.tsx';
import {
  checkFeedXML,
  searchFeedXML,
  getProductFromCatalog,
  generateLog,
} from '../../../domain/catalog';
import ProductCard from './ProductCard/ProductCard';
import { CustomCarousel } from './styles';
import getEndpoint from '../../../utils/getEndpoint';
import api from '../../../infrascruture/httpRequest';
import VtexCredentials from './VtexCredentials';

const { Option } = Select;
const { Panel } = Collapse;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;

const CatalogSupport = () => {
  const { id } = useParams();
  const [feedCheck, setFeedCheck] = useState(null);
  const [feedSearch, setFeedSearch] = useState(null);
  const [productSearch, setProductSearch] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rankingUpdate, setRankingUpdate] = useState({});
  const [cacheCleaned, setCacheCleaned] = useState(false);
  const [cacheElementsCleaned, setCacheElementsCleaned] = useState(0);

  const rankingsHeatingApi = getEndpoint('apiRankingsHeating');

  const handleFeedCheck = async url => {
    if (feedCheck) {
      setFeedCheck(null);
    }
    setLoading(true);
    const result = await checkFeedXML({ url });

    setLoading(false);

    if (result) {
      setFeedCheck(result.data);
    } else {
      sendNotification(
        'Arquivo inexistente ou ocorreu algum problema nesta operação.',
        'error',
      );
    }
  };

  const handleFeedSearch = async formValues => {
    const { term, feedUrl } = formValues;
    console.log(term, feedUrl);
    if (feedSearch) {
      setFeedSearch(null);
    }

    setLoading(true);
    const result = await searchFeedXML({ term, feedUrl });

    setLoading(false);
    if (result) {
      setFeedSearch(result.data);
    } else {
      sendNotification(
        'Arquivo inexistente ou ocorreu algum problema nesta operação.',
        'error',
      );
    }
  };

  const handleProductSearch = async formValues => {
    try {
      setLoading(true);
      setSearchType(null);
      setProductSearch(null);

      const { search_type, search_term } = formValues;
      const { data } = await getProductFromCatalog(
        id,
        search_type,
        search_term,
      );

      if (data) {
        setSearchType(search_type);
        setProductSearch(data);
      }

      if (!data || (search_type === 'name' && data.length === 0)) {
        sendNotification('Nenhum produto encontrado.', 'error');
        setSearchType(null);
        setProductSearch(null);
      }
    } catch (e) {
      sendNotification('Ocorreu algum problema na operação.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const setLog = async log => {
    const result = await generateLog(log);

    if (result.status !== 201) {
      sendNotification('Falha ao gerar log', 'error');
    }
  };

  const draggerProps = {
    name: 'file',
    maxCount: 1,
    action: `${rankingsHeatingApi}/${id}`,
    accept: '.csv',

    beforeUpload: file => {
      const isCSV = file.type === 'text/csv';

      if (!isCSV) {
        sendNotification(`${file.name} não é um arquivo CSV`, 'error');
      }

      return isCSV || Upload.LIST_IGNORE;
    },

    onChange(info) {
      setRankingUpdate({});
      const { file, fileList } = info;

      if (file.status === 'error' && fileList.length > 0) {
        sendNotification(
          'Ocorreu um erro. Revise se o arquivo foi gerado no formato correto e pertence a esta operação.',
          'error',
        );
      }

      if (file.status === 'done') {
        sendNotification('Upload feito com sucesso', 'success');
        setRankingUpdate(file.response);

        const user_email = localStorage.getItem('@Beon-adm-panel:user');
        const user_id = JSON.parse(
          localStorage.getItem('@Beon-adm-panel:userid'),
        );
        const operation = localStorage.getItem(
          '@Beon-adm-panel:operation-name',
        );

        const log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: operation,
          action_type: 'Atualização manual de ranking',
        };

        setLog(log);
      }
    },
  };

  const getUpdatedRankingsTemplate = () => {
    let isSKU = true;

    if ('ids_enviados' in rankingUpdate) isSKU = false;

    return (
      <>
        <Text strong>
          {`${isSKU ? 'SKUs' : 'IDs'} enviados: 
          ${isSKU ? rankingUpdate.skus_enviados : rankingUpdate.ids_enviados}`}
        </Text>

        <Text strong type="success">
          {`${isSKU ? 'SKUs' : 'IDs'} atualizados:  ${
            isSKU
              ? rankingUpdate.skus_atualizados
              : rankingUpdate.ids_atualizados
          }`}
        </Text>

        <Text strong type="danger">
          {isSKU ? 'SKUs' : 'IDs'} não encontrados:
        </Text>
        <Paragraph>
          {isSKU
            ? rankingUpdate.skus_nao_encontrados
                .sort((a, b) => a - b)
                .join(', ')
            : rankingUpdate.ids_nao_encontrados
                .sort((a, b) => a - b)
                .join(', ')}
        </Paragraph>
      </>
    );
  };

  const cleanTenantCache = async () => {
    const CONTENT_API = getEndpoint('apiContent');
    const CLEAN_CACHE_URL = `${CONTENT_API}/${id}/contexts/purge`;

    try {
      setLoading(true);
      setCacheCleaned(false);
      setCacheElementsCleaned(0);

      const response = await api.get(CLEAN_CACHE_URL);

      if (response.status === 200) {
        setCacheElementsCleaned(response.data || 0);
        setCacheCleaned(true);
        sendNotification('Operação efetuada com sucesso!', 'success');

        const user_email = localStorage.getItem('@Beon-adm-panel:user');
        const user_id = JSON.parse(
          localStorage.getItem('@Beon-adm-panel:userid'),
        );
        const operation = localStorage.getItem(
          '@Beon-adm-panel:operation-name',
        );

        const log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: operation,
          action_type: 'Cache limpo',
        };

        const logResponse = await generateLog(log);

        if (logResponse.status !== 201) {
          sendNotification('Falha ao gerar log', 'error');
        }
      } else {
        sendNotification(
          'Algo deu errado. Por favor, tente novamente',
          'error',
        );
      }
    } catch (e) {
      console.warn(e);
      sendNotification('Algo deu errado. Por favor, tente novamente', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getCleanCacheConfirmationText = () => {
    const confirmationText = (
      <p>
        Esta ação irá limpar o cache de{' '}
        <strong style={{ fontWeight: 'bold' }}>todos</strong> os elementos desta
        operação. Deseja continuar?
      </p>
    );

    return confirmationText;
  };

  return (
    <>
      <>
        <h3>Catálogo Beon</h3>
        <Collapse style={{ width: '90%', marginBottom: '1.5rem' }}>
          <Panel header="Busca de produto" key="beonSearch">
            {id ? (
              <Form onFinish={handleProductSearch}>
                <Form.Item
                  label="Procurar por"
                  name="search_type"
                  style={{ width: '50%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Selecione o tipo de procura',
                    },
                  ]}
                >
                  <Select>
                    <Option value="name">Nome do produto</Option>
                    <Option value="product_id">Id do produto</Option>
                    <Option value="pathname">Pathname</Option>
                    <Option value="sku">SKU</Option>
                    <Option value="mpn">MPN</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Termo de pesquisa"
                  name="search_term"
                  rules={[
                    {
                      required: true,
                      message: 'Defina um termo de pesquisa',
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Pesquisar
                  </Button>
                </div>
              </Form>
            ) : (
              <p>
                <InfoCircleTwoTone /> Para ter acesso à busca de produtos
                selecione uma operação.
              </p>
            )}

            {productSearch &&
              searchType &&
              (searchType === 'name' ? (
                <>
                  {productSearch.length > 15 && (
                    <p>
                      Mostrando 15 produtos de {productSearch.length}{' '}
                      encontrados.
                    </p>
                  )}
                  <CustomCarousel>
                    {productSearch.map(
                      (product, i) =>
                        i < 15 && <ProductCard product={product} />,
                    )}
                  </CustomCarousel>
                </>
              ) : (
                <ProductCard product={productSearch} />
              ))}
          </Panel>

          <Panel header="Atualização manual de ranking">
            {id ? (
              <>
                <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined style={{ color: 'var(--beon-color)' }} />
                  </p>
                  <p className="ant-upload-text">Selecione um arquivo CSV</p>
                  <p className="ant-upload-hint">ou arraste e solte aqui</p>
                </Dragger>

                {Object.keys(rankingUpdate).length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 30,
                    }}
                  >
                    {getUpdatedRankingsTemplate()}
                  </div>
                )}
              </>
            ) : (
              <p>
                <InfoCircleTwoTone /> Para ter acesso à atualização manual de
                ranking selecione uma operação.
              </p>
            )}
          </Panel>
        </Collapse>
      </>

      <>
        <h3>Integração Frontend</h3>
        <Collapse style={{ width: '90%', marginBottom: '1.5rem' }}>
          <Panel header="Cache dos elementos" key="cache_cleaner">
            {id ? (
              <>
                {cacheCleaned && (
                  <Paragraph type="success">
                    {cacheElementsCleaned} elementos tiveram seu cache resetado.
                  </Paragraph>
                )}
                <Popconfirm
                  title={getCleanCacheConfirmationText}
                  okText="Confirmar"
                  cancelText="Cancelar"
                  onConfirm={cleanTenantCache}
                >
                  <Button type="primary" loading={loading}>
                    Limpar cache
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <p>
                <InfoCircleTwoTone /> Para ter acesso à limpeza de cache
                selecione uma operação.
              </p>
            )}
          </Panel>
        </Collapse>
      </>

      <h3>VTEX</h3>
      <VtexCredentials />
    </>
  );
};

export default CatalogSupport;
