import styled from 'styled-components';
import { Form } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 11px 10px;

  min-height: 50px;
  max-width: 80%;
  border: 2px solid var(--border-color);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin-bottom: 0;
    font-weight: bold;
    color: #707070;
    font-size: 18px;
  }

  span {
    margin-right: 8px;
  }
`;

export const SwitchSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .switch {
    margin: 0 8px;
  }

  .active {
    font-size: 10px;
  }
`;

export const ConfigDetails = styled.div`
  padding: 0 20px;
`;

export const CustomForm = styled(Form)`
  background-color: transparent;
  .ant-row {
    margin-bottom: 0;
  }
`;
