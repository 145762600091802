import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0.625rem 0;
  width: 600px;
  border: solid 1px rgba(112, 112, 112, 0.3);
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15);
  padding: 10px 8px;

  p,
  h4 {
    margin: 0;
  }
  p {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const MainContent = styled.div`
  display: flex;
`;

export const StatusArea = styled.div`
  display: block;
  margin: 0 8px;
  position: relative;
  top: 4px;
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
    color: #707070;
  }
`;

export const ButtonArea = styled.div`
  min-width: 100px;
  margin-right: 15px;
`;

export const Button = styled.button`
  background: white;
  height: 40px;
  border-radius: 4px;
  border: 1px solid black;
  padding: 0 10px;
  color: #312e38;
  width: 100%;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: black;
    color: white;
  }
`;
