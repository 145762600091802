import { Spin, Space } from 'antd';

import { Container } from './styles';

const Loading = () => (
  <Container>
    <Space size="middle">
      <Spin tip="Loading..." size="large" />
    </Space>
  </Container>
);

export default Loading;
