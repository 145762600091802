import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Button, Space, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import platforms from '../../constants/platforms';

const OperationsList = ({ loading, operations, chooseTenant }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const data = operations;

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Pesquisar operação"
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      let itemToCompare = dataIndex;
      if (Array.isArray(dataIndex)) {
        // TODO: Create a generic key generation from dataIndex array
        const [profile, name] = dataIndex;
        itemToCompare = record[profile][name];
      }

      return itemToCompare
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <>
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        </>
      ) : (
        <>{text}</>
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Operação',
      dataIndex: ['profile', 'name'],
      key: 'profile.name',
      width: 100,
      ...getColumnSearchProps(['profile', 'name']),
    },
    {
      title: 'Status',
      dataIndex: ['profile', 'active'],
      key: 'status',
      width: 50,
      render: (_, record) => (
        <Tag color={record.profile.active ? 'green' : 'red'} key={record._id}>
          {record.profile.active ? 'ATIVO' : 'INATIVO'}
        </Tag>
      ),
      sorter: (a, b) => Number(b.profile?.active) - Number(a.profile?.active),
      showSorterTooltip: false,
    },

    {
      title: 'Plataforma',
      dataIndex: ['profile', 'platform'],
      key: 'platform',
      width: 50,
      sorter: (a, b) => a.profile?.platform?.localeCompare(b.profile?.platform),
      showSorterTooltip: false,
      filterSearch: true,
      filters: platforms.map(({ name, value }) => ({ text: name, value })),
      onFilter: (value, record) => record?.profile?.platform === value,
      render: text => platforms.find(p => p.value === text)?.name || text,
    },

    {
      title: 'Ações',
      key: 'action',
      width: 100,
      dataIndex: 'base_url',
      render: (text, record) => (
        <Space size="middle">
          <Link
            to={`/status/${record._id}`}
            onClick={() => chooseTenant(record)}
          >
            Gerenciar
          </Link>
          <a href="https://app.usebeon.io" target="_blank" rel="noreferrer">
            Acessar Studio
          </a>
          <a
            href={`${record.profile.base_url}`}
            target="_blank"
            rel="noreferrer"
          >
            Ir para o site
          </a>
        </Space>
      ),
    },
  ];

  return (
    <Table
      size="small"
      loading={loading}
      columns={columns}
      dataSource={data}
      scroll={{ y: '70vh' }}
      bordered
      pagination={{
        position: ['topRight', 'bottomRight'],
      }}
    />
  );
};

export default OperationsList;
