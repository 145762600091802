import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio, Divider, InputNumber } from 'antd';
import { useCatalog } from '../../../../context/catalog';

const FeedInstallmentSource = ({ item, onFormValuesChanged }) => {
  const requiredMessage = 'Campo obrigatório quando "config" selecionado';
  const { label, description, helper } = item.keyValues;
  const { formKeys, integrationData } = useCatalog();
  const [isConfig, setIsConfig] = useState(false);
  const [installmentMonths, setInstallmentMonths] = useState({});
  const [installmentAmount, setInstallmentAmount] = useState({});

  useEffect(() => {
    Object.entries(formKeys).forEach(formKey => {
      const [keyName, keyValues] = formKey;

      if (keyName === 'config_product_tags_installment_months') {
        setInstallmentMonths({ keyName, keyValues });
      } else if (keyName === 'config_product_tags_installment_amount') {
        setInstallmentAmount({ keyName, keyValues });
      }
    });

    if (integrationData[item.keyName] === 'config') {
      setIsConfig(true);
    }
  }, []);

  const onRadioChange = ({ target: { value } }) => {
    if (value === 'config') {
      setIsConfig(true);
    } else {
      setIsConfig(false);
    }
  };

  return (
    <>
      <Divider />
      <Form.Item
        label={label || description}
        name={item.keyName}
        tooltip={helper}
      >
        <Radio.Group
          name={item.keyName}
          defaultValue="feed"
          onChange={value => {
            onRadioChange(value);
            onFormValuesChanged(value);
          }}
        >
          <Radio.Button value="feed">feed</Radio.Button>
          <Radio.Button value="config">config</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {isConfig && (
        <>
          <Form.Item
            label={
              installmentMonths.keyValues.label ||
              installmentMonths.keyValues.description
            }
            name={installmentMonths.keyName}
            tooltip={installmentMonths.keyValues.helper}
            rules={[
              {
                required: true,
                message: requiredMessage,
              },
            ]}
          >
            <InputNumber
              min={0}
              onChange={value => onFormValuesChanged(value, true, item.keyName)}
            />
          </Form.Item>
          <Form.Item
            label={
              installmentAmount.keyValues.label ||
              installmentAmount.keyValues.description
            }
            name={installmentAmount.keyName}
            tooltip={installmentAmount.keyValues.helper}
            rules={[
              {
                required: true,
                message: requiredMessage,
              },
            ]}
          >
            <InputNumber
              min={0}
              onChange={value => onFormValuesChanged(value, true, item.keyName)}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};

FeedInstallmentSource.propTypes = {
  item: PropTypes.shape({
    keyName: PropTypes.string,
    keyValues: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      helper: PropTypes.string,
    }),
  }),
};

export default FeedInstallmentSource;
