const validations = (item, value) => {
  if (item === 'credentials_appKey') {
    if (!value || /^vtexappkey-/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error('Uma appKey deve iniciar por "vtexappkey-"'),
    );
  }

  if (
    item === 'parse_product_installment_amount' ||
    item === 'parse_product_installment_months'
  ) {
    if (value < 0) {
      return Promise.reject(new Error('Este valor não pode ser negativo'));
    }
    return Promise.resolve();
  }

  if (item === 'parse_imagesSizeConstraint') {
    if (!value || /^\d+,\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        'Este campo deve conter dois números separados por uma vírgula. Ex: 500,500',
      ),
    );
  }

  if (item === 'credentials_pubSearchDomain') {
    if (value.startsWith('https://') || value.startsWith('http://')) {
      return Promise.reject(
        new Error('Este campo não pode conter o prefíxo http:// ou https://'),
      );
    }
  }

  return Promise.resolve();
};

export default validations;
