const endpoints = [
  {
    name: 'apiCatalogIntegra',
    resource: `${process.env.REACT_APP_API_CATALOG_INTEGRA}`,
    title: 'Catálogo de produtos',
    type: 'integration',
    active: true,
    healthcheck: true,
  },
  {
    name: 'apiIntegraGA',
    resource: `${process.env.REACT_APP_API_GA_INTEGRA}`,
    active: false,
    healthcheck: true,
  },
  {
    name: 'apiTenant',
    resource: `${process.env.REACT_APP_API_TENANT}`,
    active: true,
    healthcheck: true,
    title: 'Cadastro da operação',
    type: 'configuration',
  },
  {
    name: 'apiGTMTracking',
    resource: `${process.env.REACT_APP_API_TENANT}`,
    active: true,
    healthcheck: true,
    title: 'Tag GTM',
    type: 'integration',
  },
  {
    name: 'apiTriggers',
    resource: `${process.env.REACT_APP_API_TRIGGERS}`,
    active: false,
    healthcheck: true,
  },
  {
    name: 'apiCustomize',
    resource: `${process.env.REACT_APP_API_CUSTOMIZE}`,
    active: false,
    healthcheck: true,
  },
  {
    name: 'apiRankings',
    resource: `${process.env.REACT_APP_API_RANKINGS}`,
    active: false,
    healthcheck: true,
  },
  {
    name: 'apiRankingsHeating',
    resource: `${process.env.REACT_APP_API_RANKINGS_HEATING}`,
    active: true,
    healthcheck: false,
  },
  {
    name: 'apiAuth',
    resource: `${process.env.REACT_APP_API_AUTH}`,
    active: true,
    healthcheck: false,
  },
  {
    name: 'apiContent',
    resource: `${process.env.REACT_APP_API_CONTENT}`,
    active: true,
    healthcheck: false,
  },
  {
    name: 'apiECMS',
    resource: `${process.env.REACT_APP_API_ECMS}`,
    active: true,
    healthcheck: true,
    title: 'Templates',
    type: 'configuration',
  },
  {
    name: 'svcIntegraFeed',
    resource: `${process.env.REACT_APP_SVC_INTEGRA_FEED}`,
    active: true,
    healthcheck: false,
  },
  {
    name: 'svcIntegraMultiplatform',
    resource: `${process.env.REACT_APP_SVC_INTEGRA}`,
    active: true,
    healthcheck: false,
  },
  {
    name: 'svcVtex',
    resource: `${process.env.REACT_APP_SVC_VTEX}`,
    active: true,
    healthcheck: false,
  },
  {
    name: 'apiLogs',
    resource: `${process.env.REACT_APP_API_LOGS}`,
    active: true,
    healthcheck: false,
  },
];

export default Object.freeze(endpoints);
