import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import {
  Title,
  Container,
  ContainerRightSide,
  ContainerLeftSide,
  PackageVersionContent,
  CustomForm,
} from './styles';

import CustomSwitch from '../../../components/Switch';

import StatusIcon from '../../../components/StatusIcon';

const PackageBox = props => {
  const { item, api, endpoint, id, getCustomizations } = props;
  const [isActive, setIsActive] = useState(item.is_live);
  const [isLoading, setIsLoading] = useState(false);

  const [formSwitch] = Form.useForm();
  const { submit: submitSwitch } = formSwitch;

  const handlePromote = async formValues => {
    const [bundleId, isOn] = Object.entries(formValues)[0];

    try {
      setIsLoading(true);
      const result = await api.post(
        `${endpoint}/${id}/bundle/${bundleId}/promote`,
      );

      if (result.status === 200) {
        setIsActive(isOn);
        setIsLoading(false);
        getCustomizations();
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <Container>
      <ContainerRightSide>
        <StatusIcon status="success" size="16px" withIcon={false} />
        <PackageVersionContent>
          <Title data-testid="main-title">Versão {item.version}</Title>
          <p>Data do envio: {item.created_at}</p>
          {item.published_at && <p>Data em produção: {item.published_at}</p>}
        </PackageVersionContent>
      </ContainerRightSide>
      <ContainerLeftSide>
        <CustomForm form={formSwitch} onFinish={handlePromote}>
          <Form.Item name={item._id} valuePropName="checked">
            <CustomSwitch
              defaultChecked={item.is_live}
              loading={isLoading}
              className="switch"
              onChange={submitSwitch}
              isActive={isActive}
            />
          </Form.Item>
        </CustomForm>
      </ContainerLeftSide>
    </Container>
  );
};

PackageBox.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    version: PropTypes.string,
    is_live: PropTypes.bool,
    can_delete: PropTypes.bool,
    created_at: PropTypes.string,
    published_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    manifest: PropTypes.arrayOf(PropTypes.object),
  }),
  api: PropTypes.func,
  endpoint: PropTypes.string,
  id: PropTypes.string,
  getCustomizations: PropTypes.func,
};

export default PackageBox;
