import React from 'react';
// import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import AppLayout from '../components/AppLayout';

import { useAuth } from '../context/user.tsx';

const RouteWrapper = ({ component: Component, onboarding, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          onboarding ? (
            <Component {...props} />
          ) : (
            <AppLayout {...props}>
              <Component {...props} />
            </AppLayout>
          )
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

// RouteWrapper.propTypes = {
//   component: PropTypes.node,
//   onboarding: PropTypes.bool,
//   location: PropTypes.instanceOf(Route),
// };

export default RouteWrapper;
