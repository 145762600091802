import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Tag, Tabs, Typography } from 'antd';

import api from '../../infrascruture/httpRequest';
import getEndpoint from '../../utils/getEndpoint';

import Loading from '../../components/Loading';

type TemplatesParams = {
  id: string;
};

type ContextsData = {
  contexts: [
    {
      context: {
        label: string;
        name: string;
      };
      props: {
        label: string;
        status: string;
      };
    },
  ];
};

type ContextsTableItem = {
  name: string;
  status: string;
};

const { TabPane } = Tabs;
const { Title } = Typography;

const contextColumns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => (
      <Tag color={status === 'suspended' ? 'red' : 'green'} key={status}>
        {status?.toUpperCase()}
      </Tag>
    ),
  },
];

const createTableData = (data: ContextsData) => {
  const contextsTableData = data.contexts.map(({ context, props }) => {
    return {
      name: context.label,
      status: props.status,
    };
  });

  return [contextsTableData];
};

const Templates = () => {
  const { id } = useParams<TemplatesParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [presets, setPresets] = useState<ContextsTableItem[][]>([]);

  const endpoint = getEndpoint('apiECMS');

  useEffect(() => {
    const getPresets = async () => {
      setIsLoading(true);

      try {
        const { data } = await api.get(`${endpoint}/presets/get/${id}/`);
        const tables: ContextsTableItem[][] = createTableData(data);
        setPresets(tables);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    };

    getPresets();
  }, [endpoint, id]);

  return (
    <>
      <Title level={3} style={{ fontWeight: 300 }}>
        Páginas
      </Title>

      {isLoading ? (
        <Loading />
      ) : (
        presets && (
          <Tabs
            defaultActiveKey="1"
            size="middle"
            type="card"
            style={{ marginBottom: 32 }}
          >
            <TabPane tab="Páginas" key="1">
              <Table dataSource={presets[0]} columns={contextColumns} />
            </TabPane>
          </Tabs>
        )
      )}
    </>
  );
};

export default Templates;
