import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import ReactInputMask from 'react-input-mask';
import validarCNPJ from './validaCnpj';

const FormFieldDocument = ({ type = 'CNPJ', ...props }) => {
  const [mask, setMask] = useState('');

  function getMaskByDocumentType(documentType) {
    if (documentType === 'CNPJ') return '__.___.___/____-__';
    if (documentType === 'CPF') return '___.___.___-__';
    return '__.___.___/____-__';
  }

  useEffect(() => {
    if (type === 'CNPJ') {
      setMask('99.999.999/9999-99');
    } else setMask('999.999.999-99');
  }, []);

  return (
    <Form.Item
      {...props}
      rules={[
        {
          required: true,
          message: 'Campo obrigatório',
        },
        {
          validator: (_, value) =>
            type === 'CNPJ'
              ? validarCNPJ(value)
                ? Promise.resolve()
                : Promise.reject(
                    'CNPJ inválido. Por favor verifique e insira novamente.',
                  )
              : Promise.resolve(),
        },
      ]}
    >
      <ReactInputMask
        className="ant-input lg-ant-input"
        mask={mask}
        maskChar="_"
        placeholder={getMaskByDocumentType(type)}
      />
    </Form.Item>
  );
};

FormFieldDocument.propTypes = {
  type: PropTypes.string,
};

export default FormFieldDocument;
