import { Carousel } from 'antd';
import styled from 'styled-components';

export const CustomCarousel = styled(Carousel)`
  .slick-dots {
    display: flex;
    justify-content: space-evenly;
  }

  .slick-dots li button {
    border: 0.5rem solid var(--beon-color);
    width: 1.75rem;
    border-radius: 0.75rem;
  }
`;
