import { message } from 'antd';

const sendNotification = (msg: string, type: string) => {
  switch (type) {
    case 'success':
      message.success(msg);
      break;
    case 'error':
      message.error(msg);
      break;
    default:
      message.info(msg);
      break;
  }
};

export default sendNotification;
