import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { InputNumber, Form, message } from 'antd';

import {
  Container,
  Header,
  TitleSection,
  SwitchSection,
  ConfigDetails,
  CustomForm,
} from './styles';

import api from '../../../infrascruture/httpRequest';

import StatusIcon from '../../../components/StatusIcon';
import BeonSwitch from '../../../components/Switch';
import ConfigButton from '../../../components/ConfigButton';

const Ranking = ({ ranking, endpoint, tenant_id, config }) => {
  const [formSwitch] = Form.useForm();
  const [formInput] = Form.useForm();
  const { submit: submitSwitch } = formSwitch;
  const { submit: submitInput } = formInput;

  const [isConfigVisible, setIsConfigVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsConfigVisible(!isConfigVisible);
  };

  useEffect(() => {
    setIsActive(fieldConfigValue(getEnableField()));
  }, []);

  const handleSubmitSwitch = async formValue => {
    setIsLoading(true);

    try {
      const response = await api.post(
        `${endpoint}/${tenant_id}/config`,
        formValue,
      );

      if (response.status === 200) {
        const fieldValue = Object.values(formValue);
        const value = fieldValue[0];

        setIsActive(value);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      message.error('Não foi possível salvar a configuração.');
    }
  };

  const handleSubmitInput = async formValue => {
    try {
      const response = await api.patch(
        `${endpoint}/${tenant_id}/config`,
        formValue,
      );

      if (response.status === 200) {
        console.log('resp', response);
        message.success('Configuração salva com sucesso.');
      }
    } catch (err) {
      console.log(err);
      message.error('Não foi possível salvar a configuração.');
    }
  };

  const fieldConfigValue = configField => {
    return config[configField.name] ?? configField.default;
  };

  const getEnableField = () => {
    return ranking.config.find(field => /_enabled$/i.test(field.name));
  };

  return (
    <Container>
      <Header>
        <TitleSection>
          <StatusIcon status="success" size="16px" withIcon={false} />
          <h4 data-testid="header-title">{ranking.label}</h4>
        </TitleSection>
        <SwitchSection>
          <ConfigButton onClick={handleClick} isActive={isConfigVisible}>
            {!isConfigVisible ? 'Configurar' : 'Concluir'}
          </ConfigButton>
          <CustomForm form={formSwitch} onFinish={handleSubmitSwitch}>
            <Form.Item name={getEnableField().name} valuePropName="checked">
              <BeonSwitch
                className="switch"
                loading={isLoading}
                defaultChecked={fieldConfigValue(getEnableField())}
                onChange={submitSwitch}
                isActive={isActive}
              />
            </Form.Item>
          </CustomForm>
        </SwitchSection>
      </Header>
      {isConfigVisible ? (
        <ConfigDetails>
          <Form form={formInput} onFinish={handleSubmitInput} layout="vertical">
            {ranking.config
              .filter(configField => /[^_enabled]$/i.test(configField.name))
              .map(formField => (
                <Form.Item
                  key={formField.name}
                  label={formField.label}
                  name={formField.name}
                  initialValue={fieldConfigValue(formField)}
                >
                  <InputNumber
                    data-testid="input-number"
                    min={1}
                    onChange={debounce(submitInput, 500)}
                  />
                </Form.Item>
              ))}
          </Form>
        </ConfigDetails>
      ) : null}
    </Container>
  );
};

Ranking.propTypes = {
  ranking: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
      PropTypes.string,
      PropTypes.array,
    ]),
  ),
  config: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  ),
  endpoint: PropTypes.string,
  tenant_id: PropTypes.string,
};

export default Ranking;
