import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Modal, Input, Select } from 'antd';

import api from '../../../infrascruture/httpRequest';
import StatusIcon from '../../../components/StatusIcon';
import sendNotification from '../../../components/Notification/index.tsx';

import {
  Container,
  Header,
  TitleSection,
  SwitchSection,
  ConfigDetails,
  CustomForm,
  CustomSwitch,
  CustomTag,
  CustomButton,
  RowTrigger,
} from './styles';

const { Option } = Select;

const { TextArea } = Input;

const ConfigTriggerModal = ({
  trigger,
  visible,
  handleUpdate,
  handleCancel,
}) => {
  const [form] = Form.useForm();

  const normalizeValues = values => {
    const normalized = {
      trigger: {
        given: values.given,
        op: values.op,
        expected: values.expected,
      },
      snippet_type: values.snippet_type,
      snippet_source: values.snippet_source,
    };

    return normalized;
  };

  return (
    <Modal
      title={`Configuração de gatilho: ${trigger.label}`}
      okText="Salvar"
      cancelText="Cancelar"
      visible={visible}
      onCancel={handleCancel}
      width={700}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            handleUpdate(normalizeValues(values));
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        name="triggerConfig"
        initialValues={{
          given: trigger.trigger.given,
          op: trigger.trigger.op,
          expected: trigger.trigger.expected,
          snippet_type: trigger.snippet_type,
          snippet_source: trigger.snippet_source,
        }}
      >
        <Form.Item
          required
          label="Regras de disparo: "
          name="given"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
              type: 'string',
            },
          ]}
        >
          <Select style={{ width: 150, marginRight: 20 }}>
            <Option value={trigger.trigger.given}>
              {trigger.trigger.given}
            </Option>
            <Option value="body_class">Classes do body</Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          name="op"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Select style={{ width: 150, marginRight: 20 }}>
            <Option value={trigger.trigger.op}>{trigger.trigger.op}</Option>
            <Option value="not_contain">não contém</Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          name="expected"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Input type="text" name="expected" style={{ width: 150 }} />
        </Form.Item>

        <Form.Item
          required
          label="Script"
          name="snippet_type"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Select name="snippet_type" style={{ width: 150, marginRight: 20 }}>
            <Option value={trigger.snippet_type}>{trigger.snippet_type}</Option>
            <Option value="script_type">Script type</Option>
          </Select>
        </Form.Item>
        <Form.Item name="snippet_source">
          <TextArea rows={5} style={{ marginTop: 10 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TriggerBox = ({ trigger, endpoint, tenant_id }) => {
  const [formSwitch] = Form.useForm();
  const { submit: submitSwitch } = formSwitch;

  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [triggerDetails, setTriggerDetails] = useState({});

  const getTriggerDetails = async () => {
    const response = await api.get(
      `${endpoint}/${tenant_id}/trigger/${trigger._id}`,
    );

    setTriggerDetails(response.data);
    setIsModalVisible(true);

    console.log('Details', response.data);
  };

  const handleCancel = () => {
    handleModal();
  };

  const handleModal = async () => {
    if (!isModalVisible) {
      getTriggerDetails();
    } else {
      setIsModalVisible(!isModalVisible);
    }
  };

  useEffect(() => {
    setIsActive(trigger.is_live);
  }, [trigger.is_live]);

  const handleSubmitSwitch = async switchValue => {
    setIsLoading(true);

    try {
      const response = await api.patch(
        `${endpoint}/${tenant_id}/trigger/${trigger._id}`,
        switchValue,
      );

      if (response.status === 200) {
        const value = Object.values(switchValue)[0];
        console.log('valor', value);

        setIsActive(value);
        setIsLoading(false);
        sendNotification('Configuração salva com sucesso.', 'success');
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      sendNotification('Não foi possível salvar a configuração.', 'error');
    }
  };

  const handleUpdate = async formValues => {
    try {
      const response = await api.patch(
        `${endpoint}/${tenant_id}/trigger/${trigger._id}`,
        formValues,
      );

      if (response.status === 200) {
        console.log('resp', response);
        sendNotification('Configuração salva com sucesso.', 'success');
        handleModal();
      }
    } catch (err) {
      console.log(err);
      sendNotification('Não foi possível salvar a configuração.', 'error');
    }
  };

  return (
    <Container>
      {isModalVisible && (
        <ConfigTriggerModal
          trigger={triggerDetails}
          visible={isModalVisible}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )}

      <Header>
        <TitleSection>
          <StatusIcon status={trigger.status} size="16px" withIcon={false} />
          <h4 data-testid="header-title">{trigger.label}</h4>
        </TitleSection>
        <SwitchSection>
          <CustomForm form={formSwitch} onFinish={handleSubmitSwitch}>
            <Form.Item name="is_live" valuePropName="checked">
              <CustomSwitch
                className="switch"
                loading={isLoading}
                defaultChecked={trigger.is_live}
                onChange={submitSwitch}
              />
            </Form.Item>
          </CustomForm>
          {isActive ? (
            <CustomTag data-testid="tag-active" color="green">
              ativo
            </CustomTag>
          ) : (
            <CustomTag data-testid="tag-inactive" color="default">
              Inativo
            </CustomTag>
          )}
          <CustomButton onClick={handleModal}>Configurar</CustomButton>
        </SwitchSection>
      </Header>
      <ConfigDetails>
        <section data-testid="trigger-options">
          <RowTrigger>
            <p>Disparo:</p> <p>{trigger.trigger.given}</p>
            <p>{trigger.trigger.op}</p>
            <p>{trigger.trigger.expected}</p>
          </RowTrigger>
          <RowTrigger>
            <p>Script:</p> <p>{trigger.snippet_type}</p>
          </RowTrigger>
          <RowTrigger>
            <p>Situação:</p> <p>{trigger.status}</p>
          </RowTrigger>
        </section>
      </ConfigDetails>
    </Container>
  );
};

TriggerBox.propTypes = {
  endpoint: PropTypes.string,
  tenant_id: PropTypes.string,
  trigger: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    label: PropTypes.string,
    is_live: PropTypes.bool,
    trigger: PropTypes.shape({
      given: PropTypes.string,
      op: PropTypes.string,
      expected: PropTypes.string,
    }),
    snippet_type: PropTypes.string,
    snippet_source: PropTypes.string,
  }),
};

ConfigTriggerModal.propTypes = {
  trigger: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    label: PropTypes.string,
    is_live: PropTypes.bool,
    trigger: PropTypes.shape({
      given: PropTypes.string,
      op: PropTypes.string,
      expected: PropTypes.string,
    }),
    snippet_type: PropTypes.string,
    snippet_source: PropTypes.string,
  }),
  visible: PropTypes.bool,
  handleUpdate: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default TriggerBox;
