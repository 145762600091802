import React from 'react';
import PropTypes from 'prop-types';
import { CustomButton } from './styles';

const ConfigButton = ({ isActive, ...props }) => {
  return (
    <CustomButton {...props} isActive={isActive}>
      {!isActive ? 'Configurar' : 'Concluir'}
    </CustomButton>
  );
};

ConfigButton.propTypes = {
  isActive: PropTypes.bool,
};

export default ConfigButton;
