import styled from 'styled-components';
import { Button } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  background: var(--color-light-gray);
`;

export const LoginContainer = styled.div`
  width: 24rem;
  padding: 55px;

  border: 1px solid var(--color-light-gray);

  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06),
    inset 0 -0.5rem 0 0 var(--beonly-dark-yellow-color);

  border-radius: 2rem;
  background-color: var(--color-white);

  .login__form-input {
    border-radius: 7px;

    &:hover {
      border-color: var(--beonly-gray-color);
    }
  }
`;

export const Title = styled.h2`
  color: #232129;
  margin: 2rem 0;
  text-align: center;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  height: 3rem;

  margin-top: 15px;
  border-radius: 12px;

  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;

  background-color: var(--beonly-dark-yellow-color);
  color: var(--beonly-gray-color) !important;

  &:hover {
    border-color: var(--beonly-gray-color) !important;
    background-color: var(--beonly-dark-yellow-color) !important;
    color: var(--beonly-gray-color) !important;
  }
`;
