import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Tag, Space, Modal } from 'antd';
import { FaSignOutAlt } from 'react-icons/fa';

import { useHistory, Link } from 'react-router-dom';

import { useAuth } from '../../context/user.tsx';

import sendNotification from '../../components/Notification/index.tsx';
import {
  getDataFromEndpoints,
  registerTenant,
  selectTenant,
} from '../../domain/Tenant';
import { generateLog } from '../../domain/catalog';
import NewOperationForm from './newOperationForm';
import CatalogSupport from '../CatalogIntegration/CatalogSupport';
import OperationsList from './operationsList';
import { Container } from './styles';

const columns = [
  {
    title: 'Operação',
    dataIndex: 'profile.name',
    width: '40%',
    render: (_, record) => (
      <p style={{ fontWeight: 'bold' }}>{record.profile.name}</p>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'profile[active]',
    key: 'status',
    render: (_, record) => (
      <Tag color={record.profile.active ? 'green' : 'red'} key={record._id}>
        {record.profile.active ? 'ATIVO' : 'INATIVO'}
      </Tag>
    ),
  },
  {
    title: 'Ações',
    key: 'action',
    dataIndex: 'base_url',
    render: (_, record) => (
      <Space size="middle">
        <Link to={`/status/${record._id}`}>Gerenciar</Link>
        <a href="https://app.usebeon.io" target="_blank" rel="noreferrer">
          Acessar Painel
        </a>
        <a href={record.base_url} target="_blank" rel="noreferrer">
          {`${record.base_url}`}
        </a>
      </Space>
    ),
  },
];

const OperationSet = () => {
  const [operations, setOperations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOperationModalVisible, setIsOperationModalVisible] = useState(false);
  const [isSupportModalVisible, setIsSupportModalVisible] = useState(false);

  const history = useHistory();
  const { logout } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    const getOperations = async () => {
      const data = await getDataFromEndpoints();
      setOperations(data);
      setIsLoading(false);
    };

    getOperations();
  }, []);

  const showSupportModal = () => {
    setIsSupportModalVisible(true);
  };

  const handleSupportModalCancel = () => {
    setIsSupportModalVisible(false);
  };

  const showOperationModal = () => {
    setIsOperationModalVisible(true);
  };

  const handleOperationModalCancel = () => {
    setIsOperationModalVisible(false);
  };

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  const handleRegister = async values => {
    try {
      const id = await registerTenant(values);

      if (id) {
        sendNotification(
          'Cadastro realizado com sucesso, redirecionando...',
          'success',
        );

        const user_email = localStorage.getItem('@Beon-adm-panel:user');
        const user_id = JSON.parse(
          localStorage.getItem('@Beon-adm-panel:userid'),
        );

        const log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: 'Cadastro de operação',
          action_type: 'Cadastro',
        };

        const logResult = await generateLog(log);

        if (logResult.status !== 201) {
          sendNotification('Falha ao gerar log', 'error');
        }

        history.push(`/status/${id}`);
      } else {
        sendNotification(
          'Ocorreu um problema na criação da operação.',
          'error',
        );
      }
    } catch (e) {
      sendNotification('Ocorreu um problema na criação da operação.', 'error');
    }
  };

  return (
    <>
      <div
        className="site-page-header-ghost-wrapper"
        style={{ marginTop: '1rem' }}
      >
        <PageHeader
          ghost={false}
          onBack={() => handleLogout()}
          title="Gestão de operações Beon"
          subTitle=""
          backIcon={<FaSignOutAlt size={20} />}
          style={{ padding: '0 40px' }}
          extra={[
            <Button key="1" type="primary" onClick={showOperationModal}>
              Cadastrar operação
            </Button>,
            <Button key="2" onClick={showSupportModal}>
              Ferramentas de suporte
            </Button>,
          ]}
        />
      </div>
      <Container>
        <NewOperationForm
          visible={isOperationModalVisible}
          handleRegister={handleRegister}
          handleCancel={handleOperationModalCancel}
        />

        <Modal
          onCancel={handleSupportModalCancel}
          visible={isSupportModalVisible}
          width={768}
          footer={[
            <Button
              type="primary"
              htmlType="button"
              loading={isLoading}
              onClick={handleSupportModalCancel}
            >
              Fechar
            </Button>,
          ]}
        >
          <CatalogSupport />
        </Modal>

        <OperationsList
          loading={isLoading}
          operations={operations}
          chooseTenant={selectTenant}
        />
      </Container>
    </>
  );
};

export default OperationSet;
