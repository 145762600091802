import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
// import { GeneralStatusContainer } from './styles';
// import GeneralStatus from './GeneralStatus';
import OperationStatusView from './operationStatusView';
import Loading from '../../components/Loading';
import { getDataFromEndpoints, createSummary } from '../../domain/Dashboard';

const { Content } = Layout;

const Dashboard = () => {
  // const [operationSummary, setOperationSummary] = useState({});
  const [operationStatuses, setOperationStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      console.log('chamou backend');

      const data = await getDataFromEndpoints(id);
      console.log('Request finalizado: ', data);

      setOperationStatuses(data);
      // setOperationSummary(createSummary(data));
      setIsLoading(false);
    };

    getData();
  }, [id]);

  return (
    <Content
      className="site-layout-background"
      style={{
        padding: 24,
        margin: 0,
        minHeight: 280,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* <GeneralStatusContainer>
            <GeneralStatus
              summary={operationSummary}
              stepsTotal={operationStatuses.length}
            />
          </GeneralStatusContainer> */}

          <Row gutter={{ xs: 8, sm: 12, md: 24, lg: 32 }} justify="center">
            <Col span="8" className="gutter-row" style={{ overflow: 'hidden' }}>
              <OperationStatusView
                operationStatuses={operationStatuses}
                id={id}
                title="INTEGRAÇÕES"
                type="integration"
              />
            </Col>
            <Col span="8" className="gutter-row" style={{ overflow: 'hidden' }}>
              <OperationStatusView
                operationStatuses={operationStatuses}
                id={id}
                title="CONFIGURAÇÕES"
                type="configuration"
              />
            </Col>
          </Row>
        </>
      )}
    </Content>
  );
};

export default Dashboard;
