import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const CredentialTag = ({ vtexCredentialStatus }) => {
  switch (vtexCredentialStatus) {
    case 'valid':
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Credencial válida
        </Tag>
      );

    case 'invalid':
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Credencial inválida
        </Tag>
      );

    case 'unauthorized':
      return (
        <Tag icon={<CloseCircleOutlined />} color="warning">
          Credencial sem as permissões mínimas
        </Tag>
      );

    default:
      return <></>;
  }
};

CredentialTag.propTypes = {
  vtexCredentialStatus: PropTypes.string,
};

export default CredentialTag;
