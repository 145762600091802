import { createContext, useContext, useState } from 'react';
import { AxiosResponse } from 'axios';

import api from '../infrascruture/httpRequest';
import getEndpoint from '../utils/getEndpoint';

const TOKEN_KEY_USER = '@Beon-adm-panel:user';
const TOKEN_KEY_TOKEN = '@Beon-adm-panel:token';
const TOKEN_KEY_USER_ID = '@Beon-adm-panel:userid';
const TOKEN_OPERATION_NAME = '@Beon-adm-panel:operation-name';
const TOKEN_OPERATION_ID = '@Beon-adm-panel:operation-id';

type Props = {
  children: React.ReactNode;
};

type LoginValues = {
  username: string;
  password: string;
};

type UserData = {
  active: boolean;
  email: string;
  fullname: string;
  id?: string;
  role: string;
  password: string;
};

type AuthContextType = {
  userId: string;
  login: (values: LoginValues) => Promise<boolean | Error>;
  logout: () => void;
  authenticatedUser: () => string;
  isAuthenticated: () => boolean;
  createUser: (userData: UserData) => Promise<boolean | Error>;
  listUsers: (id: string) => Promise<boolean | AxiosResponse>;
  updateUser: (userData: UserData) => Promise<boolean | Error>;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: Props) => {
  const [userId, setUserId] = useState<string>('');
  const resource = getEndpoint('apiAuth');

  async function login({ username, password }: LoginValues) {
    const loginUrl = `${resource}/login`;

    try {
      const response = await api.post(loginUrl, {
        username,
        password,
      });

      if (response?.status !== 200) {
        return false;
      }

      const { data } = response;

      if (data) {
        const userApprovedScope = data.acl['bn:global'] || null;

        if (userApprovedScope && userApprovedScope.active) {
          setUserId(data._id);

          localStorage.setItem(TOKEN_KEY_USER, JSON.stringify(username));
          localStorage.setItem(TOKEN_KEY_TOKEN, JSON.stringify(data.token));
          localStorage.setItem(TOKEN_KEY_USER_ID, JSON.stringify(data._id));

          return true;
        }
      }

      return false;
    } catch (e) {
      throw new Error(String(e));
    }
  }

  async function logout() {
    localStorage.removeItem(TOKEN_KEY_USER);
    localStorage.removeItem(TOKEN_KEY_TOKEN);
    localStorage.removeItem(TOKEN_OPERATION_NAME);
    localStorage.removeItem(TOKEN_OPERATION_ID);
    localStorage.removeItem(TOKEN_KEY_USER_ID);
  }

  function isAuthenticated() {
    return localStorage.getItem(TOKEN_KEY_USER) !== null;
  }

  function authenticatedUser() {
    const user = localStorage.getItem(TOKEN_KEY_USER);
    return user ? JSON.parse(user) : '';
  }

  async function listUsers(id: string) {
    const usersEndpoint = `${resource}/user/acl/org:${id}`;

    const response = await api.get(usersEndpoint);

    if (response.status !== 200) {
      return false;
    }

    return response;
  }

  async function createUser(userData: UserData) {
    const { email, id } = userData;
    const type = 'user';
    const createUserUrl = `${resource}/user/create`;
    const scope = `org:${id}`;

    const newUser = {
      ...userData,
      username: email,
      type,
      scope,
    };

    delete newUser.id;

    const response = await api.post(createUserUrl, newUser);

    if (response?.status === 201) {
      return true;
    }

    return false;
  }

  async function updateUser(userData: UserData) {
    const { email, id, fullname, role, password, active } = userData;
    const updateUrl = `${resource}/user/update`;
    const acl = {
      [`org:${id}`]: {
        type: role,
        active,
      },
    };

    const updatedUser = {
      username: email,
      fullname,
      acl,
      password,
    };

    const response = await api.put(updateUrl, updatedUser);

    if (response?.status === 200) {
      return true;
    }

    return false;
  }

  return (
    <AuthContext.Provider
      value={{
        userId,
        authenticatedUser,
        isAuthenticated,
        login,
        logout,
        createUser,
        listUsers,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
