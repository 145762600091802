import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider, Button } from 'antd';

import {
  DetailsContainer,
  ProductImage,
  ProductName,
  ProductInfo,
  ProductVerticalInfo,
} from './styles/feedPreviewStyles';

const PreviewProductDetails = ({ detailsModal, setDetailsModal }) => {
  const {
    product: { product },
  } = detailsModal;

  const numberFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const getAvailable = () => {
    if (product.is_available) return 'Sim';

    return 'Não';
  };

  const getPromo = () => {
    if (product.is_promo) return 'Sim';

    return 'Não';
  };

  const getUUID = () => crypto.randomUUID();

  const getPromotionValues = () => {
    if (product.is_promo) {
      return (
        <>
          <ProductInfo>{`Valor original: ${numberFormatter.format(
            product.price_from,
          )}`}</ProductInfo>
          <ProductInfo>{`Valor do desconto: ${numberFormatter.format(
            product.price_discount_value,
          )}`}</ProductInfo>
          <ProductInfo>{`Porcentagem do desconto: ${product.price_discount_off}%`}</ProductInfo>
        </>
      );
    }

    return null;
  };

  if (product) {
    return (
      <Modal
        onCancel={() => setDetailsModal({ ...detailsModal, visible: false })}
        visible={detailsModal.visible}
        width="90%"
        footer={[
          <Button
            onClick={() => setDetailsModal({ ...detailsModal, visible: false })}
          >
            Fechar
          </Button>,
        ]}
      >
        <DetailsContainer>
          <ProductName>{product.name}</ProductName>
          <ProductImage width="40%" src={product.images[0]} />
          <Divider />
          <ProductInfo decoration="underline">Descrição</ProductInfo>
          <ProductInfo fontSize="14px">{product.description}</ProductInfo>
          <Divider />
          <ProductInfo decoration="underline">Condição</ProductInfo>
          <ProductInfo fontSize="14px">{product.condition}</ProductInfo>
          <Divider />
          <ProductInfo decoration="underline">Urls das imagens</ProductInfo>
          {product.images.map(image => (
            <ProductInfo key={`${image}-${getUUID()}`} fontSize="12px">
              {image}
            </ProductInfo>
          ))}
          <Divider />
          <ProductVerticalInfo>
            <ProductInfo decoration="underline">Parcelas</ProductInfo>
            <ProductInfo>{`Valor das parcelas: ${numberFormatter.format(
              product.installment_amount,
            )}`}</ProductInfo>
            <ProductInfo>{`Quantidade de parcelas: ${product.installment_months}`}</ProductInfo>
          </ProductVerticalInfo>
          <Divider />
          <ProductVerticalInfo>
            <ProductInfo decoration="underline">
              Disponibilidade e promoção
            </ProductInfo>
            <ProductInfo>{`Está disponível: ${getAvailable()}`}</ProductInfo>
            <ProductInfo>{`Está em promoção: ${getPromo()}`}</ProductInfo>
          </ProductVerticalInfo>
          <Divider />
          <ProductInfo decoration="underline">Pathname</ProductInfo>
          <ProductInfo fontSize="14px">{product.pathname}</ProductInfo>
          <Divider />
          <ProductVerticalInfo>
            <ProductInfo decoration="underline">Preços</ProductInfo>
            {getPromotionValues()}
            <ProductInfo>{`Valor atual: ${numberFormatter.format(
              product.price_to,
            )}`}</ProductInfo>
          </ProductVerticalInfo>
          <Divider />
          <ProductInfo decoration="underline">Id do produto</ProductInfo>
          <ProductInfo fontSize="14px">{product.product_id}</ProductInfo>
          <Divider />
          <ProductInfo decoration="underline">SKU</ProductInfo>
          <ProductInfo fontSize="14px">{product.sku}</ProductInfo>
          <Divider />
          <ProductInfo decoration="underline">Source</ProductInfo>
          <ProductInfo fontSize="14px">{product.source}</ProductInfo>
          <Divider />
          <ProductInfo decoration="underline">URL</ProductInfo>
          <ProductInfo fontSize="14px">{product.url}</ProductInfo>
          <Divider />
          <ProductInfo decoration="underline">Referências</ProductInfo>
          {product.references.map(reference => (
            <ProductInfo key={`${reference}-${getUUID()}`} fontSize="14px">
              {reference}
            </ProductInfo>
          ))}
          <Divider />
          {product.trees.map((tree, index) => {
            const { kind, name, priority, source, tree_id, tree_key } = tree;

            return (
              <div key={tree_id}>
                <ProductVerticalInfo>
                  <ProductInfo decoration="underline">{`Tree - ${
                    index + 1
                  }`}</ProductInfo>
                  <ProductInfo fontSize="14px">{`Tipo - ${kind}`}</ProductInfo>
                  <ProductInfo fontSize="14px">{`Nome - ${name}`}</ProductInfo>
                  <ProductInfo fontSize="14px">{`Prioridade - ${priority}`}</ProductInfo>
                  <ProductInfo fontSize="14px">{`Fonte - ${source}`}</ProductInfo>
                  <ProductInfo fontSize="14px">{`tree_id - ${tree_id}`}</ProductInfo>
                  <ProductInfo fontSize="14px">{`tree_key - ${tree_key}`}</ProductInfo>
                </ProductVerticalInfo>
                <Divider />
              </div>
            );
          })}
        </DetailsContainer>
      </Modal>
    );
  }

  return null;
};

PreviewProductDetails.propTypes = {
  detailsModal: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.string),
        condition: PropTypes.string,
        description: PropTypes.string,
        is_available: PropTypes.bool,
        is_promo: PropTypes.bool,
        installment_amount: PropTypes.number,
        installment_months: PropTypes.number,
        pathname: PropTypes.string,
        price_from: PropTypes.number,
        price_discount_value: PropTypes.number,
        price_discount_off: PropTypes.number,
        price_to: PropTypes.number,
        product_id: PropTypes.string,
        sku: PropTypes.string,
        source: PropTypes.string,
        url: PropTypes.string,
        references: PropTypes.arrayOf(PropTypes.string),
        trees: PropTypes.arrayOf(
          PropTypes.shape({
            kind: PropTypes.string,
            name: PropTypes.string,
            priority: PropTypes.number,
            source: PropTypes.string,
            tree_id: PropTypes.string,
            tree_key: PropTypes.string,
          }),
        ),
      }),
    }),
    visible: PropTypes.bool,
  }),
  setDetailsModal: PropTypes.func,
};

export default PreviewProductDetails;
