import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';

import { CustomIcon } from './styles';

const StatusIcon = ({ status, size, withIcon }) => {
  switch (status) {
    case 'success':
      return withIcon ? (
        <CheckCircleTwoTone
          style={{ fontSize: `${size}` }}
          twoToneColor="#58F5A1"
        />
      ) : (
        <CustomIcon color="#58F5A1" size={size} withIcon={withIcon} />
      );

    case 'warning':
      return withIcon ? (
        <ExclamationCircleTwoTone
          style={{ fontSize: `${size}` }}
          twoToneColor="#FFE200"
        />
      ) : (
        <CustomIcon color="#FFE200" size={size} withIcon={withIcon} />
      );

    case 'error':
      return withIcon ? (
        <CloseCircleTwoTone
          style={{ fontSize: `${size}` }}
          twoToneColor="#F55858"
        />
      ) : (
        <CustomIcon color="#F55858" size={size} withIcon={withIcon} />
      );

    // trocar para cinza(undefined) - mensagem a ser tratada!
    default:
      return withIcon ? (
        <ExclamationCircleTwoTone
          style={{ fontSize: `${size}` }}
          twoToneColor="#6f6f6d"
        />
      ) : (
        <CustomIcon color="#6f6f6d" size={size} withIcon={withIcon} />
      );
  }
};

StatusIcon.propTypes = {
  status: PropTypes.string,
  size: PropTypes.string,
  withIcon: PropTypes.bool,
};

StatusIcon.defaultProps = {
  size: '1em',
  withIcon: true,
};

export default StatusIcon;
