import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useCatalog } from '../../../context/catalog';
import { getIntegrationFormKeys } from '../../../domain/catalog';

const PlatformButton = () => {
  const { integration: platform } = useParams();

  const {
    integrations,
    isLoading,
    setFormKeys,
    setIsLoading,
    setIsModalVisible,
    setLoadFormOnAction,
    setProvider,
    setRequiredItems,
  } = useCatalog();

  const openNewIntegrationModal = async () => {
    try {
      setIsLoading(true);
      setProvider(platform);
      setLoadFormOnAction('add');

      const {
        data: {
          DescribeConfig: { properties, required },
        },
      } = await getIntegrationFormKeys(platform);

      setRequiredItems(required);
      setFormKeys(properties);
      setIsModalVisible(true);
    } catch (e) {
      console.warn('Failed to open new integration modal:', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="primary"
      disabled={isLoading || integrations?.active}
      style={{ borderRadius: '10px', marginRight: '10px' }}
      onClick={() => openNewIntegrationModal()}
    >
      Adicionar integração
    </Button>
  );
};

export default PlatformButton;
