import React from 'react';
import PropTypes from 'prop-types';
import ServiceStatus from './ServiceStatus';

const OperationStatusView = ({ operationStatuses, type, id, title }) => {
  return (
    <div>
      <h3
        style={{
          fontWeight: 'bold',
          marginBottom: '1rem',
          textAlign: 'center',
        }}
      >
        {title}
      </h3>

      {operationStatuses.length === 0 ? (
        <p>Nenhum item para exibir. Manutenção em progresso.</p>
      ) : (
        operationStatuses
          .filter(service => service.type === type)
          .map(item => (
            <ServiceStatus
              key={`${item.service}-${item.title}`}
              id={id}
              title={item.title}
              description={item.message || ''}
              status={item.status}
              alerts={item.checks}
            />
          ))
      )}
    </div>
  );
};

OperationStatusView.propTypes = {
  operationStatuses: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default OperationStatusView;
