import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio, Divider } from 'antd';

const ParseDirection = ({ item, onFormValuesChanged }) => {
  const { label, description, helper } = item.keyValues;

  return (
    <>
      <Divider />
      <Form.Item
        label={label || description}
        name={item.keyName}
        tooltip={helper}
      >
        <Radio.Group
          name={item.keyName}
          onChange={onFormValuesChanged}
          defaultValue="ltr"
          style={{ marginLeft: '1.5rem' }}
        >
          <Radio value="ltr" checked>
            ltr
          </Radio>
          <Radio value="rtl">rtl</Radio>
        </Radio.Group>
      </Form.Item>
      <Divider />
    </>
  );
};

ParseDirection.propTypes = {
  item: PropTypes.shape({
    keyName: PropTypes.string,
    keyValues: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      helper: PropTypes.string,
    }),
  }),
  onFormValuesChanged: PropTypes.func,
};

export default ParseDirection;
