import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Table, Divider, Button } from 'antd';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

const AuditLogModal = ({ visible, log, setAuditModal }) => {
  const [hasComplexData, setHasComplexData] = useState({
    has: false,
    data: [],
  });
  const { Title } = Typography;
  const { Column } = Table;

  const formatDate = date => {
    const dateFormat = 'dd/MM/yyyy HH:mm:ss';

    return format(new Date(date), dateFormat, { locale: ptBR });
  };

  let generalTableData = [];

  if (Object.entries(log).length > 0) {
    generalTableData = [
      {
        key: '1',
        name: 'Id do tenant',
        value: log.tenant_id,
      },
      {
        key: '2',
        name: 'Atualizado em',
        value: formatDate(log.time_stamp),
      },
      {
        key: '3',
        name: 'Usuário',
        value: log.user_email,
      },
      {
        key: '4',
        name: 'Id do usuário',
        value: log.user_id,
      },
      {
        key: '5',
        name: 'Local',
        value: log.app_location,
      },
      {
        key: '5',
        name: 'Recurso',
        value: log.resource?.toUpperCase(),
      },
      {
        key: '6',
        name: 'Ação',
        value: log.action_type,
      },
    ];
  }

  const getFieldTable = () => {
    if (Object.entries(log).length > 0) {
      if (log.body) {
        const fieldsColumn = [
          {
            title: 'Campo',
            dataIndex: 'field',
            key: 'field',
          },
          {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
          },
        ];

        const fieldsValue = [];
        let complexDataFound = false;
        const complexData = [];

        Object.entries(log.body).forEach((field, index) => {
          const [fieldName, fieldValue] = field;

          if (typeof fieldValue !== 'object') {
            fieldsValue.push({
              key: index,
              field: fieldName,
              value: `${fieldValue}`,
            });
          } else {
            complexDataFound = true;
            complexData.push({ [fieldName]: fieldValue });
          }
        });

        if (!hasComplexData.has && complexDataFound) {
          setHasComplexData({ has: true, data: complexData });
        }

        if (fieldsValue.length === 0) return null;

        return (
          <Table
            pagination={false}
            dataSource={fieldsValue}
            columns={fieldsColumn}
          />
        );
      }
    }

    return null;
  };

  const closeModal = () => {
    setAuditModal({ visible: false, log: {} });
    setHasComplexData({ has: false, data: [] });
  };

  return (
    <Modal
      onCancel={() => closeModal()}
      visible={visible}
      width="70%"
      footer={[
        <Button type="primary" onClick={() => closeModal()}>
          OK
        </Button>,
      ]}
    >
      <Title level={2}>Detalhes do log</Title>
      <Title level={4}>Informações gerais</Title>
      <Table dataSource={generalTableData} pagination={false}>
        <Column title="" dataIndex="name" key="name" />
        <Column title="" dataIndex="value" key="value" />
      </Table>

      <Divider />

      <Title level={4}>Campos modificados</Title>
      {getFieldTable()}

      {hasComplexData.has && (
        <div>
          <Title level={5} style={{ marginTop: '1rem' }}>
            Campos complexos:
          </Title>
          <pre>{JSON.stringify(hasComplexData.data, null, '  ')}</pre>
          <Divider />
        </div>
      )}
    </Modal>
  );
};

AuditLogModal.propTypes = {
  visible: PropTypes.bool,
  setAuditModal: PropTypes.func,
  log: PropTypes.shape({
    tenant_id: PropTypes.string,
    time_stamp: PropTypes.string,
    user_email: PropTypes.string,
    user_id: PropTypes.string,
    app_location: PropTypes.string,
    resource: PropTypes.string,
    action: PropTypes.string,
    body: PropTypes.shape({}),
  }),
};

export default AuditLogModal;
