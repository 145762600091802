import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { useCatalog } from '../../../../context/catalog';
import validations from '../FormItems/FormItemsValidations';

const ImgsSizeConstraint = ({ formKey }) => {
  const { integrationData, requiredItems } = useCatalog();
  const initValues =
    integrationData[formKey[0]] && integrationData[formKey[0]].join();

  const { type, description, label, helper } = formKey[1];

  return (
    <Form.Item
      label={label || description}
      name={formKey[0]}
      initialValue={initValues}
      rules={[
        {
          required: requiredItems?.includes(formKey[0]),
          message: 'Campo obrigatório',
          type,
        },
        () => ({
          validator(_, value) {
            return validations(formKey[0], value);
          },
        }),
      ]}
      hasFeedback
      tooltip={helper}
    >
      <Input placeholder={description} />
    </Form.Item>
  );
};

ImgsSizeConstraint.propTypes = {
  formKey: PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    helper: PropTypes.string,
    required: PropTypes.bool,
  }),
};

export default ImgsSizeConstraint;
