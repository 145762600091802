import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Divider, Form, Row, Menu, Typography, Button } from 'antd';
import { useCatalog } from '../../../context/catalog';
import sendNotification from '../../../components/Notification/index.tsx';
import FeedFormItems from './FormItems/FeedFormItems';
import { getTenantData } from '../../../domain/Tenant';
import { WarnningContainer } from './styles/feedStyles';

const FeedIntegrationForm = () => {
  const [feedForm] = Form.useForm();
  const { id } = useParams();
  const { Title } = Typography;
  const basicZone = 'basic';
  const pricingZone = 'price';
  const advancedZone = 'advanced';

  const {
    formKeys,
    integrationData,
    provider,
    loadFormOnAction,
    addIntegration,
    updateIntegration,
    setFeedTenantId,
    setFeedSaveButtonDisable,
  } = useCatalog();

  const [basicSectionItems, setBasicSectionItems] = useState([]);
  const [pricingSectionItems, setPricingSectionItems] = useState([]);
  const [advancedSectionItems, setAdvancedSectionItems] = useState([]);
  const [restItems, setRestItems] = useState([]);
  const [tenantInfo, setTenantInfo] = useState({});
  const [notSameOrigin, setNotSameOrigin] = useState(false);
  const [differentValues, setDifferentValues] = useState({
    has: false,
    values: {},
  });

  useEffect(() => {
    const separateFormKeys = () => {
      const basicSection = [];
      const pricingSection = [];
      const advancedSection = [];
      const restItemsFounded = [];

      Object.entries(formKeys).forEach(formKey => {
        const [keyName, keyValues] = formKey;
        if (!keyValues.zone) {
          restItemsFounded.push({ keyName, keyValues });
        } else if (keyValues.zone === basicZone) {
          basicSection.push({ keyName, keyValues });
        } else if (keyValues.zone === pricingZone) {
          pricingSection.push({ keyName, keyValues });
        } else if (keyValues.zone === advancedZone) {
          advancedSection.push({ keyName, keyValues });
        }
      });

      setBasicSectionItems(basicSection);
      setPricingSectionItems(pricingSection);
      setAdvancedSectionItems(advancedSection);
      setRestItems(restItemsFounded);
    };

    const getTenantInfo = async () => {
      const tenant = await getTenantData(id);

      if (tenant) {
        setTenantInfo(tenant.data);
      }
    };

    separateFormKeys();
    getTenantInfo();
  }, []);

  const { integrationId } = integrationData;

  const onFinish = async values => {
    const formattedValues = values;

    formattedValues.config_product_tags_url_params =
      values?.config_product_tags_url_params?.map(item => item?.param);

    if (loadFormOnAction === 'add') {
      addIntegration(formattedValues, id);
    } else {
      setFeedTenantId(id);
      updateIntegration({
        values: { ...formattedValues, provider },
        tenantId: id,
        integrationId,
      });
    }
  };

  const onFinishFailed = () => {
    sendNotification(
      'Informações preenchidas incorretamente. Por favor cheque novamente e faça as devidas correções.',
      'error',
    );
  };

  let initialData;

  const {
    config_tree_tags_tree_attributes,
    config_product_tags_url_params,
    ...initialDataFound
  } = integrationData;

  if (Object.entries(initialDataFound).length > 0) {
    initialData = initialDataFound;
  } else {
    const defaultInitialData = {};

    Object.entries(formKeys).forEach(([keyName, keyValues]) => {
      if (keyName !== 'config_interval') {
        defaultInitialData[keyName] = keyValues.default;
      }
    });

    initialData = defaultInitialData;
  }

  const onConfirmButton = () => {
    setFeedSaveButtonDisable(false);
    setNotSameOrigin(false);
  };

  const verifyFeedUrl = () => {
    if (notSameOrigin) {
      setFeedSaveButtonDisable(true);
      return (
        <WarnningContainer>
          <Title level={4}>
            A URL do Feed XML não tem o mesmo domínio principal deste cliente,
            tem certeza que está correto?
          </Title>
          <Button type="primary" onClick={onConfirmButton}>
            Sim, está correto
          </Button>
        </WarnningContainer>
      );
    }

    setFeedSaveButtonDisable(false);

    return null;
  };

  const onFormValuesChanged = (value, hasOnlyValue = false, keyName = '') => {
    if (!hasOnlyValue) {
      const {
        target: { name, value: inputValue },
      } = value;

      if (name.includes('tree')) {
        const nameSplit = name.split('-');
        const treeIndex = +nameSplit[1];
        const treeInputName = nameSplit[2];
        const treeAttribute =
          integrationData.config_tree_tags_tree_attributes[treeIndex];

        if (treeAttribute) {
          if (inputValue !== treeAttribute[treeInputName]) {
            setDifferentValues({
              has: true,
              values: { ...differentValues.values, [name]: inputValue },
            });

            setFeedSaveButtonDisable(false);
          } else {
            const updatedValues = differentValues.values;
            delete updatedValues[name];

            setDifferentValues({ ...differentValues, values: updatedValues });

            if (Object.entries(updatedValues).length === 0) {
              setDifferentValues({ has: false, ...differentValues });
              setFeedSaveButtonDisable(true);
            }
          }
        } else {
          setDifferentValues({
            has: true,
            values: { ...differentValues.values, [name]: inputValue },
          });

          setFeedSaveButtonDisable(false);
        }
      } else if (name.includes('params')) {
        const nameSplit = name.split('-');
        const paramIndex = +nameSplit[1];
        const param =
          integrationData.config_product_tags_url_params[paramIndex];

        if (param) {
          if (inputValue !== param) {
            setDifferentValues({
              has: true,
              values: {
                ...differentValues.values,
                [name]: inputValue,
              },
            });

            setFeedSaveButtonDisable(false);
          } else {
            const updatedValues = differentValues.values;
            delete updatedValues[name];

            setDifferentValues({ ...differentValues, values: updatedValues });

            if (Object.entries(updatedValues).length === 0) {
              setDifferentValues({ has: false, ...differentValues });
              setFeedSaveButtonDisable(true);
            }
          }
        } else {
          setDifferentValues({
            has: true,
            values: { ...differentValues.values, [name]: inputValue },
          });

          setFeedSaveButtonDisable(false);
        }
      } else {
        let finalValue = inputValue;

        if (finalValue === '') {
          finalValue = null;
        }

        if (finalValue !== integrationData[name]) {
          setDifferentValues({
            has: true,
            values: { ...differentValues.values, [name]: inputValue },
          });

          setFeedSaveButtonDisable(false);
        } else {
          const updatedValues = differentValues.values;
          delete updatedValues[name];

          setDifferentValues({ ...differentValues, values: updatedValues });

          if (Object.entries(updatedValues).length === 0) {
            setDifferentValues({ has: false, ...differentValues });
            setFeedSaveButtonDisable(true);
          }
        }
      }
    }

    if (hasOnlyValue && value !== integrationData[keyName]) {
      setDifferentValues({
        has: true,
        values: { ...differentValues.values, [keyName]: value },
      });

      setFeedSaveButtonDisable(false);
    } else if (hasOnlyValue && value === integrationData[keyName]) {
      const updatedValues = differentValues.values;
      delete updatedValues[keyName];

      setDifferentValues({ ...differentValues, values: updatedValues });

      if (Object.entries(updatedValues).length === 0) {
        setDifferentValues({ has: false, ...differentValues });
        setFeedSaveButtonDisable(true);
      }
    }
  };

  const onTreeAddOrRemove = (status, newFieldsCount, field) => {
    if (field === 'tree') {
      if (status === 'add') {
        setFeedSaveButtonDisable(false);
      } else if (newFieldsCount === 0) {
        setFeedSaveButtonDisable(true);
      } else {
        setFeedSaveButtonDisable(false);
      }
    } else if (field === 'url-params') {
      if (status === 'add') {
        setFeedSaveButtonDisable(false);
      } else if (newFieldsCount === 0) {
        setFeedSaveButtonDisable(true);
      } else {
        setFeedSaveButtonDisable(false);
      }
    }
  };

  return (
    <Row gutter={16}>
      <Col span={24} className="gutter-row">
        <Form
          id="newIntegrationForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initialData}
          form={feedForm}
          style={{ margin: '0 2rem' }}
        >
          <Divider />
          {integrationId && (
            <Form.Item name="integrationId">
              <em>Id da integração: {integrationId}</em>
            </Form.Item>
          )}
          {restItems.map(restItem => (
            <FeedFormItems
              key={restItem.keyName}
              item={restItem}
              tenantInfo={tenantInfo}
              setNotSameOrigin={setNotSameOrigin}
              setFeedSaveButtonDisable={setFeedSaveButtonDisable}
              onFormValuesChanged={onFormValuesChanged}
              hasDifferent={differentValues.has}
              onTreeAddOrRemove={onTreeAddOrRemove}
              feedForm={feedForm}
            />
          ))}
          <Menu mode="inline">
            <Menu.SubMenu
              title="De -> Para"
              style={{ backgroundColor: '#e5e5e5' }}
              key={basicZone}
            >
              {basicSectionItems.map(basicItem => (
                <FeedFormItems
                  key={basicItem.keyName}
                  item={basicItem}
                  tenantInfo={tenantInfo}
                  setNotSameOrigin={setNotSameOrigin}
                  setFeedSaveButtonDisable={setFeedSaveButtonDisable}
                  onFormValuesChanged={onFormValuesChanged}
                  hasDifferent={differentValues.has}
                  onTreeAddOrRemove={onTreeAddOrRemove}
                  feedForm={feedForm}
                />
              ))}
            </Menu.SubMenu>
            <Menu.SubMenu
              title="Precificação"
              style={{ backgroundColor: '#e5e5e5' }}
              key={pricingZone}
            >
              {pricingSectionItems.map(priceItem => (
                <FeedFormItems
                  key={priceItem.keyName}
                  item={priceItem}
                  tenantInfo={tenantInfo}
                  setNotSameOrigin={setNotSameOrigin}
                  setFeedSaveButtonDisable={setFeedSaveButtonDisable}
                  onFormValuesChanged={onFormValuesChanged}
                  hasDifferent={differentValues.has}
                  onTreeAddOrRemove={onTreeAddOrRemove}
                  feedForm={feedForm}
                />
              ))}
            </Menu.SubMenu>
            <Menu.SubMenu
              title="Avançados"
              style={{ backgroundColor: '#e5e5e5' }}
              key={advancedZone}
            >
              {advancedSectionItems.map(advancedItem => (
                <FeedFormItems
                  key={advancedItem.keyName}
                  item={advancedItem}
                  tenantInfo={tenantInfo}
                  setNotSameOrigin={setNotSameOrigin}
                  setFeedSaveButtonDisable={setFeedSaveButtonDisable}
                  onFormValuesChanged={onFormValuesChanged}
                  hasDifferent={differentValues.has}
                  onTreeAddOrRemove={onTreeAddOrRemove}
                  feedForm={feedForm}
                />
              ))}
            </Menu.SubMenu>
          </Menu>
        </Form>
        {verifyFeedUrl()}
      </Col>
    </Row>
  );
};

FeedIntegrationForm.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string,
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

export default FeedIntegrationForm;
