import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Table, Typography, Divider, Button } from 'antd';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { getFeedLogDetails } from '../../../domain/catalog';

const LogModal = ({ logModal, setLogModal }) => {
  const { id } = useParams();
  const { Title, Text } = Typography;
  const [log, setLog] = useState({});

  useEffect(() => {
    const getLog = async () => {
      const { data } = await getFeedLogDetails(id, logModal.logId);

      if (data) {
        setLog(data);
      }
    };

    getLog();
  }, []);

  const formatDate = date => {
    const dateFormat = 'dd/MM/yyyy HH:mm:ss';

    return format(new Date(date), dateFormat, { locale: ptBR });
  };

  const handleCancel = () => {
    setLogModal({ visible: false, logId: '', detailsBody: '' });
  };

  const baseTableColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const getLogStatus = () => {
    if (log.status === 'failure') return 'Falha';

    return 'Sucesso';
  };

  const getBasicTable = () => {
    const data = log[logModal.detailsBody];

    let basicTableData = [];

    if (Object.entries(log).length > 0) {
      basicTableData = [
        {
          key: '1',
          name: 'Status',
          value: getLogStatus(),
        },
        {
          key: '2',
          name: 'Log gerado em',
          value: formatDate(log.date_time),
        },
        {
          key: '3',
          name: 'Tamanho do arquivo xml',
          value: data.XML_size,
        },
        {
          key: '4',
          name: 'Produtos encontrados',
          value: data.products_parsed,
        },
        {
          key: '5',
          name: 'Tempo de processamento',
          value: data.timestamp,
        },
      ];
    }

    return <Table dataSource={basicTableData} columns={baseTableColumns} />;
  };

  const getDispatchTable = () => {
    let dispatchTableData = [];

    if (Object.entries(log).length > 0) {
      const {
        [logModal.detailsBody]: { dispatch_item_to_catalog },
      } = log;

      dispatchTableData = [
        {
          key: '1',
          name: 'Quantidade de produtos',
          value: dispatch_item_to_catalog.count,
        },
        {
          key: '2',
          name: 'Quantidade de produtos com successo',
          value: dispatch_item_to_catalog.success,
        },
        {
          key: '3',
          name: 'Percentual de sucesso',
          value: `${
            (dispatch_item_to_catalog.success /
              dispatch_item_to_catalog.count) *
            100
          }%`,
        },
      ];
    }

    return <Table dataSource={dispatchTableData} columns={baseTableColumns} />;
  };

  const getDispatchFailureTable = () => {
    const failureTableColumns = [
      {
        title: 'Id do produto',
        dataIndex: 'product_id',
        key: 'product_id',
      },
      {
        title: 'Erro',
        dataIndex: 'error',
        key: 'error',
      },
    ];

    const dispatchFailureData = [];

    if (Object.entries(log).length > 0) {
      const {
        [logModal.detailsBody]: { dispatch_item_to_catalog },
      } = log;

      if (dispatch_item_to_catalog.failure.count === 0) {
        return <Text>Nenhum erro encontrado</Text>;
      }

      dispatch_item_to_catalog.failure.log.forEach((failure, index) => {
        const {
          reason: { product_id, error },
        } = failure;
        dispatchFailureData.push({
          key: index,
          product_id,
          error,
        });
      });
    }

    return (
      <Table dataSource={dispatchFailureData} columns={failureTableColumns} />
    );
  };

  const getCleanUpTable = () => {
    const cleanUpTableColumns = [
      {
        title: 'Produtos',
        dataIndex: 'products',
        key: 'products',
      },
      {
        title: 'Rankings',
        dataIndex: 'rankings',
        key: 'rankings',
      },
    ];

    let cleanUpTableData = [];

    if (Object.entries(log).length > 0) {
      const data = log[logModal.detailsBody];

      if (data.clean_up) {
        const {
          [logModal.detailsBody]: { clean_up },
        } = log;

        cleanUpTableData = [
          {
            key: '1',
            products: clean_up.affected.products,
            rankings: clean_up.affected.rankings,
          },
        ];

        return (
          <Table dataSource={cleanUpTableData} columns={cleanUpTableColumns} />
        );
      }
    }

    return null;
  };

  const getProductFormatErrorsTable = () => {
    const productFormatTableColumns = [
      {
        title: 'Id do Produto',
        dataIndex: 'product_id',
        key: 'product_id',
      },
      {
        title: 'Erro',
        dataIndex: 'error',
        key: 'error',
      },
    ];

    const productFormatTableData = [];

    if (Object.entries(log).length > 0) {
      const {
        [logModal.detailsBody]: { errors_format_product },
      } = log;

      if (errors_format_product.count === 0) {
        return <Text>Nenhum produto com propriedades ausentes encontrado</Text>;
      }

      errors_format_product.log.forEach((error, index) => {
        const { product_id, error: logError } = error;
        productFormatTableData.push({
          key: index,
          product_id,
          error: logError,
        });
      });

      return (
        <Table
          dataSource={productFormatTableData}
          columns={productFormatTableColumns}
        />
      );
    }

    return null;
  };

  return (
    <Modal
      visible={logModal.visible}
      onCancel={handleCancel}
      width="80%"
      footer={[
        <Button onClick={handleCancel} type="primary">
          Cancelar
        </Button>,
      ]}
    >
      <Title level={2}>Detalhes da integração</Title>
      <Title level={3}>Informações gerais</Title>
      {getBasicTable()}
      <Divider />
      <Title level={3}>Produtos enviados ao catálgo</Title>
      {getDispatchTable()}
      <Divider />
      <Title level={3}>Produtos com erro de envio ao catálogo</Title>
      {getDispatchFailureTable()}
      <Title level={3}>Quantidade de Items inativados pela limpeza</Title>
      {getCleanUpTable()}
      <Divider />
      <Title level={3}>Produtos com propriedades obrigatórias ausentes</Title>
      {getProductFormatErrorsTable()}
    </Modal>
  );
};

LogModal.propTypes = {
  logModal: PropTypes.shape({
    visible: PropTypes.bool,
    logId: PropTypes.string,
    detailsBody: PropTypes.string,
  }),
  setLogModal: PropTypes.func,
};

export default LogModal;
