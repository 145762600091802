import PropTypes from 'prop-types';
import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Space,
  Tooltip,
} from 'antd';
import { useCatalog } from '../../../../context/catalog';

const { Panel } = Collapse;

const AdditionalPricesRules = ({ formKey }) => {
  const { integrationData } = useCatalog();
  const currentAttrs = integrationData[formKey[0]];
  const { helper, label } = formKey[1];

  const showCurrentAttrs = () => {
    const normalizedAttrs = currentAttrs
      ? currentAttrs.map(attr => {
          const { name, key, value } = attr;

          return {
            name,
            key,
            value: value * 100,
          };
        })
      : [];

    return normalizedAttrs;
  };

  return (
    <Collapse
      defaultActiveKey={`${currentAttrs?.length ? ['1'] : []}`}
      style={{ marginBottom: '2rem' }}
    >
      <Panel
        header={
          <>
            {label}{' '}
            <Tooltip title={helper}>
              <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </>
        }
        key="1"
      >
        <Form.Item>
          <Form.List name={formKey[0]} initialValue={showCurrentAttrs()}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Space
                      key={key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                      }}
                      align="center"
                    >
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          label="Nome do preço"
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            {
                              required: true,
                              message: 'Inclua o nome',
                            },
                          ]}
                        >
                          <Input placeholder="ex: avista" />
                        </Form.Item>

                        <Form.Item
                          label="Campo de preço"
                          {...restField}
                          name={[name, 'key']}
                          rules={[
                            {
                              required: true,
                              message: 'Defina o campo de preço',
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio.Button value="price_from">
                              Preço De
                            </Radio.Button>
                            <Radio.Button value="price_to">
                              Preço Por
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          label="Valor (%)"
                          {...restField}
                          name={[name, 'value']}
                          rules={[
                            {
                              required: true,
                              message: 'Inclua o valor',
                            },
                          ]}
                        >
                          <InputNumber min={0} max={99} step={0.5} />
                        </Form.Item>
                      </Space>

                      <MinusCircleOutlined
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => remove(name)}
                      />
                    </Space>
                    <Divider />
                  </>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    style={{ borderRadius: '10px' }}
                  >
                    Adicionar regra
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

AdditionalPricesRules.propTypes = {
  formKey: PropTypes.shape({
    label: PropTypes.string,
  }),
};

export default AdditionalPricesRules;
