import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, Button } from 'antd';

import {
  ProductContainer,
  ProductImage,
  ProductName,
  ProductInfo,
  ProductHorizontalInfo,
} from './styles/feedPreviewStyles';

const numberFormatter = new Intl.NumberFormat('pt-Br', {
  style: 'currency',
  currency: 'BRL',
});

const FeedParsedProduct = ({ product, setDetailsModal }) => {
  const { position, product: productValues } = product;
  const { Text } = Typography;

  const onDetailsModalClick = () => {
    setDetailsModal({ visible: true, product });
  };

  const getPromotionValues = () => {
    if (productValues.is_promo) {
      return (
        <ProductHorizontalInfo>
          <ProductInfo fontSize="14px">{`Preço original: ${numberFormatter.format(
            productValues.price_from,
          )}`}</ProductInfo>
          <ProductInfo fontSize="14px">{`Desconto: ${productValues.price_discount_off}%`}</ProductInfo>
        </ProductHorizontalInfo>
      );
    }

    return null;
  };

  return (
    <ProductContainer>
      <ProductImage width="60%" src={productValues.images[0]} />
      <ProductHorizontalInfo>
        <Text>{`Posição: ${position}`}</Text>
        <Text>{`SKU: ${productValues.sku}`}</Text>
      </ProductHorizontalInfo>
      <Divider />
      <ProductName>{productValues.name}</ProductName>
      <Divider />
      {getPromotionValues()}
      <ProductInfo fontSize="14px">
        {numberFormatter.format(productValues.price_to)}
      </ProductInfo>
      <Divider />
      <Button onClick={onDetailsModalClick} type="primary">
        Ver detalhes
      </Button>
    </ProductContainer>
  );
};

FeedParsedProduct.propTypes = {
  product: PropTypes.shape({
    position: PropTypes.number,
    product: PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      price_to: PropTypes.number,
      price_from: PropTypes.number,
      price_discount_off: PropTypes.number,
      sku: PropTypes.string,
      url: PropTypes.string,
      is_promo: PropTypes.bool,
    }),
  }),
  setDetailsModal: PropTypes.func,
};

export default FeedParsedProduct;
