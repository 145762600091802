import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  margin: 64px auto;
  max-width: 1120px;
  background: var(--background-general);

  @media (max-width: 1024px) {
    margin-top: 32px;
    max-width: 800px;
  }
`;
