import React from 'react';
import PropTypes from 'prop-types';
import { CustomSwitch, CustomTag } from './styles';

const BeonSwitch = ({ isActive, ...props }) => {
  return (
    <>
      <CustomSwitch className="switch" {...props} />
      {isActive ? (
        <CustomTag data-testid="tag-active" color="green">
          ativo
        </CustomTag>
      ) : (
        <CustomTag data-testid="tag-inactive" color="default">
          Inativo
        </CustomTag>
      )}
    </>
  );
};

BeonSwitch.propTypes = {
  isActive: PropTypes.bool,
};

export default BeonSwitch;
