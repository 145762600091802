import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 50vw;
  height: 50vh;
  align-items: center;
  justify-content: center;
`;
