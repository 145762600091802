import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Tag } from 'antd';

import { useCatalog } from '../../../context/catalog';

import Loading from '../../../components/Loading';
import IntegrationsBox from '../IntegrationsBox';
import PlatformButton from './PlatformButton';

const FEED = 'feed';
const INTEGRA_FEED = 'integra-feed';

const TenantConfig = () => {
  const { id, integration } = useParams();
  const { isLoading, setIsLoading, integrations, getIntegrations } =
    useCatalog();

  useEffect(() => {
    const getAllIntegrations = async () => {
      setIsLoading(true);

      if (integration === FEED) {
        await getIntegrations(id, INTEGRA_FEED);
      } else {
        await getIntegrations(id, integration);
      }

      setIsLoading(false);
    };

    getAllIntegrations();
  }, [integration]);

  return (
    <>
      <PlatformButton />

      {integration === FEED && integrations.length > 0 && (
        <Tag
          color="red"
          style={{ margin: '1rem 0', display: 'block', width: 'fit-content' }}
        >
          Antes de ativar uma configuração ou depois de modificar algum
          parâmetro, recomenda-se utilizar a opção Preview e validar as
          informações sobre a sua integração.
        </Tag>
      )}

      <Divider />

      {isLoading ? (
        <Loading />
      ) : integrations.length > 0 ? (
        integrations.map(currIntegration => (
          <IntegrationsBox
            key={currIntegration._id}
            integration={currIntegration}
            tenantId={id}
          />
        ))
      ) : Object.keys(integrations).length > 0 ? (
        <>
          <h3>Integrações salvas:</h3>
          <IntegrationsBox
            key={integrations._id}
            integration={integrations}
            tenantId={id}
          />
        </>
      ) : (
        <p>{`Nenhuma integração de catálogo ${integration} configurada para esta operação.`}</p>
      )}
    </>
  );
};

export default TenantConfig;
