import styled from 'styled-components';

export const MainEditorContainer = styled.div`
  border: 1px solid black;
  border-radius: 0 15px 15px 0;
`;

export const ExempleEditorContainer = styled.div`
  margin: 1rem 0;
`;
