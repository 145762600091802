import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Tag, Spin, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import {
  Container,
  ButtonSection,
  ConfigDetails,
  FeedRunningContainer,
} from './styles';

import { useCatalog } from '../../../context/catalog';
import sendNotification from '../../../components/Notification/index.tsx';
import {
  getIntegrationFormKeys,
  getFeedIntegrationPreview,
  runFeedIntegration,
  getMultiplatformIntegrationRunningStatus,
  runMultiplatformFullIntegration,
  generateLog,
} from '../../../domain/catalog';
import platforms from '../../../constants/platforms';
import UnitaryIntegrationModal from './UnitaryIntegrationModal';

const dateFormat = 'dd/MMM/yyyy HH:mm:ss';

const IntegrationBox = ({ integration }) => {
  const { id } = useParams();
  const {
    getIntegrations,
    setProvider,
    setIsLoading,
    setIsModalVisible,
    setFormKeys,
    setLoadFormOnAction,
    setRequiredItems,
    toggleIntegrationStatus,
    hookModal,
    setHookModal,
    getFeedIntegrationData,
    setIsPreviewModalVisible,
    setFeedPreview,
    setFeedSaveButtonDisable,
  } = useCatalog();

  const [isFeedIntegrationRunning, setIsFeedIntegrationRunning] =
    useState(false);
  const [
    isMultiplatformFullIntegrationRunning,
    setIsMultiplatformFullIntegrationRunning,
  ] = useState(false);

  const [canIntegrate, setCanIntegrate] = useState(true);
  const [intervalId, setintervalId] = useState({
    checkTimeRemainingId: '',
    vtexIntegrationLogId: '',
  });
  const [timeRemainingToIntegrate, setTimeRemainingToIntegrate] = useState(0);
  const [showUnitaryIntegrationModal, setShowUnitaryIntegrationModal] =
    useState(false);

  const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
  const [isIntegrationRunning, setIsIntegrationRunning] = useState(false);
  const [isFullIntegrationRunning, setIsFullIntegrationRunning] =
    useState(false);

  const getDiferrenceInMinutes = (firstDate, secondDate) => {
    const difference = firstDate - secondDate;

    const minutes = Math.floor(difference / 1000 / 60);

    return Math.abs(minutes);
  };

  const checkVtexIntegrationPending = async () => {
    const { data } = await getMultiplatformIntegrationRunningStatus(id);

    if (Object.entries(data).length > 0) {
      const { status } = data;

      if (status === 'pending') {
        setIsMultiplatformFullIntegrationRunning(true);
        setCanIntegrate(false);
      } else {
        setIsMultiplatformFullIntegrationRunning(false);

        if (timeRemainingToIntegrate === 0) {
          setCanIntegrate(true);
        }

        clearInterval(intervalId.vtexIntegrationLogId);

        if (status === 'finished') {
          sendNotification('Integração concluída com sucesso!', 'success');
        }
      }
    } else {
      setIsMultiplatformFullIntegrationRunning(false);

      if (timeRemainingToIntegrate === 0) {
        setCanIntegrate(true);
      }

      clearInterval(intervalId.vtexIntegrationLogId);
    }
  };

  const checkRunIntegrationViability = nextIntegrationDate => {
    const currentDate = new Date();

    if (currentDate < nextIntegrationDate) {
      const minutesRemaining = getDiferrenceInMinutes(
        currentDate,
        nextIntegrationDate,
      );

      setTimeRemainingToIntegrate(minutesRemaining);
      setCanIntegrate(false);
    } else {
      setTimeRemainingToIntegrate(0);
      if (!isMultiplatformFullIntegrationRunning) {
        setCanIntegrate(true);
      }
      clearInterval(intervalId.checkTimeRemainingId);
    }
  };

  useEffect(() => {
    const getRunIntegrationViability = async () => {
      const { namespace } = integration;

      if (namespace === 'integra-feed') {
        if (integration.last_integration) {
          const { last_integration } = integration;

          const lastIntegrationDate = new Date(last_integration);
          lastIntegrationDate.setMinutes(lastIntegrationDate.getMinutes() + 30);

          checkRunIntegrationViability(lastIntegrationDate);

          const checkTimeRemainingId = setInterval(
            () => checkRunIntegrationViability(lastIntegrationDate),
            60000,
          );
          setintervalId({ ...intervalId, checkTimeRemainingId });
        }
      } else {
        const result = await getMultiplatformIntegrationRunningStatus(id);

        if (result?.data) {
          const { data } = result;
          const { updated_at, status } = data;

          if (status === 'pending') {
            setIsMultiplatformFullIntegrationRunning(true);
            checkVtexIntegrationPending();

            const vtexIntegrationLogId = setInterval(
              () => checkVtexIntegrationPending(),
              60000,
            );

            setintervalId({ ...intervalId, vtexIntegrationLogId });
          } else if (status === 'finished') {
            const nextIntegrationDate = new Date(updated_at);
            nextIntegrationDate.setMinutes(
              nextIntegrationDate.getMinutes() + 30,
            );

            checkRunIntegrationViability(nextIntegrationDate);

            const checkTimeRemainingId = setInterval(
              () => checkRunIntegrationViability(nextIntegrationDate),
              60000,
            );

            setintervalId({ ...intervalId, checkTimeRemainingId });
          }
        } else {
          setTimeRemainingToIntegrate(0);
          setCanIntegrate(true);
        }
      }
    };

    getRunIntegrationViability();
  }, []);

  const showIntegrationForm = async () => {
    const { namespace } = integration;

    setIsLoading(true);

    if (namespace === 'integra-feed') {
      const {
        data: {
          DescribeConfig: { properties, required },
        },
      } = await getIntegrationFormKeys(namespace);

      await getIntegrations(id, namespace);

      getFeedIntegrationData(integration._id);
      setFormKeys(properties);
      setRequiredItems(required);
      setFeedSaveButtonDisable(true);
    } else {
      const {
        data: {
          DescribeConfig: { properties, required },
        },
      } = await getIntegrationFormKeys(namespace);

      await getIntegrations(id, namespace);

      setFormKeys(properties);
      setRequiredItems(required);
    }

    setProvider(namespace);
    setLoadFormOnAction('edit');
    setIsModalVisible(true);
    setIsLoading(false);
  };

  const getIntegrationName = () => {
    const platform = platforms.find(
      ({ value }) => value === integration?.namespace,
    );

    if (platform) return platform.name.toUpperCase();

    return '';
  };

  const showHookModal = async () => {
    const { namespace } = integration;

    if (namespace === 'integra-feed') {
      const {
        data: {
          DescribeConfig: { properties, required },
        },
      } = await getIntegrationFormKeys(namespace);

      await getIntegrations(id, namespace);

      setFormKeys(properties);
      setRequiredItems(required);
      getFeedIntegrationData(integration._id);
      setHookModal({
        ...hookModal,
        visible: true,
        integrationId: integration._id,
      });
    }
  };

  const openPreviewModal = async () => {
    setIsPreviewModalVisible(true);
    setIsLoading(true);

    const { data } = await getFeedIntegrationPreview(integration._id);

    setFeedPreview(data);
    setIsLoading(false);
  };

  const runFullIntegration = async () => {
    setIsPopconfirmVisible(false);
    setIsFullIntegrationRunning(true);

    if (!integration.active) {
      sendNotification('Essa integração não está ativa', 'error');
      return;
    }

    const { namespace } = integration;

    if (namespace === 'integra-feed') {
      setIsFeedIntegrationRunning(true);
      setCanIntegrate(false);

      const result = await runFeedIntegration(integration._id);

      if (result) {
        setIsFeedIntegrationRunning(false);
        sendNotification('Integração realizada com sucesso!', 'success');

        const user_email = localStorage.getItem('@Beon-adm-panel:user');
        const user_id = JSON.parse(
          localStorage.getItem('@Beon-adm-panel:userid'),
        );

        const log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: integration.namespace,
          action_type: 'Integração completa manual',
        };

        const logResult = await generateLog(log);

        if (logResult.status !== 201) {
          sendNotification('Falha ao gerar log', 'error');
        }

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        setIsFeedIntegrationRunning(false);
        sendNotification('Ocorreu um erro na integração!', 'error');
      }
    } else {
      setCanIntegrate(false);

      const { data } = await runMultiplatformFullIntegration({
        tenantId: id,
        platform: namespace,
      });

      if (data?.status === 'started') {
        sendNotification('Integração iniciada!');

        const user_email = localStorage.getItem('@Beon-adm-panel:user');
        const user_id = JSON.parse(
          localStorage.getItem('@Beon-adm-panel:userid'),
        );

        const log = {
          app_location: 'Painel ADM',
          tenant_id: id,
          time_stamp: new Date().toJSON(),
          user_email,
          user_id,
          resource: integration.namespace,
          action_type: 'Integração completa manual iniciada',
        };

        await generateLog(log);

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        sendNotification('Ocorreu um erro nessa operação!', 'error');
      }
    }

    setIsFullIntegrationRunning(false);
  };

  const getHookAndPreviewAndRunButton = () => {
    const { namespace } = integration;

    if (namespace === 'integra-feed') {
      return (
        <>
          <Button
            style={{ marginBottom: '6px', width: '100%' }}
            type="primary"
            onClick={openPreviewModal}
          >
            Preview
          </Button>
          <Button
            style={{ marginBottom: '6px', width: '100%' }}
            type="primary"
            onClick={showHookModal}
          >
            Hook personalizado
          </Button>
        </>
      );
    }

    return null;
  };

  const getRemainingMinutes = () => {
    if (timeRemainingToIntegrate !== 0) {
      return (
        <Tag
          style={{ textAlign: 'center', marginBottom: '0.5rem' }}
          color="orange"
        >{`Poderá integrar manualmente novamente em ${timeRemainingToIntegrate} minutos`}</Tag>
      );
    }

    return null;
  };

  const getUnitaryIntegrationButton = () => {
    const { namespace } = integration;

    if (namespace !== 'integra-feed') {
      return (
        <Button
          type="primary"
          style={{ marginBottom: '6px', width: '100%' }}
          onClick={() => setShowUnitaryIntegrationModal(true)}
          loading={isIntegrationRunning}
        >
          Integração Unitária
        </Button>
      );
    }

    return null;
  };

  const getMultiplatformIntegrationStatus = () => {
    if (isMultiplatformFullIntegrationRunning) {
      return (
        <Tag
          style={{ textAlign: 'center', marginBottom: '0.5rem' }}
          color="orange"
        >
          Esta integração ainda está rodando!
        </Tag>
      );
    }

    return null;
  };

  const getIntegrationCreationTime = () => {
    return integration.created_at ?? integration.createdAt;
  };

  const getIntegrationUpdateTime = () => {
    return integration.updated_at ?? integration.updatedAt;
  };

  return (
    <Container>
      <ConfigDetails>
        {getRemainingMinutes()}
        {getMultiplatformIntegrationStatus()}
        <p>
          <RightOutlined /> Integração {getIntegrationName()}
        </p>

        <p>
          <RightOutlined /> Status:{' '}
          {integration?.active ? (
            <Tag color="green">Ativada</Tag>
          ) : (
            <Tag color="red">Desativada</Tag>
          )}
        </p>

        <p>
          <RightOutlined /> Data de criação:
          {format(new Date(getIntegrationCreationTime()), dateFormat, {
            locale: ptBR,
          })}
        </p>

        <p>
          <RightOutlined /> Última modificação:{' '}
          {format(new Date(getIntegrationUpdateTime()), dateFormat, {
            locale: ptBR,
          })}
        </p>

        <p>
          <em style={{ color: 'gray', fontSize: '12px' }}>
            <RightOutlined />
            ID: {integration._id}
          </em>
        </p>
      </ConfigDetails>

      <ButtonSection>
        <Button
          style={{ marginBottom: '6px', width: '100%' }}
          type="primary"
          onClick={() => showIntegrationForm()}
        >
          Configurar
        </Button>

        {getHookAndPreviewAndRunButton()}

        <Popconfirm
          title="Você irá iniciar uma integração completa. Deseja prosseguir?"
          okText="Sim"
          cancelText="Não"
          onConfirm={runFullIntegration}
          onCancel={() => setIsPopconfirmVisible(false)}
          // disabled={!canIntegrate}
          open={isPopconfirmVisible}
        >
          <Button
            style={{ marginBottom: '6px', width: '100%' }}
            type="primary"
            // disabled={!canIntegrate}
            loading={isFullIntegrationRunning}
            onClick={() => setIsPopconfirmVisible(true)}
          >
            Integração completa
          </Button>
        </Popconfirm>

        {getUnitaryIntegrationButton()}

        <Popconfirm
          title={`Deseja ${
            integration?.active ? 'desativar' : 'reativar'
          } esta integração?`}
          onConfirm={() =>
            toggleIntegrationStatus(id, integration.namespace, integration._id)
          }
          okText="Sim"
          cancelText="Não"
        >
          <Button style={{ width: '100%', marginTop: '1.8rem' }}>
            {integration?.active ? 'Desativar' : 'Ativar'}
          </Button>
        </Popconfirm>
      </ButtonSection>
      <Modal width="50%" visible={isFeedIntegrationRunning}>
        <FeedRunningContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '150px',
          }}
        >
          <Tag color="red">
            Isso pode demorar um pouco, então por favor, não atualize ou saia da
            página até que esteja concluído
          </Tag>
          <Spin tip="Integração em andamento..." />
        </FeedRunningContainer>
      </Modal>
      {showUnitaryIntegrationModal && (
        <UnitaryIntegrationModal
          showModal={showUnitaryIntegrationModal}
          setModalVisible={setShowUnitaryIntegrationModal}
          tenantId={id}
          namespace={integration.namespace}
          setIsLoading={setIsIntegrationRunning}
        />
      )}
    </Container>
  );
};

IntegrationBox.propTypes = {
  integration: PropTypes.shape({
    _id: PropTypes.string,
    created_at: PropTypes.string,
    name: PropTypes.string,
    namespace: PropTypes.string,
    active: PropTypes.bool,
    updated_at: PropTypes.string,
  }),
};

export default IntegrationBox;
